<template>
  <div class="ancestral-arena-list-item">
    <div class="ancestral-arena-list-item__left">
      <div class="ancestral-arena-list-item__column-group">
        <div
          class="ancestral-arena-list-item__icon ancestral-arena-list-item__finished"
          :style="{ ...iconStyle }"
        >
          <img :src="imgSrc">
        </div>
        <div>
          <h1
            class="ancestral-arena-list-item__title truncate"
            :title="title"
          >
            {{ title }}
          </h1>
          <h2 class="ancestral-arena-list-item__subtitle">
            {{ subtitle }}
          </h2>
        </div>
      </div>

      <div class="ancestral-arena-list-item__group">
        <p class="ancestral-arena-list-item__group__title">
          Questões
        </p>
        <p class="ancestral-arena-list-item__text">
          {{ numberOfQuestions }}
        </p>
      </div>
    </div>

    <div class="ancestral-arena-list-item__right">
      <div
        v-if="hasFinishedDate"
        v-tooltip.top="'Data de finalização'"
        class="ancestral-arena-list-item__status ancestral-arena-list-item__finished"
      >
        <div class="ancestral-arena-list-item__icon">
          <img
            :src="imgSrc"
            alt="finished"
          >
        </div>
        <p class="ancestral-arena-list-item__text">
          Finalizada em {{ formattedReleaseDate }}
        </p>
      </div>

      <div
        v-if="isOnGoingMission"
        class="ancestral-arena-list-item__status ancestral-arena-list-item__ongoing"
      >
        <div class="ancestral-arena-list-item__icon">
          <img :src="require('MFE/assets/icons/ongoing.svg')">
        </div>
        Em andamento
      </div>

      <slot
        name="actions"
        class="ancestral-arena-list-item__actions"
      />
    </div>
  </div>
</template>

<script>
import formatDate from 'App/mixins/formatDate'
import missionTypes from 'App/enums/missionTypes'
import missionStatusEnum from '../enums/missionStatusEnum'

export default {
  name: 'AncestralArenaListItem',
  mixins: [ formatDate ],
  props: {
    matchHistory: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imgSrc() {
      // eslint-disable-next-line global-require
      return this.hasFinishedDate ? require('MFE/assets/icons/finished.svg') : require('MFE/assets/icons/active.svg')
    },
    iconStyle() {
      return {
        backgroundColor: this.hasFinishedDate ? '#D8FDE8' : '#c9d8ff',
        marginRight: '16px',
      }
    },
    title() {
      return this.matchHistory?.title
    },
    hasFinishedDate() {
      return !!this.matchHistory?.finishedAt
    },
    isOnGoingMission() {
      return this.matchHistory?.finishedStatus === missionStatusEnum.ONGOING
    },
    formattedReleaseDate() {
      return this.format(new Date(this.matchHistory?.finishedAt), "dd/MM/yyyy - HH'h'mm")
    },
    numberOfQuestions() {
      return this.matchHistory?.questionCount
    },
    subtitle() {
      return missionTypes[this.matchHistory?.type] ?? ''
    },
  },
}
</script>

<style lang="scss" scoped>
.ancestral-arena-list-item {
  font-family: "Inter", sans-serif;
  border: 1px solid #E6E9ED;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 1rem;
  @include space-stack($size-s);
  padding: $size-s !important;
  border-radius: 8px;
  align-items: center;
  flex-wrap: wrap;

  @include mq_m {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @include mq_s {
    grid-template-columns: 1fr;
    align-items: baseline;
    min-height: 250px;
  }

  &__left {
    display: grid;
    grid-template-columns: 3fr auto;

    .ancestral-arena-list-item__icon {
      width: 44px
    }

    @include mq_l {
      gap: $size-s;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
  }

  &__right {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "status actions";
    margin-left: $size-m;

    .ancestral-arena-list-item__icon {
      width: 44px
    }

    @include mq_l {
      gap: $size-s;
      grid-template-columns: 1fr;
      grid-template-areas:
      "actions"
      "status";
      grid-template-rows: 1fr 1fr;
    }

    @include mq_s {
      margin-left: 0;
    }

    ::v-deep .ancestral-arena-list__item__actions {
      grid-area: actions;
    }
  }

  div {
    max-height: 44px;
  }

  h1, h2 {
    font-family: "Inter", sans-serif;
  }

  h2 {
    font-weight: 300;
  }

  @include mq_l {
    min-height: 150px;
    align-items: baseline;
  }

  @include mq_s {
    min-height: 250px;
  }

  @include mq-m--mf {
    flex-wrap: nowrap;
    padding: $size-s $size-m;
  }

  &__icon {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin-right: $size-xs;
  }

  &__group {
    display: flex;
    flex-direction: column;
    margin-bottom: $size-xs;

    @include mq-m--mf {
      margin-bottom: 0;
    }

    &__title {
      color: #707780;
      font-size: 0.875rem;
    }

    &__text {
      color: #191C1F;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    grid-area: "status";
    border-radius: 8px;
    padding: 8px 16px;
    font-size: $font-size-s;
    max-width: 325px;

    @include mq_m {
      text-wrap: nowrap;
    }

    &__title {
      font-size: 0.875rem;
    }
  }

  &__finished {
    background-color: #D8FDE8;
    color: #00702F;
  }

  &__ongoing {
    color: #0F56B3;
    background: #DDEBFD;
    border: 1px solid #ADD1FF;
  }

  &__column-group {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: $font-size-heading-6;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__subtitle {
    color: $color-ink-light;
    font-size: $font-size-heading-6-small;
    margin-bottom: $size-s;

    @include mq-m--mf {
      margin-bottom: 0;
    }
  }

}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
