import { render, staticRenderFns } from "./Sort.vue?vue&type=template&id=44a4c982&scoped=true"
import script from "./Sort.vue?vue&type=script&lang=js"
export * from "./Sort.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44a4c982",
  null
  
)

export default component.exports