<template>
  <modal
    class="share-modal"
    :padding="$tokens.size_m"
    :hide-close-button="true"
    @close="$emit('close')"
  >
    <ShareForm
      :loading="shareUrlCache.loading"
      :url="handleUrlAddress"
      @close="$emit('close')"
    />
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import microfrontendService from 'shared/utils/MicrofrontendService'
import { urls } from 'shared/utils/urls'
import ShareForm from './ShareForm'

export default {
  name: 'ShareModal',
  components: {
    ShareForm,
  },
  props: {
    questionnaireCode: {
      type: String,
      required: true,
    },
    selectedClassroom: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isMicrofrontend: false,
    }
  },
  computed: {
    ...mapGetters([
      'shareUrlCache',
    ]),
    gradeId() {
      return this.selectedClassroom?.grade?.id
    },
    handleUrlAddress() {
      if (!this.shareUrlCache.data[this.questionnaireCode]) return
      const url = this.shareUrlCache.data[this.questionnaireCode]

      // eslint-disable-next-line consistent-return
      return this.isMicrofrontend ? url.replace(urls.EUREKA_URI, `${urls.CROSS_PORTAL_URL}/eureka`) : url
    },
  },
  created() {
    this.isMicrofrontend = microfrontendService.get()
    const { questionnaireCode, gradeId } = this
    this.getShareUrl({ questionnaireCode, gradeId })
  },
  methods: {
    ...mapActions([
      'getShareUrl',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.share-modal {
  ::v-deep .sas-modal__header{
    display: none
  }

  ::v-deep .sas-modal__body {
    padding: $size-m 0 !important;
  }
}
</style>
