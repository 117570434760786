/*
* Esse script foi criado para lidar com os diferentes valores que podemos receber de "shift"
* durante a transição do consumo de serviços SAS e Cross evitando retrabalhos ou "estoque de código"
* visto que os serviços SAS retornam "shift" em inglês e os serviços Cross retornam em português.
*
* Esse script deverá ser removido assim que não for mais necessário.
*/

import translateShift from 'MFE/utils/shiftsTranslation'

const englishShifts = [ 'morning', 'fulltime', 'afternoon', 'evening' ]

function getUpperCaseFirstLetter(string) { return string.charAt(0).toUpperCase() + string.slice(1) }

export default function getShiftLabel(shift) {
  if (englishShifts.includes(shift.toLowerCase())) {
    return translateShift(shift)
  }

  return getUpperCaseFirstLetter(shift)
}
