<template>
  <div class="book-activities">
    <div class="book-activities__body">
      <template v-if="showEmptyState">
        <div class="book-activities__empty-state">
          <EmptyState
            :title="emptyState.title"
            :image="emptyState.image"
          />
        </div>
      </template>
      <template v-else-if="bookAssignments.ready">
        <AssignmentsList
          v-for="(
            [ bookName, assignmentsList ], groupIndex
          ) in assignmentsByBook"
          :key="groupIndex"
          :assignments="assignmentsList"
          :title="bookName"
        >
          <template #default="{ assignment }">
            <TeacherAssignmentsActions
              :assignment="assignment"
              :complaint-badge="assignment.numberOfComplaints > 0"
              @action="handleAssignmentAction"
              @select="selectedForSharing = assignment"
            />
          </template>
        </AssignmentsList>
      </template>
      <AssignmentsListSkeleton
        v-else
        :title="true"
      />
    </div>
    <RedirectToCQ
      v-if="isRedirecting"
      :title="$t('myActivities.redirect.title')"
      :description="$t('myActivities.redirect.description')"
      :image-source="require('App/assets/redirect-cq.svg')"
      :classroom="selectedClassroom"
      :lecture-id="selectedLecture.id"
    />
    <ShareModal
      v-if="selectedForSharing"
      :selected-classroom="selectedClassroom"
      :questionnaire-code="selectedForSharing.questionnaireCode"
      @close="selectedForSharing = null"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import AssignmentsList from 'MFE/components/Assignments/AssignmentsList'
import EmptyState from 'MFE/components/EmptyState/EmptyState'
import AssignmentsListSkeleton from 'MFE/components/Assignments/AssignmentsListSkeleton'
import RedirectToCQ from 'App/components/RedirectToCQ'
import ShareModal from 'MFE/components/ShareModal/ShareModal'
import TeacherAssignmentsActions from 'MFE/components/TeacherAssignmentsActions'
import { isNaN } from 'lodash'
import microfrontendService from '@/shared/utils/MicrofrontendService'

export default {
  name: 'BookActivities',
  components: {
    AssignmentsList,
    AssignmentsListSkeleton,
    EmptyState,
    RedirectToCQ,
    ShareModal,
    TeacherAssignmentsActions,
  },
  props: {
    selectedClassroom: {
      type: Object,
      default: null,
    },
    selectedLecture: {
      type: Object,
      default: null,
    },
    gradeId: {
      type: Number,
      required: true,
    },
    classroomId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isRedirecting: false,
      selectedForSharing: null,
      isMicrofrontend: false,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'yearSelectedContent',
      'bookAssignments',
    ]),
    emptyState() {
      return {
        image: 'assignments-not-found',
        title: this.$t('sasActivities.empty.title', {
          lecture: this.selectedLecture && this.selectedLecture.name,
        }),
      }
    },
    assignmentsByBook() {
      if (!this.bookAssignments.ready) {
        return []
      }

      return Object.entries(this.bookAssignments.data.reduce(
        (initial, current) => {
          const next = { ...initial }
          const { bookName } = current

          if (!Object.prototype.hasOwnProperty.call(next, bookName || '')) {
            next[bookName || ''] = []
          }
          next[bookName || ''].push({ ...current })

          return next
        }, {}
      ))
    },
    showEmptyState() {
      return this.selectedLecture?.id
      && !this.bookAssignments.loading
      && !this.assignmentsByBook.length
    },
  },
  watch: {
    selectedLecture: {
      immediate: true,
      handler() {
        if (this.selectedLecture) {
          this.fetchBookAssignments()
        }
      },
    },
    yearSelectedContent() {
      if (this.yearSelectedContent) {
        this.fetchBookAssignments()
      }
    },
  },
  mounted() {
    this.isMicrofrontend = microfrontendService.get()
  },
  methods: {
    ...mapActions([ 'getBookAssignments' ]),
    handleAssignmentAction(assignment) {
      const { id, status, questionnaireCode } = assignment

      const lectureId = this.$route.query.lectureId || this.selectedLecture?.id

      if (!this.classroomId || !lectureId || !questionnaireCode) {
        this.$toasted.global.error({
          title: 'Não foi possível visualizar o relatório',
        })

        console.error('AssignmentsList missing params:', {
          classroomId: this.classroomId,
          lectureId,
          questionnaireCode,
        })

        return
      }

      if (status !== 'unreleased') {
        this.$router.push({
          name: this.isMicrofrontend ? 'mfe-report-general' : 'teacher-report-general',
          params: {
            id,
            questionnaireCode,
            classroomId: this.classroomId,
          },
          query: {
            lectureId,
            type: 'book-activities',
          },
        })
      }
    },
    fetchBookAssignments() {
      const { lectureId, classroomId } = this.$route.query
      const { gradeId, yearSelectedContent } = this
      const lecture = lectureId || this.selectedLecture.id
      const contentYear = isNaN(yearSelectedContent) ? null : yearSelectedContent

      this.getBookAssignments({
        lectureId: lecture,
        gradeId,
        classroomId,
        contentYear,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.book-activities {
  &__empty-state {
    @include flex-column-center;
  }

  &__report-button {
    margin-right: $size-xs;
  }

  ::v-deep .assignments-container {
    margin-bottom: $size-m
  }
}
</style>
