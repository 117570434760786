<template>
  <component
    :is="currentComponent"
    v-bind="currentComponentProps"
    class="report-problem__responsive-drawer"
    :hide-close-button="true"
  >
    <template #header>
      <div class="report-problem__responsive-drawer__header">
        <h4>
          {{ $t('questions.reportAProblem.title') }}
        </h4>
        <s-button
          icon="x"
          variation="tertiary"
          :disabled="closeDisabled"
          @click="$emit('close')"
        />
      </div>
    </template>
    <EmptyState
      v-if="complaint.data.success"
      :image="successState.image"
      :title="successState.title"
    />
    <template v-else>
      <section class="report-problem__responsive-drawer__body">
        <Alert
          v-if="complaint.error"
          type="danger"
          :message="errorState.message"
          :icon="errorState.icon"
        />
        <h5 class="report-problem__form__label">
          {{ $t('questions.reportAProblem.subtitle') }}
        </h5>
        <div
          v-for="(complaint, index) in complaints"
          :key="index"
          class="report-problem__form__field"
        >
          <input
            :id="complaint"
            v-model="complaintTypes"
            type="checkbox"
            :value="complaint"
          >
          <label :for="complaint">
            {{ $t(`questions.reportAProblem.complaintTypes.${complaint}`) }}
          </label>
        </div>
        <template v-if="hasProblems">
          <h5 class="report-problem__form__label">
            {{ $t('questions.reportAProblem.describeProblem') }}
          </h5>
          <textarea
            v-model="description"
            name="reason"
            class="report-problem__form__reason"
          />
        </template>
      </section>
      <footer class="report-problem__action">
        <s-button
          size="large"
          icon-left="send"
          :disabled="!sendIsValid"
          :loading="loading"
          class="report-problem__action__send"
          @click="$emit('sending-report', { complaintTypes, description })"
        >
          {{ $t('questions.reportAProblem.send') }}
        </s-button>
      </footer>
    </template>
  </component>
</template>

<script>
import { mapGetters } from 'vuex'

import mediaQueries from 'shared/mixins/mediaQueries'
import EmptyState from 'App/components/EmptyState'
import Alert from 'App/components/Alert'

export default {
  name: 'ResponsiveReportComponent',
  components: {
    EmptyState,
    Alert,
  },
  mixins: [ mediaQueries ],
  props: {
    complaints: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    closeDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      complaintTypes: [],
      description: '',
      descriptionMinimum: 3,
    }
  },
  computed: {
    ...mapGetters([ 'complaint' ]),
    isResponsive() {
      return !this.mq_xl__mf
    },
    currentComponent() {
      return this.isResponsive ? 'drawer' : 'modal'
    },
    currentComponentProps() {
      if (this.isResponsive) {
        return { position: 'bottom' }
      }

      return {
        closeButtonEnabled: false,
        padding: { header: 0, body: 0, footer: 0 },
      }
    },
    hasProblems() {
      return this.complaintTypes.length > 0
    },
    validExplanation() {
      return this.description.length >= this.descriptionMinimum
    },
    sendIsValid() {
      return Boolean(
        this.complaintTypes.length
          && (
            !this.complaintTypes.includes('others')
            || this.validExplanation
          )
      )
    },
    successState() {
      return {
        image: 'undraw_certification',
        title: this.$t('questions.reportAProblem.successState.title'),
      }
    },
    errorState() {
      return {
        message: this.$t('questions.reportAProblem.errorState.title'),
        icon: 'alert-triangle',
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.report-problem
  &__responsive-drawer
    &__header
      +flex-space-between
      min-height: 80px
      padding: $size-s
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25)
      margin-bottom: 12px

    &__body
      padding: $font-size-xs $size-s
      padding-bottom: 80px

  &__action
    position: absolute
    bottom: 0
    width: 100%
    padding: $size-s
    +flex-column-end

    &__send
      width: 100%

  .report-problem__form
    &__label
      font-size: $font-size-heading-5-small
      font-weight: $font-weight-semi-bold
      margin-bottom: $size-s

      &:not(:first-child)
        margin-top: $size-xl

    &__field
      +flex-center-start
      margin-bottom: $size-s
      margin-left: 10px

      input
        width: $size-m
        height: $size-m

      label
        font-size: $size-s
        margin-left: $font-size-xs
        font-weight: normal

    &__reason
      resize: none
      width: 100%
      height: 106px
      background: #FFFFFF
      border: 1px solid #C9CCCF
      border-radius: 8px
</style>
