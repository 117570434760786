<template>
  <div class="lecture-picker">
    <skeleton-loader
      v-if="loading"
      width="240px"
      height="38px"
    />
    <AdaptiveSelector
      v-else
      :dropdown-direction="dropdownDirection"
      :options="lectures"
      :selected-option="selectedOption"
      :threshold="5"
      @select="$emit('select', $event)"
    >
      <template #selectedOption="{ option }">
        <div class="lecture-picker__option">
          {{ option.name }}
        </div>
      </template>
      <template #optionsTitle>
        <h4 class="lecture-picker__options-title">
          {{ $t('lecturePicker.selectLecture') }}
        </h4>
      </template>
      <template #option="{ option }">
        <div class="lecture-picker__option">
          {{ option.name }}
        </div>
      </template>
    </AdaptiveSelector>
  </div>
</template>

<script>
import AdaptiveSelector from 'MFE/components/AdaptiveSelector/AdaptiveSelector'
import lectureThemes from '../../shared/utils/lectureThemes'

export default {
  name: 'LecturePicker',
  components: {
    AdaptiveSelector,
  },
  model: {
    prop: 'selectedOption',
    event: 'select',
  },
  props: {
    lectures: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dropdownDirection: {
      type: String,
      default: 'left',
    },
  },
  methods: {
    subjectTheme: (subject) => lectureThemes.subjects[subject.id],
  },
}
</script>

<style lang="scss">
.lecture-picker {
  &__options-title {
    flex-grow: 1;
  }

  &__option {
    flex-grow: 1;
    @include flex-center-start;
    font-weight: $font_weight_medium;

    &__icon {
      display: block;
      height: 28px;
      width: 28px;
      margin-right: $font-size-xs;
      overflow: hidden;
      flex-shrink: 0;
    }
  }
}
</style>
