<template>
  <div class="students-finished">
    <header
      v-if="!loading"
      class="students-finished__header"
    >
      <h3>{{ sectionTitle }}</h3>
      <s-button
        icon-right="arrow-right"
        variation="tertiary"
        @click="$emit('viewAll')"
      >
        {{ $t('report.studentsFinished.viewAll') }}
      </s-button>
    </header>
    <div
      v-if="!loading"
      class="students-finished__search"
    >
      <s-input
        v-model="searchString"
        size="large"
        icon-left="search"
        width="100%"
        :placeholder="$t('report.students.search')"
      />
    </div>
    <div
      v-if="!loading && mq_m"
      class="students-finished__sort"
    >
      <span class="students-finished__sort__label">
        {{ $t('report.sortBy') }}
      </span>
      <s-select
        v-model="sortBy"
        class="students-finished__sort__select"
        size="medium"
        variation="tertiary"
        :options="sortOptionsLabels"
      />
    </div>
    <div
      v-if="!loading && mq_m"
      class="students-finished__cards"
    >
      <box
        v-for="(student, index) in filteredStudents"
        :key="index"
        class="students-finished__cards__item"
        elevation="2"
        padding="0"
        @click.native="selectStudent({ data: student, dataIndex: student.INDEX })"
      >
        <div class="students-finished__cards__item__student">
          <h6 class="students-finished__cards__item__student__name">
            {{ student.name }}
          </h6>
          <span class="students-finished__cards__item__student__finished-at">
            {{
              $t('report.students.finishedAt')
            }}
            {{ format(student.finishedAt, 'd MMM') }}
          </span>
        </div>
        <div class="students-finished__cards__item__hit-rate">
          <span class="students-finished__cards__item__hit-rate__value">
            <b>{{ Math.round(student.hitRate) }}%</b>
          </span>
          <span class="students-finished__cards__item__hit-rate__label">
            {{ $t('report.students.hits') }}
          </span>
        </div>
      </box>
    </div>
    <s-table
      v-else-if="!loading"
      ref="studentsTable"
      class="students-finished__table"
      :selected-row="activeStudent"
      :content="filteredStudents"
      :default-sort="defaultSort"
      :fields="fields"
      :paginate="true"
      @click-row="selectStudent"
    >
      <template
        slot="name"
        slot-scope="{ row }"
      >
        <h6 class="students-finished__table__name">
          {{ row.name }}
        </h6>
      </template>
      <template
        slot="hitRate"
        slot-scope="{ row }"
      >
        <p class="students-finished__table__hit-rate">
          <b>{{ Math.round(row.hitRate) }}%</b>
        </p>
      </template>
      <template
        slot="finishedAt"
        slot-scope="{ row }"
      >
        <div class="students-finished__table__finished-at">
          <p class="students-finished__table__finished-at__date">
            {{ mq_xl__mf
              ? format(row.finishedAt, 'd MMM, yyyy')
              : format(row.finishedAt, 'd MMM')
            }}
          </p>
          <span
            v-if="mq_xl__mf && row.finishedAt"
            class="students-finished__table__finished-at__time"
          >
            · {{ format(row.finishedAt, 'HH:mm') }}
          </span>
        </div>
      </template>
      <template slot="details">
        <s-button
          variation="secondary"
          icon-left="bar-chart-2"
        >
          {{ $t('commons.report') }}
        </s-button>
      </template>
      <template
        slot="pagination"
        slot-scope="props"
      >
        {{ $tc('report.students.pagination',
               props.total,
               {
                 from: props.start + 1,
                 to: props.end,
                 total: props.total
               }) }}
      </template>
    </s-table>
    <StudentDrawer
      v-if="activeStudent"
      :student="activeStudent"
      :has-previous="hasPreviousStudent"
      :has-next="hasNextStudent"
      :questionnaire="questionnaire"
      @close="clearSelectedStudent"
      @previous-student="previousStudent"
      @next-student="nextStudent"
    />
  </div>
</template>

<script>
import mediaQueries from 'shared/mixins/mediaQueries'
import formatDate from 'App/mixins/formatDate'
import accentFold from 'App/mixins/accentFold'
import STable from 'App/components/STable'
import microfrontendService from '@/shared/utils/MicrofrontendService'
import StudentDrawer from '../StudentDrawer/StudentDrawer'

export default {
  name: 'StudentsFinished',
  components: {
    STable,
    StudentDrawer,
  },
  mixins: [
    mediaQueries,
    accentFold,
    formatDate,
  ],
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    students: {
      type: Array,
      default: () => [],
    },
    sectionTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchString: '',
      isMicrofrontend: false,
      fields: [
        {
          value: 'name',
          text: this.$t(
            'report.students.student'
          ),
          sortable: true,
          sortFunction: (a, b, modifier = 1) => a.localeCompare(b) * modifier,
        },
        {
          value: 'hitRate',
          text: this.$t('report.students.hits'),
          sortable: true,
        },
        {
          value: 'finishedAt',
          text: this.$t(
            'report.students.finishedAt'
          ),
          sortable: true,
          sortFunction: (a, b, modifier = 1) => {
            if ((a ? a.getTime() : 0) > (b ? b.getTime() : 0)) return modifier
            if ((a ? a.getTime() : 0) < (b ? b.getTime() : 0)) {
              return -1 * modifier
            }

            return 0
          },
        },
        {
          value: 'details',
          text: '',
          width: '1px',
        },
      ],
      defaultSort: {
        field: 'name',
        direction: 'asc',
      },
      sortBy: this.$t(
        'report.students.sort.smallerHitRate'
      ),
      sortOptions: [
        {
          field: 'hitRate',
          sortFunction: this.sortNumber,
          label: this.$t(
            'report.students.sort.smallerHitRate'
          ),
        },
        {
          field: 'hitRate',
          sortFunction: (a, b) => -1 * this.sortNumber(a, b),
          label: this.$t(
            'report.students.sort.greaterHitRate'
          ),
        },
        {
          field: 'finishedAt',
          sortFunction: (a, b) => this.sortNumber(
            a ? a.getTime() : 0,
            b ? b.getTime() : 0
          ),
          label: this.$t(
            'report.students.sort.recentLast'
          ),
        },
        {
          field: 'finishedAt',
          sortFunction: (a, b) => (
            -1 * this.sortNumber(
              a ? a.getTime() : 0,
              b ? b.getTime() : 0
            )
          ),
          label: this.$t(
            'report.students.sort.recentFirst'
          ),
        },
      ],
      activeStudentInnerIndex: -1,
    }
  },
  computed: {
    studentsViewRoute() {
      if (this.isMicrofrontend) {
        return 'mfe-extra-activity-report-students'
      }

      return this.$route.params.extraActivityId
        ? 'teacher-extra-activity-report-students'
        : 'teacher-report-students'
    },
    filteredStudents() {
      const students = this.searchString
        ? [ ...this.students ].filter((student) => (
          this.accentFold(student.name.toLocaleLowerCase())
            .includes(
              this.accentFold(this.searchString.toLocaleLowerCase())
            )
        ))
        : [ ...this.students ]
      const studentsWithIndex = students.map((student, index) => ({ ...student, INDEX: index }))

      return this.mq_m
        ? [ ...studentsWithIndex ].sort((a, b) => (
          this.sortByObject.sortFunction(
            (a[this.sortByObject.field] ? a[this.sortByObject.field] : 0),
            (b[this.sortByObject.field] ? b[this.sortByObject.field] : 0)
          )
        ))
        : studentsWithIndex
    },
    sortByObject() {
      return this.sortOptions.find((option) => option.label === this.sortBy)
    },
    sortOptionsLabels() {
      return this.sortOptions.map((option) => option.label)
    },
    activeStudent() {
      const { activeStudent } = this.$route.query
      if (!activeStudent) return null

      const [ student ] = this.students.filter((item) => (
        item.id === Number.parseInt(activeStudent, 10)
      ))

      return student
    },
    activeStudentIndex() {
      return this.filteredStudents.findIndex((student) => (
        student.id === this.activeStudent.id
      ))
    },
    hasPreviousStudent() {
      return this.activeStudentInnerIndex > 0
    },
    hasNextStudent() {
      return this.activeStudentInnerIndex < this.filteredStudents.length - 1
    },
  },
  mounted() {
    this.isMicrofrontend = microfrontendService.get()
  },
  methods: {
    sortNumber(a, b) {
      if (a > b) return 1
      if (a < b) return -1

      return 0
    },
    selectStudent(event) {
      const student = event.data
      const activeStudent = Number.parseInt(this.$route.query.activeStudent, 10)

      if (student.id !== activeStudent) {
        this.activeStudentInnerIndex = event.dataIndex
        delete this.$route.query.activeQuestion
        this.$router.push({
          name: this.$route.name,
          params: { ...this.$route.params },
          query: {
            ...this.$route.query,
            activeStudent: student.id,
            assignmentCode: event.data.code,
          },
        })
      }
    },
    clearSelectedStudent() {
      const query = { ...this.$route.query }
      delete query.activeStudent
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query,
      })
    },
    previousStudent() {
      if (this.mq_m) {
        if (this.activeStudentIndex > 0) {
          this.selectStudent({
            data: this.filteredStudents[this.activeStudentIndex - 1],
            dataIndex: this.activeStudentIndex - 1,
          })
        }
      } else {
        this.$refs.studentsTable.previous()
      }
    },
    nextStudent() {
      if (this.mq_m) {
        if (this.activeStudentIndex < this.filteredStudents.length - 1) {
          this.selectStudent({
            data: this.filteredStudents[this.activeStudentIndex + 1],
            dataIndex: this.activeStudentIndex + 1,
          })
        }
      } else {
        this.$refs.studentsTable.next()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.students-finished {
  &__header {
    margin-bottom: $size-l;

    @include mq-m--mf {
      @include flex-center-start;
    }

    h3 {
      margin-bottom: $size-s;
      flex-grow: 1;

      @include mq-m--mf {
        margin-bottom: 0;
      }
    }
  }

  &__search {
    margin-bottom: $size-m;

    ::v-deep .sas-input__field {
      background-color: $color-white;
    }
  }

  &__sort {
    margin-bottom: $size-s;

    &__label {
      color: $color-ink-light;
    }

    &__select {
      display: inline-block;
    }
  }

  &__cards {
    &__item {
      @include space-stack($size-s);
      @include flex-center-start;

      &__student {
        border-right: 1px solid transparentize($color-ink-lightest, 0.5);
        flex-grow: 1;
        padding: $size-s;

        &__finished-at {
          display: inline-block;
          margin-top: $size-xxs;
          color: $color-ink-light;
        }
      }

      &__hit-rate {
        padding: $size-xs;

        &__value {
          display: block;
        }

        &__label {
          color: $color-ink-light;
        }
      }
    }
  }

  &__table {
    &__finished-at {
      @include flex-center-start;

      &__time {
        color: $color-ink-light;
        margin-left: $size-xxs;
      }
    }

    ::v-deep .sas-table__body tr {
      cursor: pointer;
    }
  }
}
</style>
