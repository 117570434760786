<template>
  <wrapper v-if="isLogosAvailableForThisGrade && !hasError">
    <div class="logos-report">
      <LogosSubjectSelector
        :grade-id="gradeId"
        @selectedSubject="onSelectSubject"
      />
      <LogosReportSkeleton
        v-if="report.loading"
      />
      <div v-else>
        <LogosChapterContainer
          v-for="(chapter, idx) in report.data"
          :key="idx"
        >
          <h3 class="logos-report__title">
            {{ chapter.name }}
          </h3>
          <LogosMissionItem
            v-for="recompositionChapter in chapter.recompositionChapters"
            :key="recompositionChapter.number"
            :chapter-number="recompositionChapter.number"
            :loading="report.loading"
            :logos-mission="recompositionChapter"
          >
            <div>
              <s-button
                class="ancestral-arena-list__button"
                icon-left="bar-chart-2"
                @click="viewReport(recompositionChapter)"
              >
                Relatório
              </s-button>
            </div>
          </LogosMissionItem>
        </LogosChapterContainer>
      </div>
    </div>
  </wrapper>
  <EmptyState
    v-else-if="!hasError && !report.loading"
    title="Relatório indisponível"
    description="A turma selecionada não possui o Lógos. Selecione outra turma acima."
    image="teacher-dash"
  />
  <EmptyState
    v-else-if="!report.loading && hasError"
    image="assignments-not-found"
    title="Erro ao carregar conteúdo"
    description="A turma selecionada não possui o Lógos. Selecione outra turma acima."
  >
    <s-button
      icon-left="refresh-ccw"
      @click="fetchReport"
    >
      Recarregar página
    </s-button>
  </EmptyState>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import EmptyState from 'App/components/EmptyState'
import logosApi from '@/service/logos'
import microfrontendService from '@/shared/utils/MicrofrontendService'
import LogosChapterContainer
  from './components/LogosChapterContainer/LogosChapterContainer'
import LogosSubjectSelector
  from './components/LogosSubjectSelector/LogosSubjectSelector'
import LogosMissionItem from './components/LogosMissionItem/LogosMissionItem'
import LogosReportSkeleton from './LogosReportSkeleton'

const MATH_LECTURE_ID = '972'
const SIXTH_GRADE_ID = 3
const SEVENTH_GRADE_ID = 14
const EIGHT_GRADE_ID = 15
const NINTH_GRADE_ID = 16

const ALLOWED_GRADES = [
  SIXTH_GRADE_ID,
  SEVENTH_GRADE_ID,
  EIGHT_GRADE_ID,
  NINTH_GRADE_ID,
]

export default {
  name: 'LogosReport',
  components: {
    EmptyState,
    LogosChapterContainer,
    LogosReportSkeleton,
    LogosSubjectSelector,
    LogosMissionItem,
  },
  props: {
    selectedClassroom: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isMicrofrontend: false,
      selectedSubject: null,
      selectedLecture: null,
      report: {
        data: [],
        loading: true,
        success: false,
        error: null,
        ready: false,
      },
      hasError: false,
      fetchCount: 0,
      timeoutId: null,
    }
  },
  computed: {
    ...mapGetters([
      'yearSelectedContent',
    ]),
    isLogosAvailableForThisGrade() {
      return this.gradeId && ALLOWED_GRADES.includes(this.gradeId)
    },
    lectures() {
      return this.selectedClassroom?.lectures || []
    },
    lectureQueryParam() {
      const { lectureId } = this.$route.query

      return parseInt(lectureId, 10) ?? this.selectedClassroom?.lectures[0]?.id
    },
    gradeId() {
      return this.selectedClassroom?.grade?.id
    },
  },
  watch: {
    selectedClassroom: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchReport()
      },
    },
    selectedLecture() {
      const {
        selectedLecture,
        selectedSubject,
      } = this

      if (selectedLecture && selectedSubject) {
        this.fetchReport()
      }
    },
    lectures() {
      const {
        findLectureById,
        lectureQueryParam,
      } = this
      const lectureFound = findLectureById(lectureQueryParam)

      this.selectedLecture = lectureFound || this.selectedClassroom.lectures[0]
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.lectureId !== MATH_LECTURE_ID) {
      next({
        name: to.name,
        query: {
          ...to.query,
          lectureId: MATH_LECTURE_ID,
        },
      })
    } else {
      next()
    }
  },
  created() {
    if (this.lectureQueryParam) {
      this.selectedLecture = this.lectures.find(
        (lecture) => lecture.id === this.lectureQueryParam
      )
    }
  },
  mounted() {
    this.isMicrofrontend = microfrontendService.get()
  },
  methods: {
    async fetchReport() {
      if (!this.checkLogosAvailability()) {
        return
      }
      this.resetReportStatus()

      if (this.isFetchDelayed()) {
        this.handleDelayedFetch()

        return
      }

      try {
        await this.fetchAndSetReportData()
      } catch (error) {
        this.handleFetchError(error)
      } finally {
        this.report.loading = false
      }
    },
    checkLogosAvailability() {
      if (!this.isLogosAvailableForThisGrade) {
        this.resetReportStatus()
        this.report.loading = false

        return false
      }

      return true
    },
    resetReportStatus() {
      this.report.data = []
      this.report.loading = true
      this.report.error = false
      this.report.success = false
      this.report.ready = false
    },
    isFetchDelayed() {
      return isEmpty(this.selectedSubject) || isEmpty(this.selectedLecture)
    },
    handleDelayedFetch() {
      const maxTryCount = 7
      if (this.fetchCount >= maxTryCount) {
        this.setReportError('Não foi possível carregar o conteúdo')

        return
      }

      if (!this.report.ready) {
        this.scheduleNextFetch()
      }
    },
    scheduleNextFetch(delay = 1000) {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(async () => {
        await this.fetchReport()
        this.fetchCount += 1
      }, delay)
    },
    async fetchAndSetReportData() {
      const selectedSubjectId = this.selectedSubject?.id || this.selectedLecture?.subject?.id
      const { data } = await logosApi.getRecompositionChapters({
        subjectId: selectedSubjectId,
        gradeId: this.gradeId,
        classroomId: this.selectedClassroom?.id,
        contentYear: this.yearSelectedContent,
      })

      this.processFetchedData(data)
    },
    processFetchedData(data) {
      this.report.data = data
      this.report.ready = true
      this.report.error = false
      this.report.success = true

      if (isEmpty(data)) {
        this.setReportError('Não foi possível carregar o conteúdo')
        this.report.success = false
      }
    },
    setReportError(errorMessage) {
      this.report.error = new Error(errorMessage)
      this.report.success = false
      this.report.ready = false
      this.report.loading = false
      this.hasError = true
    },
    handleFetchError(error) {
      this.report.error = error
      this.report.success = false
      this.report.ready = false
      this.hasError = true
    },
    onSelectSubject(subject) {
      this.selectedSubject = subject?.subject ?? null
    },
    findLectureById(lectureId) {
      return this.selectedClassroom.lectures.find((lecture) => lecture.id === lectureId)
    },
    viewReport(recompositionChapter) {
      const {
        classroomId,
        lectureId,
      } = this.$route.query

      this.$trackEvent({
        category: this.$track.category.logosReport,
        action: this.$track.action.selectLogosReport,
        label: {
          chapterNumber: recompositionChapter.number,
          chapterTitle: recompositionChapter.title,
          lectureId,
          lectureName: this.selectedLecture.name,
          gradeId: this.gradeId,
          gradeName: this.selectedClassroom.grade.name,
        },
      })

      this.$router.push({
        name: this.isMicrofrontend ? 'mfe-recomposition-chapter-report' : 'teacher-recomposition-chapter-report',
        params: {
          recompositionChapterId: recompositionChapter.id,
          classroomId,
        },
        query: {
          lectureId,
          classroomId,
          recompositionChapterId: recompositionChapter.id,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.logos-report {
  width: 100%;

  &__title {
    color: $color-ink;
    margin-bottom: $size-s;
  }
}
</style>
