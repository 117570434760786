<template>
  <div class="question-drawer-hit-rate">
    <div class="question-drawer-hit-rate__donut">
      <radial-progress-bar
        inner-stroke-color="#EFF0F0"
        start-color="#438DE4"
        stop-color="#438DE4"
        :stroke-width="10"
        :diameter="donutSize"
        :total-steps="100"
        :completed-steps="roundedHitRate"
      >
        <h4><b>{{ roundedHitRate }}%</b></h4>
      </radial-progress-bar>
    </div>
    <div class="question-drawer-hit-rate__text">
      <div class="question-drawer-hit-rate__text__title">
        {{ $t('report.difficulties.hitRateLong') }}
      </div>
      <div class="question-drawer-hit-rate__text__count">
        <h5>
          {{ this.$tc(
            'report.difficulties.hits',
            count,
            {
              count: hitCount,
              total: count,
            })
          }}
        </h5>
      </div>
    </div>
  </div>
</template>
<script>
import RadialProgressBar from 'vue-radial-progress'
import mediaQueries from 'shared/mixins/mediaQueries'

export default {
  name: 'QuestionDrawerHitRate',
  components: {
    RadialProgressBar,
  },
  mixins: [ mediaQueries ],
  props: {
    hitRate: {
      type: Number,
      required: true,
    },
    hitCount: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  computed: {
    roundedHitRate() {
      return Math.round(this.hitRate)
    },
    donutSize() {
      return this.mq_xs ? 88 : 110
    },
  },
}
</script>
<style lang="sass" scoped>
.question-drawer-hit-rate
  border-bottom: 1px solid $color-ink-lightest
  padding: $size-m $size-l

  +mq-s--mf
    +flex-center-start

  &__donut
    margin-bottom: $size-s

    +mq-s--mf
      margin-right: $size-s
      margin-bottom: 0

  &__text
    &__title
      color: $color-ink-light
      font-size: 11px
      text-transform: uppercase
      letter-spacing: 1.3px
      margin-bottom: $size-xs
</style>
