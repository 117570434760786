<template>
  <section>
    <h3 class="ancestral-arena-list__title">
      {{ title }}
    </h3>

    <ul class="ancestral-arena-list__item__container">
      <li
        v-for="matchHistory in list"
        :key="matchHistory.code"
        class="ancestral-arena-list__item"
      >
        <AncestralArenaListItem :match-history="matchHistory">
          <template #actions>
            <div class="ancestral-arena-list__item__actions">
              <div
                v-tooltip.top="'Acessar relatório'"
                class="ancestral-arena-list__button ancestral-arena-list__button--report"
                @click="viewReport(matchHistory.code)"
              >
                Acessar Relatório
              </div>

              <img
                v-tooltip.top="'Acessar missão'"
                class="ancestral-arena-list__button ancestral-arena-list__button--mission"
                :src="require('MFE/assets/icons/link.svg')"
                @click="redirectToArenaMissionView(matchHistory.modelCode)"
              >
            </div>
          </template>
        </AncestralArenaListItem>
      </li>
    </ul>
  </section>
</template>

<script>
import { urls } from 'shared/utils/urls'
import microfrontendService from '@/shared/utils/MicrofrontendService'
import AncestralArenaListItem from './AncestralArenaListItem'

export default {
  name: 'AssignmentsList',
  components: {
    AncestralArenaListItem,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Partidas ativas',
    },
  },
  data() {
    return {
      isMicrofrontend: false,
    }
  },
  mounted() {
    this.isMicrofrontend = microfrontendService.get()
  },
  methods: {
    redirectToArenaMissionView(questionnaireCode) {
      const redirectUrl = this.isMicrofrontend ? `${urls.CROSS_PORTAL_URL}/eureka` : urls.EUREKA_URI

      const url = `${redirectUrl}/ancestral-arena/teacher/questionnaires/${questionnaireCode}/detail`
      window.location.assign(url)
    },
    viewReport(questionnaireCode) {
      const { classroomId, lectureId } = this.$route.query

      if (!classroomId || !lectureId || !questionnaireCode) {
        console.error('AssignmentsList missing params:', { classroomId, lectureId, questionnaireCode })

        this.$toasted.global.error({
          title: 'Não foi possível visualizar o relatório',
        })

        return
      }

      this.$router.push({
        name: this.isMicrofrontend ? 'mfe-report-general' : 'teacher-report-general',
        params: { classroomId, questionnaireCode },
        query: {
          lectureId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ancestral-arena-list {
  font-family: "Inter", sans-serif;

  &__item__container {
    padding-bottom: $size-m;
  }

  &__title {
    display: flex;
    align-items: center;
    font-weight: $font-weight-medium;
    font-size: $font-size-heading-6;
    line-height: $line-height-heading;
    color: $color-ink;
    margin-bottom: $size-s;
  }

  &__item:not(:first-child) {
    margin-top: $size-s;
  }

  &__item__actions {
    display: flex;
    justify-content: space-around;
    margin-left: $size-m;
  }

  &__button {
    cursor: pointer;

    &:not(:first-child) {
      margin-left: $size-xs;
    }

    &--report {
      cursor: pointer;
      grid-area: "report";
      text-wrap: nowrap;
      border-radius: 8px;
      padding: $size-s;
      display: flex;
      align-items: center;
      color: #707780;
      border: 1px solid #C9CED4;
    }

    &--mission {
      grid-area: "mission";
    }
  }

  &__button {
    cursor: pointer;
    border-radius: 8px;
    padding: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    @include transition($speed-x-fast);

    :last-child {
      margin-right: $size-s;
    }

    img {
      min-height: 14px;
      min-width: 14px;
    }

    &:hover {
      background-color: #E6E9ED;
      box-shadow: 0 1px 2px -1px rgba(30, 33, 36, .25);
    }

    &:active {
      background:#C9CED4;
      border: 1px solid #C9CED4;
    }
  }
}
</style>
