<template>
  <div class="recomposition-drawer-test-table">
    <skeleton-loader
      v-if="loading"
      width="100%"
      height="600px"
    />
    <s-table
      v-else
      ref="knowledgeTestTable"
      :content="questionsList"
      :default-sort="defaultSort"
      :fields="fields"
      :paginate="true"
      @click-row="clickRow"
    >
      <template
        slot="statement"
        slot-scope="{ row }"
      >
        <div class="recomposition-drawer-test-table__name">
          <h6>
            {{ truncateText( row.statement, statementMaxLength) }}
          </h6>
        </div>
      </template>

      <template
        slot="pickedOption"
        slot-scope="{ row }"
      >
        <p class="recomposition-drawer-test-table__chosen-option">
          {{ getAnswerOption(row.answer) }}
        </p>
      </template>

      <template
        slot="rightOption"
        slot-scope="{ row }"
      >
        <div class="recomposition-drawer-test-table__chosen-option">
          {{ letterIndex(Number(row.answer.correct)) }}
        </div>
      </template>

      <template
        slot="result"
        slot-scope="{ row }"
      >
        <div class="recomposition-drawer-test-table__result">
          <s-badge
            :variation="getResultVariation(row.answer.status)"
          >
            {{ getStatusText(row.answer.status) }}
          </s-badge>
        </div>
      </template>
      <template
        slot="pagination"
        slot-scope="props"
      >
        {{ $tc('report.students.paginationQuestions',
               props.total,
               {
                 from: props.start + 1,
                 to: props.end,
                 total: props.total
               }) }}
      </template>
    </s-table>
  </div>
</template>

<script>
import formatDate from 'App/mixins/formatDate'
import STable from 'App/components/STable'
import truncateText from 'shared/utils/truncateText'

const questionStatusEnum = {
  CORRECT: 'CORRECT',
  WRONG: 'WRONG',
  BLANK: 'BLANK',
}

const questionStatusText = {
  CORRECT: 'Acerto',
  WRONG: 'Erro',
  BLANK: 'Em branco',
}

export default {
  name: 'RecompositionDrawerTestTable',
  components: {
    STable,
  },
  mixins: [
    formatDate,
  ],
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
    activeQuestion: {
      type: Object,
      default: null,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultSort: {
        field: 'statement',
        direction: 'asc',
      },
      fields: [
        {
          value: 'statement',
          text: 'Questão',
          sortable: true,
        },
        {
          value: 'pickedOption',
          text: 'Alternativa escolhida',
          sortable: true,
        },
        {
          value: 'rightOption',
          text: 'Alternativa correta',
          sortable: true,
        },
        {
          value: 'result',
          text: 'Resultado',
        },
      ],
      activeQuestionInnerIndex: -1,
    }
  },
  computed: {
    statementMaxLength() {
      switch (this.$mq) {
        case 'large':
          return 96
        default:
          return 150
      }
    },
    questionsList() {
      return this.questions.map((question) => {
        const answer = question.answer || {}

        return {
          ...question,
          statement: question.statement,
          pickedOption: answer.selected,
          rightOption: answer.correct,
          result: answer.status,
        }
      })
    },
  },
  methods: {
    truncateText,
    letterIndex(index) {
      return String.fromCharCode(65 + index)
    },
    getResultVariation(result) {
      switch (result) {
        case questionStatusEnum.CORRECT:
          return 'success'
        case questionStatusEnum.WRONG:
          return 'danger'
        case questionStatusEnum.BLANK:
          return 'neutral'
        default:
          return 'neutral'
      }
    },
    getStatusText(result) {
      return questionStatusText?.[result] ?? ''
    },
    getAnswerOption(answer) {
      const { status, selected } = answer

      return status === questionStatusEnum.BLANK ? '-' : this.letterIndex(Number(selected))
    },
    clickRow({ data }) {
      this.$emit('select-question', {
        question: data,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.recomposition-drawer-test-table {
  &__name {
    min-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  &__chosen-option {
    text-align: center;
  }

  &__title {
    color: $color-ink;
    margin-bottom: $size-xs;
  }

  &__subtitle {
    display: block;
    color: $color-ink-light;
    margin-bottom: $size-l;
  }

  &__name h6 {
    font-weight: $font-weight-base;
    font-size: $font-size-m;
  }

  ::v-deep .sas-table__body {
    cursor: pointer;
  }

  ::v-deep .sas-badge__text {
    white-space: nowrap;
  }

  ::v-deep .sas-badge i {
    margin-right: $size-xxs;
  }

  ::v-deep .sas-table__header {
    height: $size-xl;
  }

  ::v-deep .sas-badge--neutral {
    background: $color-ink-lightest;
    color: $color-ink;
    border-radius: 100px;
    border: 1px solid $color-ink;
    opacity: 0.75;
  }
}
</style>
