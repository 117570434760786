<template>
  <transition :name="mq_xl__mf ? 'fade' : 'slide-down'">
    <div class="teacher-onboarding">
      <div
        v-if="mq_xl__mf"
        class="teacher-onboarding__overlay"
      />
      <div class="teacher-onboarding__content">
        <button
          v-if="mq_m"
          class="skip"
          @click="skip"
        >
          <Icon type="x" />
          <transition name="shrink-text">
            <p
              v-if="slide === 0"
              class="skip__text"
            >
              {{ $t('commons.skipIntro') }}
            </p>
          </transition>
        </button>
        <div class="illustration">
          <transition name="fade">
            <img
              v-if="slide === 0"
              key="1"
              class="illustration__bg"
              :src="require(
                `App/assets/onboarding-1-bg-${size}.svg`
              )"
            >
            <img
              v-else-if="slide === 1"
              key="2"
              class="illustration__bg"
              :src="require(
                `App/assets/onboarding-2-bg-${size}.svg`
              )"
            >
            <img
              v-else-if="slide === 2"
              key="3"
              class="illustration__bg"
              :src="require(
                `App/assets/onboarding-3-bg-${size}.svg`
              )"
            >
            <img
              v-else-if="slide === 3"
              key="4"
              class="illustration__bg"
              :src="require(
                `App/assets/onboarding-4-bg-${size}.svg`
              )"
            >
            <img
              v-else-if="slide === 4"
              key="5"
              class="illustration__bg"
              :src="require(
                `App/assets/onboarding-5-bg-${size}.svg`
              )"
            >
          </transition>
          <transition name="slide">
            <img
              v-if="slide === 0"
              key="1"
              class="illustration__element slide-1"
              :src="require(
                `App/assets/onboarding-1-element-${size}.svg`
              )"
            >
            <img
              v-if="slide === 1"
              key="2"
              class="illustration__element"
              :src="require(
                `App/assets/onboarding-2-element-${size}.svg`
              )"
            >
            <img
              v-if="slide === 2"
              key="3"
              class="illustration__element"
              :src="require(
                `App/assets/onboarding-3-element-${size}.svg`
              )"
            >
            <img
              v-if="slide === 3"
              key="4"
              class="illustration__element"
              :src="require(
                `App/assets/onboarding-4-element-${size}.svg`
              )"
            >
            <img
              v-if="slide === 4"
              key="5"
              class="illustration__element"
              :src="require(
                `App/assets/onboarding-5-element-${size}.svg`
              )"
            >
          </transition>
        </div>
        <div class="body">
          <transition name="slide-badge">
            <SBadge
              v-if="slide === max - 1"
              class="body__new"
              size="large"
              variation="success"
            >
              {{ $t('commons.new') }}
            </SBadge>
          </transition>
          <div class="body__text">
            <h3
              class="body__text__title"
            >
              {{ $t(`onBoarding.${slide + 1}.title`) }}
            </h3>
            <p
              class="body__text__description"
            >
              {{
                $t(`onBoarding.${slide + 1}.description`)
              }}
            </p>
          </div>
          <SlideBullets
            :total="max"
            :current="slide"
          />
          <div class="body__controls">
            <SButton
              v-if="mq_l__mf"
              size="large"
              variation="tertiary"
              icon-left="x"
              @click="skip"
            >
              {{ $t('commons.skipIntro') }}
            </SButton>
            <div class="body__controls__primary">
              <TeacherOnBoardingFeedback />
              <SButton
                class="body__controls__action"
                size="large"
                @click="action"
              >
                {{ $t(`onBoarding.${slide + 1}.action`) }}
              </SButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import mediaQueries from 'shared/mixins/mediaQueries'
import SlideBullets from 'App/components/SlideBullets'
import { Icon, SButton, SBadge } from '@sas-te/alfabeto-vue'
import TeacherOnBoardingFeedback from './TeacherOnBoardingFeedback'

export default {
  name: 'TeacherOnBoarding',
  components: {
    Icon,
    SBadge,
    SButton,
    SlideBullets,
    TeacherOnBoardingFeedback,
  },
  mixins: [ mediaQueries ],
  data() {
    return {
      slide: 0,
      max: 5,
    }
  },
  computed: {
    size() {
      switch (this.$mq) {
        case 'x_small':
          return 'sm'
        case 'small':
          return 'sm'
        case 'medium':
          return 'sm'
        case 'large':
          return 'md'
        default:
          return 'lg'
      }
    },
  },
  methods: {
    action() {
      if (this.slide < this.max - 1) {
        this.slide += 1
      } else {
        this.skip()
      }
    },
    skip() {
      this.$emit('teacherOnBoardingDone')
    },
  },
}
</script>

<style lang="sass" scoped>
.teacher-onboarding
  z-index: 3

  &__overlay
    z-index: 9998
    +cover(fixed)
    +transition($speed-slow)
    background: rgba($color-ink, .50)

  &__content
    background-color: $color-main
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: 1fr auto
    +cover(fixed)
    top: $menu-height

    +mq-l--mf
      z-index: 9999
      position: fixed
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      height: 80%
      max-height: 587px
      width: 768px
      border-radius: 40px
      box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.25)
      overflow: hidden

  .skip
    z-index: 2
    position: absolute
    top: $size-s
    right: $size-s
    cursor: pointer
    color: $color-white
    background-color: transparent
    border: none
    +transition($speed-x-fast)
    +flex-center

    &:focus
      outline: none

    &__text
      font-size: $font-size-s
      font-weight: $font-weight-bold
      width: 108px
      margin-left: $size-xs / 2
      white-space: nowrap
      overflow: hidden

  .illustration
    overflow: hidden
    width: 100%
    height: 100%
    position: relative
    +flex-center

    &__bg
      position: absolute
      height: 100%
      width: 100%
      object-fit: cover

    &__element
      position: absolute
      max-height: 100%

  .body
    background-color: $color-white
    padding: $size-m $size-m $size-s
    text-align: center
    position: relative
    display: flex
    flex-direction: column

    +mq-m--mf
      padding: 0
      border-top-left-radius: $size-m
      border-top-right-radius: $size-m
      margin-top: -24px
      z-index: 3

    &__text
      margin-bottom: $size-s
      min-height: 24vh
      display: flex
      flex-direction: column
      align-items: center

      +mq-m--mf
        padding: $size-m 170px 0

      +mq-l--mf
        padding: $size-l $size-l 0
        min-height: 138px
        margin-bottom: $size-l

      &__title
        margin-bottom: $size-s

      &__description
        line-height: 26px

        +mq-l--mf
          width: 500px

    .slide-bullets
      margin-bottom: $size-m

      +mq-m--mf
        margin-bottom: $size-l

    &__controls
      .sas-button
        width: 100%

      +mq-m--mf
        border-top: 1px solid transparentize($color-ink-lightest, 0.5)
        padding: $size-s $size-m
        +flex-space-between

        .sas-button
          width: auto

        .body__controls__action
          width: 200px

    &__new
      position: absolute
      top: -18px
      align-self: center

+v-transition-in(slide)
  transform: translateX(150%)

+v-transition-out(slide)
  transform: translateX(-150%)

+v-transition-in(slide-badge)
  transform: translateX(50vw)

+v-transition-out(shrink-text)
  width: 0px !important

+v-transition(slide-down)
  transform: translateY(100%)
</style>
