<template>
  <div class="share-toolbar">
    <template v-if="mq_m">
      <drawer
        v-if="shareFormOpen"
        position="bottom"
        size="300px"
        @close="closeShareForm"
      >
        <ShareForm
          :loading="shareUrlCache.loading"
          :url="shareUrlCache.data[questionnaireCode] || ''"
          @close="closeShareForm"
        />
      </drawer>
    </template>
    <PopoverDropdown
      v-else
      icon-left="share"
      :label="$t('share.share')"
      @openPopover="openShareForm"
      @closePopover="closeShareForm"
    >
      <template #default="{ closePopover }">
        <ShareForm
          :loading="shareUrlCache.loading"
          :url="shareUrlCache.data[questionnaireCode] || ''"
          @close="closePopover"
        />
      </template>
    </PopoverDropdown>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import mediaQueries from 'shared/mixins/mediaQueries'
import PopoverDropdown from 'App/components/PopoverDropdown'
import ShareForm from 'App/components/ShareForm'

export default {
  name: 'ShareToolbar',
  components: {
    PopoverDropdown,
    ShareForm,
  },
  mixins: [ mediaQueries ],
  props: {
    selectedClassroom: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      shareFormOpen: false,
    }
  },
  computed: {
    ...mapGetters([
      'shareUrlCache',
    ]),
    gradeId() {
      return this.selectedClassroom?.grade.id
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
  },
  methods: {
    ...mapActions([
      'getShareUrl',
    ]),
    openShareForm() {
      if (!this.shareUrlCache.data[this.questionnaireCode]) {
        const { questionnaireCode, gradeId } = this
        this.getShareUrl({ questionnaireCode, gradeId })
      }
      this.shareFormOpen = true
    },
    closeShareForm() {
      this.shareFormOpen = false
    },
  },
}
</script>

<style lang="sass" scoped>
.share-toolbar
  ::v-deep
    .drawer
      height: min-content
      border-top-left-radius: $size-s
      border-top-right-radius: $size-s

    +mq-m
      .share-form
        &__header
          padding: $size-xs $size-s
          box-shadow: $shadow-s rgba(0, 0, 0 ,0.25)

        &__body
          padding: $size-s
</style>
