import { render, staticRenderFns } from "./ShareToolbar.vue?vue&type=template&id=5b60ffbc&scoped=true"
import script from "./ShareToolbar.vue?vue&type=script&lang=js"
export * from "./ShareToolbar.vue?vue&type=script&lang=js"
import style0 from "./ShareToolbar.vue?vue&type=style&index=0&id=5b60ffbc&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b60ffbc",
  null
  
)

export default component.exports