<template>
  <div class="extra-activity">
    <Header
      :title="extraActivity.ready ? extraActivity.data.name : ''"
      :navigation="{
        label: $t('assignments.extraActivities'),
        route: {
          name: 'teacher-sas-activities',
          query: {
            classroomId: classroomQueryParam,
            lectureId: $route.query.lectureId
          }
        },
      }"
      :loading="extraActivity.loading"
    >
      <template #right>
        <ClassPicker
          v-model="selectedClassroom"
          :classrooms="filteredClassrooms"
          :loading="loadingClassPicker"
        />
      </template>
      <template #bottom>
        <Tabs
          theme="dark"
          :tabs="tabs"
        />
      </template>
    </Header>
    <ExtraActivityContextWrapper
      :selected-property="selectedProperty"
      @resetProperty="selectedProperty = null"
    >
      <template #groupSelection="{ groupSelection }">
        <ExtraActivityGroupPicker
          v-if="groupSelection"
          v-model="selectedProperty"
          :loading="groupSelection.loading"
          :groups="groupSelection.data"
        />
      </template>
      <template #assignmentsList="{ assignmentsList }">
        <template v-if="assignmentsList">
          <AssignmentsListSkeleton
            v-if="assignmentsList.loading"
          />
          <AssignmentsList
            v-else-if="assignmentsList.data"
            :assignments="assignmentsList.data"
          >
            <template #default="{ assignment }">
              <TeacherAssignmentsActions
                :assignment="assignment"
                :complaint-badge="assignment.numberOfComplaints > 0"
                @action="handleAssignmentAction"
                @select="selectedForSharing = assignment"
              />
            </template>
          </AssignmentsList>
        </template>
      </template>
    </ExtraActivityContextWrapper>
    <ShareModal
      v-if="selectedForSharing"
      :selected-classroom="selectedClassroom"
      :questionnaire-code="selectedForSharing.questionnaireCode"
      @close="selectedForSharing = null"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import mediaQueries from 'shared/mixins/mediaQueries'
import updateRoute from 'shared/mixins/updateRoute'
import Header from 'App/components/Header/Header'
import ClassPicker from 'App/components/ClassPicker/ClassPicker'
import ShareModal from 'App/components/ShareModal'
import Tabs from 'App/components/Tabs'
import AssignmentsList from 'App/components/Assignments/AssignmentsList'
import AssignmentsListSkeleton from 'App/components/Assignments/AssignmentsListSkeleton'
import ExtraActivityGroupPicker from 'App/components/ExtraActivityGroupPicker'
import ExtraActivityContextWrapper from 'App/components/ExtraActivityContextWrapper'
import TeacherAssignmentsActions from 'App/components/TeacherAssignmentsActions'

export default {
  name: 'ExtraActivity',
  components: {
    Header,
    ClassPicker,
    ShareModal,
    ExtraActivityContextWrapper,
    Tabs,
    AssignmentsList,
    AssignmentsListSkeleton,
    ExtraActivityGroupPicker,
    TeacherAssignmentsActions,
  },
  mixins: [ mediaQueries, updateRoute ],
  data() {
    return {
      selectedClassroom: null,
      loadingClassPicker: true,
      loadingGroupPicker: true,
      selectedProperty: null,
      selectedForSharing: null,
    }
  },
  computed: {
    ...mapGetters([
      'classrooms',
      'extraActivity',
      'extraActivitiesDifficulties',
      'extraActivityProperties',
      'yearSelectedContent',
    ]),
    extraActivityPathParam() {
      return parseInt(this.$route.params.extraActivityId, 10)
    },
    classroomQueryParam() {
      return parseInt(this.$route.query.classroomId, 10)
    },
    filteredClassrooms() {
      if (!this.classrooms.ready || this.selectedClassroom === null) {
        return []
      }

      return this.classrooms.data.filter(
        ({ grade }) => grade.id === this.selectedClassroom?.grade?.id
      )
    },
    tabs() {
      return [
        {
          name: this.$t('commons.extraActivitiesTabs.byYear'),
          route: {
            name: 'teacher-extra-activities-by-context',
            params: {
              context: 'year',
            },
            query: this.$route.query,
          },
        },
        {
          name: this.$t('commons.extraActivitiesTabs.byDifficulty'),
          route: {
            name: 'teacher-extra-activities-by-context',
            params: {
              context: 'difficulty',
            },
            query: this.$route.query,
          },
        },
      ]
    },
    requiredProperty() {
      return this.$route.query.property
    },
    requiredContext() {
      return this.$route.query.context
    },
    dataSource() {
      if (this.requiredContext) {
        return this.requiredContext === 'year'
          ? this.extraActivityProperties.data
          : this.extraActivitiesDifficulties.data
      }

      return null
    },
  },
  watch: {
    selectedClassroom() {
      if (this.selectedClassroom?.id) {
        this.updateRouteQuery('classroomId', this.selectedClassroom?.id)
      }
    },
  },
  created() {
    this.fetchClassrooms()
    this.fetchExtraActivity()
    this.redirectToContext()
  },
  methods: {
    ...mapActions([
      'getClassrooms',
      'getExtraActivity',
    ]),
    async fetchExtraActivity() {
      if (!this.extraActivity.ready) {
        this.getExtraActivity(this.extraActivityPathParam)
      }
    },
    async fetchClassrooms() {
      try {
        if (!this.classrooms.ready) {
          await this.getClassrooms(this.yearSelectedContent)
        }
        if (this.classroomQueryParam) {
          this.selectedClassroom = this.classrooms.data
            .find(({ id }) => id === this.classroomQueryParam)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingClassPicker = false
      }
    },
    handleAssignmentAction(assignment) {
      const { status, questionnaireCode } = assignment
      if (status !== 'unreleased') {
        this.$router.push({
          name: 'teacher-extra-activity-report-general',
          params: {
            questionnaireCode,
            classroomId: this.selectedClassroom?.id,
            fromExtraActivities: true,
          },
          query: { ...this.$route.query, context: this.$route.params.context },
        })
      }
    },
    redirectToContext() {
      const defaultTab = this.tabs[0]
      const selectedTab = this.tabs.find((tab) => tab.route.params.context === this.requiredContext)

      const routeToReplace = this.dataSource && selectedTab ? selectedTab : defaultTab
      const shouldBeReplace = routeToReplace.route.name !== this.$route.name

      if (shouldBeReplace) {
        this.$router.replace(routeToReplace.route)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.extra-activity {
  &__group-selection {
    border-bottom: 1px solid transparentize($color-ink-lightest, 0.5);
    padding: $size-s 0;

    @include mq-s--mf {
      padding: $size-m 0;
    }

    @include mq-m--mf {
      padding: $size-l 0;
    }

    @include mq-l--mf {
      border-bottom: none;
      padding: 0;

      .sas-wrapper {
        border-bottom: 1px solid transparentize($color-ink-lightest, 0.5);
        padding: $size-m 0;
      }
    }
  }

  &__assignments {
    padding: {
      top: $size-s;
      bottom: 40vh;
    }

    @include mq-s--mf {
      padding-top: $size-m;
    }

    @include mq-m--mf {
      padding-top: $size-l;
    }
  }

  .class-picker {
    width: auto;
  }

  &__report-button {
    margin-right: $size-xs;
  }
}
</style>
