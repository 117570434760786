// TODO: testar se o export de rotas com componente não causa problemas
// Se for o caso, separar as rotas em `routes` e `configRoutes`
// https://github.com/arcotech-services/sas-childhood-followup-frontend/pull/74

import BaseLayout from 'shared/views/BaseLayout'
import Home from 'App/views/Home/Home'
import SchoolActivities from 'App/views/SchoolActivities/SchoolActivities'
import AncestralArenaReport from 'App/views/AncestralArena/AncestralArenaReport'
import SasActivities from 'App/views/SasActivities/SasActivities'
import LogosReport from 'App/views/LogosReport/LogosReport'
import PageError from 'shared/views/PageError'
import LogosMissionReport from 'App/views/LogosMissionReport/LogosMissionReport'
import ExtraActivity from 'App/views/ExtraActivity/ExtraActivity'
import ExtraActivityContextWrapper from 'App/components/ExtraActivityContextWrapper'
import ClassReport from 'App/views/ClassReport/ClassReport'
import GeneralView from 'App/components/GeneralView/GeneralView'
import QuestionsView from 'App/components/QuestionsView/QuestionsView'
import StudentsView from 'App/components/StudentsView/StudentsView'
import StudentsPerformance from 'App/views/StudentsPerformance/StudentsPerformance'
import SyllabusClassroomPerformance from 'App/views/Syllabus/ClassroomPerformance'

const routes = [
  {
    path: '/error/:status',
    name: 'error',
    component: PageError,
  },
  {
    path: '/',
    component: BaseLayout,
    children: [
      {
        path: '',
        component: Home,
        children: [
          {
            path: 'school-activities',
            name: 'teacher-school-activities',
            component: SchoolActivities,
          },
          {
            path: 'my-activities',
            name: 'teacher-my-activities',
            component: SchoolActivities,
          },
          {
            path: 'arena-ancestral',
            name: 'teacher-arena-ancestral',
            component: AncestralArenaReport,
          },
          {
            path: 'sas-activities',
            name: 'teacher-sas-activities',
            component: SasActivities,
          },
          {
            path: 'logos-report',
            name: 'teacher-logos-report',
            component: LogosReport,
          },
        ],
      },
      {
        path: 'recomposition/recomposition-chapter/:recompositionChapterId/classroom/:classroomId/progress',
        name: 'teacher-recomposition-chapter-report',
        component: LogosMissionReport,
      },
      {
        path: 'extra-activity/:extraActivityId',
        name: 'teacher-extra-activity',
        component: ExtraActivity,
        children: [
          {
            path: 'by/:context',
            name: 'teacher-extra-activities-by-context',
            component: ExtraActivityContextWrapper,
          },
        ],
      },
      {
        path: 'questionnaires/:questionnaireCode/classes/:classroomId',
        component: ClassReport,
        children: [
          {
            path: 'general',
            name: 'teacher-report-general',
            component: GeneralView,
          },
          {
            path: 'questions',
            name: 'teacher-report-questions',
            component: QuestionsView,
          },
          {
            path: 'students',
            name: 'teacher-report-students',
            component: StudentsView,
          },
        ],
      },
      {
        path: 'subjects/:subjectId/classrooms/:classroomId/students/performance',
        name: 'teacher-performance-students',
        props: (route) => ({
          ...route.params,
          classroomId: route.params.classroomId ? parseInt(route.params.classroomId, 10) : null,
          subjectId: route.params.subjectId ? parseInt(route.params.subjectId, 10) : null,
          lectureId: route.query.lectureId ? parseInt(route.query.lectureId, 10) : null,
        }),
        component: StudentsPerformance,
      },
      {
        path: 'extra-activity/:extraActivityId/questionnaires/:questionnaireCode/classes/:classroomId',
        component: ClassReport,
        props: true,
        children: [
          {
            path: 'general',
            name: 'teacher-extra-activity-report-general',
            component: GeneralView,
          },
          {
            path: 'questions',
            name: 'teacher-extra-activity-report-questions',
            component: QuestionsView,
          },
          {
            path: 'students',
            name: 'teacher-extra-activity-report-students',
            component: StudentsView,
          },
        ],
      },
      {
        path: 'exercicios-personalizados',
        component: SyllabusClassroomPerformance,
        name: 'syllabus',
      },
    ],
  },
  {
    path: '*',
    redirect: {
      name: 'error',
      params: { status: 404 },
    },
  },
]

export default routes
