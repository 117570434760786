<template>
  <wrapper>
    <StudentsViewHeader
      :loading="loading"
      :students-count="students.length"
    />
    <StudentsViewSearch
      :loading="loading"
      :has-filter-by="false"
    />
    <template v-if="mq_m">
      <template v-if="loading">
        <StudentsViewCardsSkeleton />
      </template>
      <template v-else>
        <StudentsPerformanceViewCards
          :students="filteredStudents"
          @selectStudent="selectStudent"
        />
      </template>
    </template>
    <template v-else>
      <template v-if="loading">
        <StudentsViewTableSkeleton />
      </template>
      <template v-else>
        <StudentsPerformanceViewTable
          ref="studentsTable"
          :active-student="activeStudent"
          :students="filteredStudents"
          :is-paginate="false"
          @selectStudent="selectStudent"
        />
      </template>
    </template>
    <HistoricListDrawer
      v-if="activeStudent"
      v-bind="historicDrawerData"
      :student="activeStudent"
      :subject="subject"
      :has-previous="hasPreviousStudent"
      :has-next="hasNextStudent"
      :classroom-id="classroomId"
      @close="clearSelectedStudent"
      @previous="previousStudent"
      @next="nextStudent"
    />
  </wrapper>
</template>

<script>
import { isEmpty } from 'App/utils/arrays'
import mediaQueries from 'shared/mixins/mediaQueries'
import accentFold from 'App/mixins/accentFold'
import StudentsViewHeader from 'App/components/StudentsView/StudentsViewHeader'
import StudentsViewSearch from 'App/components/StudentsView/StudentsViewSearch'
import StudentsViewCardsSkeleton from 'App/components/StudentsView/StudentsViewCardsSkeleton'
import StudentsViewTableSkeleton from 'App/components/StudentsView/StudentsViewTableSkeleton'
import HistoricListDrawer from 'App/components/ProposedActivitiesDrawer/HistoricListDrawer'
import StudentsPerformanceViewCards from './StudentsPerformanceViewCards'
import StudentsPerformanceViewTable from './StudentsPerformanceViewTable'

export default {
  name: 'StudentsPerformanceView',
  components: {
    StudentsViewHeader,
    StudentsViewSearch,
    StudentsPerformanceViewCards,
    StudentsViewCardsSkeleton,
    StudentsPerformanceViewTable,
    StudentsViewTableSkeleton,
    HistoricListDrawer,
  },
  mixins: [
    mediaQueries,
    accentFold,
  ],
  props: {
    students: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectedClassroom: {
      type: Object,
      default: () => ({}),
    },
    classroomId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      searchString: '',
      activeStudentInnerIndex: -1,
    }
  },
  computed: {
    filteredStudents() {
      return [ ...this.students ].filter((student) => {
        const searchString = this.searchString.toLocaleLowerCase()
        const studentName = student.name.toLocaleLowerCase()

        return this.accentFold(studentName).includes(this.accentFold(searchString))
      })
    },
    activeStudent() {
      const { activeStudent } = this.$route.query
      if (!activeStudent) return null

      return this.students.find(({ id }) => id === parseInt(activeStudent, 10))
    },
    activeStudentIndex() {
      return this.filteredStudents.findIndex((student) => (
        student.id === this.activeStudent.id
      ))
    },
    hasPreviousStudent() {
      return this.activeStudentInnerIndex > 0
    },
    hasNextStudent() {
      return this.activeStudentInnerIndex < this.filteredStudents.length - 1
    },
    historicDrawerData() {
      const grade = this.selectedClassroom.grade?.name || ''
      const classroom = this.$t('commons.classroom', { name: this.selectedClassroom.name })
      const shift = this.$t(`shifts.${this.selectedClassroom.shift}`)

      return {
        title: this.activeStudent.name,
        subtitle: `${grade} · ${classroom} · ${shift}`,
      }
    },
    subject() {
      if (isEmpty(this.selectedClassroom)) {
        return {}
      }

      const classroomLectures = this.selectedClassroom.lectures
      const lectureId = parseInt(this.$route.query.lectureId, 10)

      return classroomLectures.find(({ id }) => id === lectureId)?.subject
    },
  },
  watch: {
    '$route.query': 'setFilterParams',
  },
  mounted() {
    this.setFilterParams(this.$route.query)
  },
  methods: {
    setFilterParams(query) {
      const {
        name,
      } = query
      this.searchString = name || ''
    },
    selectStudent({ data, index }) {
      const { id } = data
      const { name, params, query } = this.$route
      const activeStudent = parseInt(this.$route.query.activeStudent, 10)

      this.activeStudentInnerIndex = index
      if (id !== activeStudent) {
        this.$router.push({
          name,
          params,
          query: {
            ...query,
            activeStudent: id,
          },
        })
      }
    },
    clearSelectedStudent() {
      const query = { ...this.$route.query }

      delete query.activeStudent
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query,
      })
    },
    previousStudent() {
      if (this.mq_m) {
        if (this.activeStudentIndex > 0) {
          this.selectStudent({
            data: this.filteredStudents[this.activeStudentIndex - 1],
            index: this.activeStudentIndex - 1,
          })
        }
      } else {
        this.$refs.studentsTable.previousStudent()
      }
    },
    nextStudent() {
      if (this.mq_m) {
        if (this.activeStudentIndex < this.filteredStudents.length - 1) {
          this.selectStudent({
            data: this.filteredStudents[this.activeStudentIndex + 1],
            index: this.activeStudentIndex + 1,
          })
        }
      } else {
        this.$refs.studentsTable.nextStudent()
      }
    },
  },
}
</script>
