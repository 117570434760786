<template>
  <div class="teacher-assignments-actions">
    <s-badge
      v-if="complaintBadge"
      v-tooltip.bottom="$t('questions.reportAProblem.studentsReportedAProblem')"
      variation="warning"
      icon-left="flag"
    >
      {{ $t('questions.reportAProblem.problemReported') }}
    </s-badge>
    <s-button
      class="teacher-assignments-actions__report-button"
      variation="secondary"
      @click="$emit('action', assignment)"
    >
      {{ $t('commons.seeReport') }}
    </s-button>
    <CustomDropdown
      :origin="`bottom-${complaintBadge ? 'right' : 'left'}`"
      text=""
      variation="tertiary"
      icon="more-horizontal"
      @click.stop
    >
      <CustomDropdownItem
        v-if="enableDelete"
        icon-left="trash"
        @click.stop="$emit('delete')"
      >
        {{ $t('commons.delete') }}
      </CustomDropdownItem>
      <CustomDropdownItem
        icon-left="share"
        @click.stop="$emit('select')"
      >
        {{
          mq_m
            ? $t('share.share')
            : $t('share.title')
        }}
      </CustomDropdownItem>
    </CustomDropdown>
  </div>
</template>

<script>
import mediaQueries from 'shared/mixins/mediaQueries'
import CustomDropdown from 'App/components/CustomDropdown'
import CustomDropdownItem from 'App/components/CustomDropdownItem'

export default {
  name: 'TeacherAssignmentsActions',
  components: {
    CustomDropdown,
    CustomDropdownItem,
  },
  mixins: [ mediaQueries ],
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    enableDelete: {
      type: Boolean,
      default: false,
    },
    complaintBadge: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.teacher-assignments-actions {
  @include flex-center;

  @include mq-m {
    justify-content: space-between;
    width: 100%;
  }

  & > *:not(:last-child) {
    margin-right: $size-xs;
  }
}
</style>
