<template>
  <drawer
    :size="historicDrawerSize"
    :disable-overlay="true"
    :disable-backdrop-click="true"
    @close="$emit('close')"
  >
    <DrawerHeader
      hide-more-options
      :title="title"
      :subtitle="subtitle"
      :has-previous="hasPrevious"
      :has-next="hasNext"
      @previous="$emit('previous')"
      @next="$emit('next')"
      @close="$emit('close')"
    />
    <section class="historic-list-drawer-body">
      <EmptyState
        v-if="historicList.error"
        v-bind="historicList.error"
      >
        <s-button
          icon-left="refresh-ccw"
          @click="getHistoric"
        >
          {{ $t('commons.tryAgain') }}
        </s-button>
      </EmptyState>
      <template v-else>
        <AssignmentExtraDetails
          class="historic-list"
          title="Histórico por atividade"
          :subtitle="drawerSubtitle"
        />
        <div class="historic-list-drawer__list-items">
          <BoxListItem
            v-if="historicList.loading"
            :loading="historicList.loading"
            variation="row"
          />
          <template v-else>
            <BoxListItem
              v-for="(historic, index) in historicList.data"
              :key="historic.code"
              :title="historic.title"
              :subtitle="historic.subtitle"
              variation="row"
            >
              <s-button
                variation="secondary"
                @click="toggleHistoricQuestionsDrawer({ historic, index })"
              >
                {{ $t('commons.seeReport') }}
              </s-button>
            </BoxListItem>
          </template>
        </div>
      </template>
    </section>
    <ProposedQuestionsDrawer
      v-if="isQuestionsDrawerOpen"
      :assignment-info="assignmentInfo"
      :has-previous="hasPreviousHistoric"
      :has-next="hasNextHistoric"
      :hide-more-options="true"
      :disable-controls="true"
      :student="mappedStudent"
      :classroom-id="classroomId"
      @previous="previousHistoric"
      @next="nextHistoric"
      @close="closeHistoricQuestions"
    />
  </drawer>
</template>

<script>
import { isEmpty } from 'App/utils/arrays'
import api from '@/service/proposedAssignment'
import updateRoute from 'shared/mixins/updateRoute'
import DrawerHeader from 'App/components/DrawerPartials/DrawerHeader/DrawerHeader'
import BoxListItem from 'App/components/BoxListItem'
import EmptyState from 'App/components/EmptyState'
import ProposedQuestionsDrawer from 'App/components/ProposedActivitiesDrawer/ProposedQuestionsDrawer'
import AssignmentExtraDetails from 'App/components/DrawerPartials/AssignmentExtraDetails'

export default {
  name: 'HistoricListDrawer',
  components: {
    DrawerHeader,
    BoxListItem,
    EmptyState,
    ProposedQuestionsDrawer,
    AssignmentExtraDetails,
  },
  mixins: [ updateRoute ],
  props: {
    classroomId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    subject: {
      type: Object,
      default: () => ({}),
    },
    student: {
      type: Object,
      required: true,
    },
    hasPrevious: Boolean,
    hasNext: Boolean,
  },
  data() {
    return {
      isQuestionsDrawerOpen: false,
      assignmentInfo: {},
      historicList: {
        data: [],
        error: null,
        loading: false,
      },
      activeHistoricIndex: 0,
    }
  },
  computed: {
    drawerSubtitle() {
      const { subject } = this
      if (isEmpty(this.subject)) return ''

      return `Reforço de ${subject.name.toLowerCase()}`
    },
    activeStudent() {
      return this.$route.query.activeStudent
    },
    historicDrawerSize() {
      return this.isQuestionsDrawerOpen ? '100%' : this.$tokens.device_m
    },
    mappedStudent() {
      if (this.assignmentInfo.code) {
        return {
          ...this.student,
          classroom: this.subtitle,
          assignmentCode: this.assignmentInfo.code,
          status: 'finished',
        }
      }

      return this.student
    },
    hasPreviousHistoric() {
      return this.activeHistoricIndex > 0
    },
    hasNextHistoric() {
      return this.activeHistoricIndex < this.historicList.data.length - 1
    },
  },
  watch: {
    activeStudent(newStudent, oldStudent) {
      if (oldStudent !== undefined && oldStudent !== newStudent) {
        this.getHistoric()
      }
    },
  },
  async mounted() {
    await this.getHistoric()

    this.usePreviousSelectedHistoric()
  },
  methods: {
    async getHistoric() {
      this.historicList.error = null
      this.historicList.loading = true

      try {
        const studentId = this.$route.query.activeStudent
        const { data } = await api.getProposedAssignmentHistoric(this.subject.id, studentId)

        this.historicList.data = data
      } catch {
        this.historicList.error = {
          title: 'Ocorreu um erro para mostrar esse relatório',
          description: 'Por favor, tente novamente',
          image: 'error',
        }
      } finally {
        this.historicList.loading = false
      }
    },
    toggleHistoricQuestionsDrawer({ historic, index }) {
      const { isQuestionsDrawerOpen } = this

      this.isQuestionsDrawerOpen = !isQuestionsDrawerOpen
      this.selectHistoric({ historic, index })
    },
    selectHistoric({ historic, index }) {
      this.assignmentInfo = {
        code: historic?.code || null,
        name: historic?.title || '',
        subject: this.subject.name,
        finishedAt: historic?.subtitle || '',
      }
      this.activeHistoricIndex = index
      this.handleHistoricOnRoute()
    },
    handleHistoricOnRoute() {
      const { isQuestionsDrawerOpen } = this

      if (isQuestionsDrawerOpen) {
        this.updateRouteQuery('activeHistoric', this.assignmentInfo.code)
      } else {
        this.clearSelectedHistoric()
      }
    },
    clearSelectedHistoric() {
      const query = { ...this.$route.query }

      delete query.activeHistoric

      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query,
      })
    },
    usePreviousSelectedHistoric() {
      const { activeHistoric } = this.$route.query

      if (activeHistoric) {
        const historic = this.historicList.data.find(({ code }) => activeHistoric === code)
        const index = this.historicList.data.findIndex(({ code }) => code === historic.code)

        if (historic) {
          this.toggleHistoricQuestionsDrawer({ historic, index })
        }
      }
    },
    previousHistoric() {
      if (this.hasPreviousHistoric) {
        this.selectHistoric({
          historic: this.historicList.data[this.activeHistoricIndex - 1],
          index: this.activeHistoricIndex - 1,
        })
      }
    },
    nextHistoric() {
      if (this.hasNextHistoric) {
        this.selectHistoric({
          historic: this.historicList.data[this.activeHistoricIndex + 1],
          index: this.activeHistoricIndex + 1,
        })
      }
    },
    closeHistoricQuestions() {
      this.clearSelectedHistoric()
      this.activeHistoricIndex = 0
      this.isQuestionsDrawerOpen = false
    },
  },
}
</script>
<style lang="scss" scoped>
.historic-list-drawer-body {
  padding: $size-m $size-l;
}

.historic-list-drawer__list-items {
  margin-top: $size-m;

  .box-list-item:not(:last-child) {
    margin-bottom: $size-s;
  }
}

::v-deep .historic-list.assignment-extra-details {
  padding: 0;
}
</style>
