import { urls } from 'shared/utils/urls'

import http from './http'
import legacyApi from './legacy'

const configAssignmentReportBff = { baseURL: urls.ASSIGNMENT_REPORT_BFF }

export default {
  getUser: () => http.get('v1/users/me', configAssignmentReportBff),
  getPersonalData: () => http.get('v2/personal-data', configAssignmentReportBff),
  getSignedIn: () => http.get('signin'),
  getReallocationDateLimit: () => legacyApi.get('/users/me/reallocation-login-date-limit'),
  postExchangeToken: (token) => http.post('/v1/exchange-token/onb-to-sas/', { token }, configAssignmentReportBff),
}
