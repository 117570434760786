<template>
  <div class="teacher-assignments-actions">
    <s-button
      class="teacher-assignments-actions__report-button"
      variation="secondary"
      @click="$emit('action', assignment)"
    >
      Acessar relatório
    </s-button>
    <div
      v-tooltip.top="'Compartilhar atividade'"
      class="teacher-assignments-actions__icon"
      @click.stop="$emit('select')"
    >
      <img :src="require('MFE/assets/icons/share.svg')">
    </div>
  </div>
</template>

<script>
import mediaQueries from 'shared/mixins/mediaQueries'

export default {
  name: 'TeacherAssignmentsActions',
  components: {
  },
  mixins: [ mediaQueries ],
  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.teacher-assignments-actions {
  @include flex-center;

  @include mq-m {
    justify-content: space-between;
    width: 100%;
  }

  & > *:not(:last-child) {
    margin-right: $size-xs;
  }

  &__report-button {
    &:hover {
      background-color: #E6E9ED;
      border: 1px solid #C9CED4;
      box-shadow: 0 1px 2px -1px rgba(30, 33, 36, .25);
    }

    @include mq_m {
      width: 100%;
    }
  }

  ::v-deep .sas-button {
    height: unset;
    padding: 12px;
    box-shadow: none;
    font-size: $font-size-s;
  }

  ::v-deep .tooltip .tooltip-inner {
    border-radius: 8px;
  }

  &__icon {
    cursor: pointer;
    border-radius: 8px;
    padding: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid #fff;
    @include transition($speed-x-fast);

    img {
      min-height: 14px;
      min-width: 14px;
    }

    &:hover {
      background-color: #E6E9ED;
      box-shadow: 0 1px 2px -1px rgba(30, 33, 36, .25);
    }

    &:active {
      background:#C9CED4;
      border: 1px solid #C9CED4;
    }
  }
}
</style>
