import axios from 'axios'

import { addAuthorizationHeader, handleResponseInterceptorError } from 'App/utils/auth'
import { urls } from 'shared/utils/urls'

const legacyApi = axios.create({
  baseURL: urls.AUTH_API_V2,
  timeout: process.env.VUE_APP_TIMEOUT,
})

legacyApi.interceptors.request.use(addAuthorizationHeader)

legacyApi.interceptors.response.use((response) => response, handleResponseInterceptorError)

export default legacyApi
