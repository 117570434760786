<template>
  <div class="share-form">
    <header class="share-form__header">
      <div>
        <h4 class="share-form__title">
          Compartilhar atividade
        </h4>
        <p class="share-form__description">
          Copie o link a seguir e compartilhe com os estudantes para acessarem a atividade
        </p>
      </div>
      <s-button
        icon="x"
        variation="tertiary"
        @click="$emit('close')"
      />
    </header>
    <main class="share-form__body">
      <div>
        <label
          class="sas-input__label"
          for="url"
        >
          Link
        </label>
        <div class="share-url">
          <input
            ref="url"
            class="sas-input__field --medium"
            type="text"
            readonly
            :value="url"
          >
        </div>
      </div>
    </main>
    <footer class="share-form__footer">
      <SButton
        variation="secondary"
        size="large"
        :loading="loading"
        @click="$emit('close')"
      >
        Voltar
      </SButton>
      <SButton
        v-tooltip.top="{
          content: $t('share.copied'),
          trigger: 'manual',
          show: copiedTooltipOpen,
        }"
        size="large"
        :loading="loading"
        @click="copyToClipboard"
      >
        Copiar link
      </SButton>
    </footer>
  </div>
</template>

<script>
import { SButton } from '@sas-te/alfabeto-vue'

export default {
  name: 'ShareForm',
  components: {
    SButton,
  },
  props: {
    url: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      copiedTooltipOpen: false,
    }
  },
  methods: {
    async copyToClipboard() {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(this.url)
      } else {
        const urlInput = this.$refs.url
        urlInput.focus()
        urlInput.select()
        document.execCommand('copy')
      }
      this.copiedTooltipOpen = true
      setTimeout(() => {
        this.copiedTooltipOpen = false
      }, 1500)
    },
  },

}
</script>

<style lang="scss" scoped>
.share-form {
  font-family: "Inter", sans-serif;

  &__header {
    @include flex-space-between;
    position: relative;
    padding: 0 $size-m;

    button {
      position: absolute;
      top: -12px;
      right: 12px;
    }
  }

  &__body {
    padding: 0 $size-m;
  }

  &__title {
    font-size: $font-size-heading-5;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 20px;
  }

  &__description {
    margin-bottom: $size-m;
    color: $color-ink;
    font-weight: $font-weight-regular;
  }
}

.share-url {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include mq-s--mf {
    flex-direction: row;
    justify-content: center;
  }

  .sas-button {
    padding: 0 $size-m;
  }

  .sas-input__field {
    margin-bottom: $size-m;
    background: #F7F9FA;
    border: 1px solid #E6E9ED;
    color: #707780;
    height: $element-size-l;
    font-size: $font-size-m;
  }
}

.share-form__footer {
  display: flex;
  justify-content: flex-end;
  gap: $size-m;
  border-top: 1px solid #E6E9ED;
  padding: $size-m $size-m 0 $size-m;

  .sas-button.--secondary, .sas-button.--primary {
    font-family: "Inter", sans-serif;
    box-shadow: none;
    border-radius: 8px;
  }

  @include mq_m {
    justify-content: center;
    flex-direction: column-reverse;
    gap: $size-xs;
  }
}

</style>
