const ready = (state) => {
  let hasData = false
  if (typeof state === 'object' && state !== null && state.data) {
    if (Array.isArray(state.data) && state.data.length > 0) {
      hasData = true
    } else {
      hasData = Object.values(state.data).length > 0
    }
  } else if (typeof state.data === 'string') {
    hasData = state.data.length > 0
  } else if (typeof state.data === 'number') {
    hasData = true
  }

  return hasData
    && state.error === null
    && !state.loading
}

const getContentYear = (contractType, adaptContentYear, contentYear) => {
  if (contractType === 'ADAPT') {
    return adaptContentYear
  }

  return contentYear
}

export {
  ready,
  getContentYear,
}
