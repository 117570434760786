import { isNil } from 'lodash'

export default {
  methods: {
    getValueAsString(value) {
      return typeof value === 'number' ? value.toString() : value
    },
    getIsDifferentOfCurrentQuery(name, value) {
      const currentQuery = this.$route.query[name]
      const newValue = this.getValueAsString(value)

      return currentQuery !== newValue
    },
    getIsDifferentOfCurrentParam(name, value) {
      const currentParam = this.$route.params[name]
      const newValue = this.getValueAsString(value)

      return currentParam !== newValue
    },
    updateRouteQuery(param, value) {
      if (isNil(param) || isNil(value)) {
        console.error('updateRouteQuery: param or value is null/undefined', { param, value })

        return
      }

      const isNewValue = this.getIsDifferentOfCurrentQuery(param, value)

      if (!isNewValue || !this.$router) return

      const query = {
        ...this.$route.query,
        [param]: value,
      }
      const { name, params } = this.$route

      this.$router.push({
        name,
        params,
        query,
      })
    },
    updateRouteParam(param, value) {
      const isNewValue = this.getIsDifferentOfCurrentParam(param, value)
      if (!isNewValue || !this.$router) return

      const { name, query } = this.$route
      const params = {
        ...this.$route.params,
        [param]: value,
      }

      this.$router.push({
        name,
        params,
        query,
      })
    },
    updateRouteQueryWithReplace(param, value) {
      const isNewValue = this.getIsDifferentOfCurrentQuery(param, value)

      if (!isNewValue || !this.$router) return

      const query = {
        ...this.$route.query,
      }

      if (isNil(value)) {
        delete query[param]
      } else {
        query[param] = value
      }

      const { name, params } = this.$route

      this.$router.replace({
        name,
        params,
        query,
      })
    },
  },
}
