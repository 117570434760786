<template>
  <STable
    ref="studentsTable"
    :content="students"
    :selected-row="activeStudent"
    :fields="fields"
    @click-row="selectStudent"
  >
    <template
      slot="name"
      slot-scope="{ row }"
    >
      <div :class="$style.student">
        <Avatar
          :class="$style.avatar"
          :src="row.profilePicture"
          :title="row.student.name"
        />
        <h6 :class="$style.studentName">
          {{ row.student.name }}
        </h6>
      </div>
    </template>
    <template
      slot="hitRate"
      slot-scope="{ row }"
    >
      <span :class="$style.progress">
        {{ getProgress(row) }}
      </span>
    </template>
    <template
      slot="details"
      slot-scope="{ row }"
    >
      <SButton
        v-if="row.status !== status.notAvailable"
        variation="secondary"
        :loading="row.loading"
      >
        Histórico
      </SButton>
    </template>
  </STable>
</template>

<script>
import mediaQueries from 'shared/mixins/mediaQueries'

import { SButton } from '@sas-te/alfabeto-vue'

import Avatar from 'App/components/Avatar'
import STable from 'App/components/STable'

export default {
  name: 'SyllabusStudentsTable',
  components: {
    Avatar,
    SButton,
    STable,
  },
  mixins: [
    mediaQueries,
  ],
  props: {
    students: {
      type: Array,
      required: true,
    },
    activeStudent: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          value: 'name',
          text: 'Aluno',
        },
        {
          value: 'hitRate',
          text: 'Progresso',
        },
        {
          value: 'details',
          text: '',
          width: '1px',
        },
      ],
      status: {
        notAvailable: 'Não disponível',
      },
    }
  },
  methods: {
    selectStudent({ data, dataIndex }) {
      if (data.status === this.status.notAvailable) {
        return
      }

      this.$trackEvent({
        category: this.$track.category.syllabusHome,
        action: this.$track.action.syllabus.accessStudentHistory,
      })

      this.$emit('select-student', { student: data, studentIndex: dataIndex })
    },
    getProgress(student) {
      return student.status !== this.status.notAvailable
        ? `${student.numberOfAnsweredQuestions}/${student.numberOfQuestions} questões`
        : this.status.notAvailable
    },
  },
}
</script>

<style lang="scss" module>
.student {
  display: flex;
  align-items: center;
}

.avatar {
  height: $element-size-m !important;
  width: $element-size-m !important;
  min-height: $element-size-m !important;
  min-width: $element-size-m !important;
  margin-right: 12px;
}

.studentName {
  font-size: $font-size-m;
}

.progress {
  font-size: $font-size-s;
  white-space: nowrap;
}
</style>
