<template>
  <div class="class-difficulties">
    <ClassDifficultiesHeader
      :loading="difficulties.loading"
      :downloading="downloading"
      :can-be-exported="hasDifficulties"
      :export-button-is-visible="exportButtonIsVisible"
      :questionnaire="questionnaire"
      @viewAll="$emit('view-all')"
      @exportQuestions="exportQuestions"
    />
    <MagnaMissionBanner
      v-if="isEurekaGradeSelectedClassroom"
      class="class-difficulties__banner"
      :can-generate-mission="hasDifficulties"
      @generate-reinforcement-mission="showReinforcementModal"
    />
    <template v-if="difficulties.loading || isNotEmpty(difficulties.data)">
      <template v-if="mq_m">
        <template v-if="!difficulties.loading">
          <ClassDifficultiesCards
            :questions="difficulties.data"
            @selectQuestion="selectQuestion"
          />
          <s-button
            class="class-difficulties__export"
            icon-left="download"
            width="100%"
            :disabled="!hasDifficulties"
            :loading="downloading"
            @click="exportQuestions"
          >
            {{
              $t('report.difficulties.export.long')
            }}
          </s-button>
        </template>
        <ClassDifficultiesCardsSkeleton v-else />
      </template>
      <ClassDifficultiesTable
        v-else
        ref="questionsTable"
        :loading="difficulties.loading"
        :questions="difficulties.data"
        :active-question="activeQuestion"
        @select-question="selectQuestion"
      />
    </template>
    <EmptyState
      v-else-if="isEmpty(difficulties.data)"
      :title="emptyState.title"
      :description="emptyState.description"
      :image="emptyState.image"
    />
    <QuestionDrawer
      v-if="activeQuestion"
      :question="activeQuestion"
      :has-previous="hasPreviousQuestion"
      :has-next="hasNextQuestion"
      :disabled-report="reportIsDisabled"
      :is-teacher-challenge="isTeacherChallenge"
      @on-close="clearSelectedQuestion"
      @on-previous="previousQuestion"
      @on-next="nextQuestion"
    />
    <ReinforcementModal
      v-if="isReinforcementModalVisible"
      :reinforcement-mission-title="reinforcementMissionTitle"
      :has-error="isMissionNameAlreadyExists"
      :classroom-name="activeClassroomName"
      @confirm="createReinforcementMission"
      @close="closeReinforcementModal"
      @cancel="closeReinforcementModal"
    />
    <CreateMagnaMissionModal
      v-if="isCreateMagnaMissionModalVisible"
      :questionnaire-code="questionnaireCodeOfMagnaMissionCreated"
      @cancel="closeCreateMagnaMissionModal"
      @close="closeCreateMagnaMissionModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import arenaApi from '@/service/arena'
import statisticsApi from '@/service/statistics'
import { isEmpty, isNotEmpty } from 'App/utils/arrays'
import mediaQueries from 'shared/mixins/mediaQueries'
import httpCodes from 'App/enums/httpCodes'
import download from 'App/mixins/download'
import EmptyState from 'App/components/EmptyState'
import ReinforcementModal from 'App/components/ReinforcementModal'
import CreateMagnaMissionModal from 'App/components/CreateMagnaMissionModal'
import QuestionDrawer from 'App/components/QuestionDrawer/QuestionDrawer'
import ClassDifficultiesHeader from './ClassDifficultiesHeader'
import ClassDifficultiesCards from './ClassDifficultiesCards'
import ClassDifficultiesCardsSkeleton from './ClassDifficultiesCardsSkeleton'
import ClassDifficultiesTable from './ClassDifficultiesTable'
import MagnaMissionBanner from './MagnaMissionBanner'

const originEnum = {
  TEACHER: 'TEACHER',
  SAS: 'SAS',
}

export default {
  name: 'ClassDifficulties',
  components: {
    ClassDifficultiesHeader,
    ClassDifficultiesCards,
    ClassDifficultiesCardsSkeleton,
    ClassDifficultiesTable,
    MagnaMissionBanner,
    QuestionDrawer,
    EmptyState,
    ReinforcementModal,
    CreateMagnaMissionModal,
  },
  mixins: [
    mediaQueries,
    download,
  ],
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
    isArenaMission: Boolean,
    isEurekaGradeSelectedClassroom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeQuestionTableIndex: -1,
      isReinforcementModalVisible: false,
      isCreateMagnaMissionModalVisible: false,
      magnaMission: {
        loading: false,
        error: null,
        data: null,
      },
      difficulties: {
        loading: false,
        error: false,
        data: [],
      },
      emptyState: {
        image: 'empty-class-difficulties',
        title: 'Nenhuma questão aqui ainda',
        description: 'É preciso que mais alunos finalizem a atividade para identificar as dificuldades da turma.',
      },
    }
  },
  computed: {
    ...mapGetters([ 'activeClassroom', 'yearSelectedContent' ]),
    ...mapState({
      showEurekaChallengeReinforcement: ({ featureToggles }) => featureToggles
        .toggles.eurekaChallengeReinforcement,
    }),
    isTeacherChallenge() {
      return this.questionnaire.data?.isTeacherChallenge ?? false
    },
    activeClassroomName() {
      return this.activeClassroom?.data?.name ?? ''
    },
    fileName() {
      if (!this.questionnaire.data) {
        return ''
      }

      const { grade, name } = this.activeClassroom.data
      const { subtitle, title } = this.questionnaire.data

      return this.$t(
        'report.difficulties.export.fileName',
        {
          grade: grade.name,
          classroom: name,
          subtitle,
          title,
        }
      )
    },
    activeQuestion() {
      const { activeStudent, activeQuestion } = this.$route.query
      if (activeStudent || !activeQuestion) return null

      const [ question ] = this.difficulties.data.filter((item) => (
        item.id === activeQuestion
      ))

      return question || {}
    },
    activeQuestionIndex() {
      return this.difficulties.data.findIndex((item) => (
        item.id === this.activeQuestion.id
      ))
    },
    hasPreviousQuestion() {
      return this.activeQuestionTableIndex > 0
    },
    hasNextQuestion() {
      return this.activeQuestionTableIndex < this.difficulties.data.length - 1
    },
    hasDifficulties() {
      return isNotEmpty(this.difficulties.data)
    },
    questions() {
      if (this.hasDifficulties) {
        return this.difficulties.data.map(({ id }) => ({ id }))
      }

      return []
    },
    classroomId() {
      return this.$route.params.classroomId
    },
    reinforcementMissionTitle() {
      if (isNotEmpty(this.questionnaire.data)) {
        return `Reforço ${this.questionnaire.data.title}`
      }

      return ''
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    questionnaireCodeOfMagnaMissionCreated() {
      return this.magnaMission.data?.code
    },
    reportIsDisabled() {
      return this.questionnaire.data.isClassroomActivity
        || this.isTeacherChallenge
    },
    exportButtonIsVisible() {
      return !this.isTeacherChallenge && isNotEmpty(this.difficulties.data)
    },
    isMissionNameAlreadyExists() {
      if (isEmpty(this.magnaMission.error)) {
        return false
      }

      return this.magnaMission.error?.response?.status === httpCodes.badRequest
    },
  },
  watch: {
    classroomId() {
      this.getQuestionsDifficulties()
    },
  },
  async created() {
    await this.getQuestionsDifficulties()
  },
  methods: {
    isEmpty,
    isNotEmpty,
    ...mapActions([
      'getClassrooms',
    ]),
    selectQuestion(event) {
      const question = event.data
      if (question && question.id !== this.$route.query.activeQuestion) {
        this.activeQuestionTableIndex = event.dataIndex
        delete this.$route.query.activeStudent
        this.$router.push({
          name: this.$route.name,
          params: { ...this.$route.params },
          query: {
            ...this.$route.query,
            activeQuestion: question.id,
          },
        })
      }
    },
    clearSelectedQuestion() {
      const query = { ...this.$route.query }
      delete query.activeQuestion
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query,
      })
    },
    previousQuestion() {
      if (this.mq_m) {
        if (this.activeQuestionIndex > 0) {
          this.selectQuestion({
            data: this.questions[this.activeQuestionIndex - 1],
            dataIndex: this.activeQuestionIndex - 1,
          })
        }
      } else {
        this.$refs.questionsTable.previous()
      }
    },
    nextQuestion() {
      if (this.mq_m) {
        if (this.activeQuestionIndex < this.questions.length - 1) {
          this.selectQuestion({
            data: this.questions[this.activeQuestionIndex + 1],
            dataIndex: this.activeQuestionIndex + 1,
          })
        }
      } else {
        this.$refs.questionsTable.next()
      }
    },
    exportQuestions() {
      const questionIds = this.difficulties.data.map((item) => item.id)
      const url = `/questions/download?ids=${questionIds.join(',')}`
      const contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

      try {
        this.downloadFile(
          url,
          this.fileName,
          contentType
        )
      } catch (error) {
        console.error(error)
        this.$toasted.global.error({
          title: this.$t(
            'report.difficulties.export.error'
          ),
        })
      }
    },
    async getQuestionsDifficulties() {
      const { questionnaireCode, classroomId } = this

      this.difficulties.loading = true

      if (!classroomId) {
        console.error('classroomId is null')

        await this.getClassrooms(this.yearSelectedContent)
      }

      try {
        const data = await statisticsApi.getQuestionsStatistics({
          questionnaireCode,
          classroomId,
          classroomDifficulties: true,
          contentYear: this.yearSelectedContent,
        })

        this.difficulties.data = data.map((question, index) => ({
          ...question,
          statement: question.statement.trim(),
          number: index + 1,
        }))
      } catch (error) {
        this.difficulties.error = error
      } finally {
        this.difficulties.loading = false
      }
    },
    closeReinforcementModal() {
      this.isReinforcementModalVisible = false
    },
    showReinforcementModal() {
      this.isReinforcementModalVisible = true
    },
    closeCreateMagnaMissionModal() {
      this.isCreateMagnaMissionModalVisible = false
    },
    showCreateMagnaMissionModal() {
      this.isCreateMagnaMissionModalVisible = true
    },
    async createReinforcementMission(missionTitle) {
      const { questions, classroomId } = this

      const questionsWithOrigin = questions?.map((question) => ({
        ...question,
        origin: originEnum.SAS,
      })) ?? []

      const body = {
        title: missionTitle,
        questionnaireType: 'CHALLENGE_REINFORCEMENT',
        classroomId,
        questions: questionsWithOrigin,
      }

      this.magnaMission.loading = true
      this.magnaMission.error = null

      try {
        const { data } = await arenaApi.createMagnaMission(body)
        this.magnaMission.data = data
        this.closeReinforcementModal()
        this.showCreateMagnaMissionModal()
      } catch (error) {
        this.magnaMission.error = error

        if (!this.isMissionNameAlreadyExists) {
          this.$toasted.global.error({
            title: 'Não foi possível criar missão de reforço, tente novamente mais tarde',
          })
          this.closeReinforcementModal()
        }
      } finally {
        this.magnaMission.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.class-difficulties {
  &__banner {
    margin-bottom: $size-m;
  }

  &__export {
    margin-top: $size-s;
  }
}

.class-difficulties-header {
  margin-bottom: $size-s;
}
</style>
