<template>
  <wrapper class="questions-view">
    <EmptyState
      v-if="hasNoQuestions"
      :image="noQuestions.image"
      :title="noQuestions.title"
    />
    <template v-else>
      <ReportSectionLayout
        v-if="questionsWithProblems.length"
        :title="sections.reportedQuestions"
        :subtitle="questionsWithProblemsCountLabel"
        :loading="questions.loading"
      >
        <QuestionsCards
          v-if="mq_m"
          :loading="questions.loading"
          :questions="questionsWithProblems"
          :active-question="activeQuestion"
          :sort-option="sortOption"
          :is-classroom-activity="isClassroomActivity"
          variation="warning"
          @selectQuestion="selectQuestion"
        />
        <ReportedQuestionsTable
          v-else
          ref="reportedQuestionsTable"
          :loading="questions.loading"
          :questions="questionsWithProblems"
          :active-question="activeQuestion"
          :is-classroom-activity="isClassroomActivity"
          variation="warning"
          @selectQuestion="selectQuestion"
        />
      </ReportSectionLayout>
      <ReportSectionLayout
        :title="sections.allQuestions"
        :subtitle="questionsCountLabel"
        :loading="questions.loading"
      >
        <template #top-bar>
          <FiltersBar
            :search-term="searchTerm"
            :level-filter="levelFilter"
            :sort-option="sortOption"
            :is-filtered-by-order="!isClassroomActivity"
            :questionnaire="questionnaire"
            @onSearch="onSearch"
            @onLevelSelect="onLevelSelect"
            @onSortSelect="onSortSelect"
          />
        </template>
        <template>
          <QuestionsCards
            v-if="mq_m"
            :is-classroom-activity="isClassroomActivity"
            :loading="questions.loading"
            :questions="filteredQuestions"
            :active-question="activeQuestion"
            :sort-option="sortOption"
            @selectQuestion="selectQuestion"
          />
          <QuestionsTable
            v-else
            ref="questionsTable"
            :loading="questions.loading"
            :questions="filteredQuestions"
            :active-question="activeQuestion"
            :is-classroom-activity="isClassroomActivity"
            @selectQuestion="selectQuestion"
          />
        </template>
      </ReportSectionLayout>
    </template>
    <QuestionDrawer
      v-if="activeQuestion"
      :disabled-report="isTeacherChallenge || isChallengeActivity"
      :has-next="hasNextQuestion"
      :has-previous="hasPreviousQuestion"
      :question="activeQuestion"
      :hide-report="unableToReport"
      :is-teacher-challenge="isTeacherChallenge"
      :assignment-code="assignmentCodeInitialOrQueryParam"
      @update-reports="getQuestions"
      @on-previous="onPrevious"
      @on-next="onNext"
      @on-close="clearSelectedQuestion"
    />
  </wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import simplur from 'simplur'

import { isEmpty } from 'App/utils/arrays'
import accentFold from 'App/mixins/accentFold'
import mediaQueries from 'shared/mixins/mediaQueries'
import updateRouteQuery from 'shared/mixins/updateRoute'
import statisticsApi from '@/service/statistics'
import QuestionDrawer from 'App/components/QuestionDrawer/QuestionDrawer'
import EmptyState from 'App/components/EmptyState'
import ReportedQuestionsTable from './partials/ReportedQuestionsTable'
import ReportSectionLayout from './partials/ReportSectionLayout'
import FiltersBar from './partials/FiltersBar/FiltersBar'
import QuestionsCards from './partials/QuestionsCards/QuestionsCards'
import QuestionsTable from './partials/QuestionsTable'

export default {
  name: 'QuestionsView',
  components: {
    FiltersBar,
    QuestionsTable,
    QuestionsCards,
    QuestionDrawer,
    EmptyState,
    ReportSectionLayout,
    ReportedQuestionsTable,
  },
  mixins: [
    accentFold,
    mediaQueries,
    updateRouteQuery,
  ],
  props: {
    selectedClassroom: {
      type: Object,
      default: () => {},
    },
    questionnaire: {
      type: Object,
      default: () => ({}),
    },
    isChallengeActivity: Boolean,
  },
  data() {
    return {
      activeQuestionInnerIndex: -1,
      levelFilter: 'all',
      searchTerm: '',
      selectedSortOption: {},
      reportedQuestionsList: false,
      questions: {
        loading: false,
        error: null,
        data: [],
      },
      sections: {
        allQuestions: 'Todas as questões',
        reportedQuestions: 'Problemas reportados',
      },
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'yearSelectedContent',
    ]),
    assignmentCodeInitialOrQueryParam() {
      if (this.$route.query?.assignmentCode) {
        return this.$route.query?.assignmentCode
      }

      return this?.student?.assignmentCode
    },
    sortOption() {
      const defaultSortOption = { field: 'number', direction: 'asc' }
      const sortByHitRateOption = { field: 'hitRate', direction: 'asc' }
      const selectedSortOptionIsEmpty = isEmpty(Object.keys(this.selectedSortOption))

      if (this.isClassroomActivity && selectedSortOptionIsEmpty) {
        return sortByHitRateOption
      }

      return selectedSortOptionIsEmpty ? defaultSortOption : this.selectedSortOption
    },
    isClassroomActivity() {
      return this.questionnaire.data.isClassroomActivity
    },
    isTeacherChallenge() {
      return this.questionnaire.data.isTeacherChallenge
    },
    questionsCount() {
      return this.questions.data.length
    },
    questionsCountLabel() {
      return simplur`${this.questionsCount} [questão|questões]`
    },
    questionsWithProblemsCountLabel() {
      return simplur`${this.questionsWithProblems.length} [questão|questões]`
    },
    filteredQuestions() {
      if (isEmpty(this.questions.data)) return []

      return this.questions.data
        .filter((question) => this.filterByTerm(question) && this.filterByLevel(question))
    },
    activeQuestion() {
      const { activeQuestion } = this.$route.query
      if (!activeQuestion) return null

      const [ question ] = this.selectedQuestionsList.filter((item) => (
        item.id === activeQuestion
      ))

      return question
    },
    hasPreviousQuestion() {
      return this.activeQuestionInnerIndex > 0
    },
    hasNextQuestion() {
      const { activeQuestionInnerIndex, selectedQuestionsList } = this

      return activeQuestionInnerIndex < selectedQuestionsList.length - 1
    },
    hasNoQuestions() {
      return !this.questions.loading && isEmpty(this.questions.data)
    },
    noQuestions() {
      const subject = this.questionnaire.data?.title

      return {
        image: 'empty-activities',
        title: `Ops! Não existem questões disponíveis de ${subject} para essa atividade.`,
      }
    },
    questionsWithProblems() {
      if (isEmpty(this.questions.data)) return this.questions.data

      return this.questions.data.filter((question) => question.numberOfComplaints > 0)
    },
    selectedQuestionsList() {
      return this.reportedQuestionsList
        ? this.questionsWithProblems
        : this.filteredQuestions
    },
    selectedTableRef() {
      return this.reportedQuestionsList
        ? 'reportedQuestionsTable'
        : 'questionsTable'
    },
    unableToReport() {
      const { isManager, isSchool } = this.user

      return isManager || isSchool
    },
    gradeId() {
      return this.selectedClassroom?.grade.id
    },
    classroomId() {
      return this.$route.params.classroomId
    },
  },
  watch: {
    classroomId() {
      this.getQuestions()
    },
  },
  created() {
    if (isEmpty(this.questions.data)) {
      this.getQuestions()
    }
  },
  methods: {
    ...mapActions([
      'getClassrooms',
    ]),
    onSearch(term) {
      this.searchTerm = term
    },
    onLevelSelect(level) {
      this.levelFilter = level
    },
    onSortSelect(sort) {
      this.selectedSortOption = sort
    },
    selectQuestion({ question, index }) {
      const { id } = question
      const { activeQuestion } = this.$route.query

      this.reportedQuestionsList = Boolean(question.numberOfComplaints > 0)

      if (this.reportedQuestionsList) {
        this.updateRouteQuery('openQuestionProblems', 'true')
      }

      this.activeQuestionInnerIndex = index
      if (id !== activeQuestion) {
        this.updateRouteQuery('activeQuestion', id)
      }
    },
    clearSelectedQuestion() {
      const { name, params } = { ...this.$route }
      const query = { ...this.$route.query }
      delete query.activeQuestion
      delete query.openQuestionProblems
      this.$router.push({ name, params, query })
    },
    onPrevious() {
      if (this.mq_m) {
        const { selectedQuestionsList, activeQuestionInnerIndex } = this

        if (activeQuestionInnerIndex > 0) {
          this.selectQuestion({
            question: selectedQuestionsList[activeQuestionInnerIndex - 1],
            index: activeQuestionInnerIndex - 1,
          })
        }
      } else {
        this.$refs[this.selectedTableRef].previousQuestion()
      }
    },
    onNext() {
      if (this.mq_m) {
        const { selectedQuestionsList, activeQuestionInnerIndex } = this

        if (activeQuestionInnerIndex < selectedQuestionsList.length - 1) {
          this.selectQuestion({
            question: selectedQuestionsList[activeQuestionInnerIndex + 1],
            index: activeQuestionInnerIndex + 1,
          })
        }
      } else {
        this.$refs[this.selectedTableRef].nextQuestion()
      }
    },
    filterByTerm(question) {
      return this.accentFold(question.statement.toLowerCase())
        .includes(this.accentFold(this.searchTerm.toLowerCase()))
    },
    filterByLevel(question) {
      return this.levelFilter === 'all' || question.level === this.levelFilter
    },
    async getQuestions() {
      const { questionnaireCode, classroomId } = this.$route.params

      this.questions.loading = true

      if (!classroomId) {
        console.error('classroomId is null')

        await this.getClassrooms(this.yearSelectedContent)
      }

      try {
        const response = await statisticsApi.getQuestionsStatistics({
          questionnaireCode,
          classroomId,
          contentYear: this.yearSelectedContent,
        })

        let questions = response.map((question) => ({
          ...question,
          statement: question.statement.trim(),
        }))

        if (this.isChallengeActivity) {
          questions = this.questionsWithDecrementedOrder(questions)
        }

        this.questions.data = questions
      } catch (error) {
        this.questions.error = error
      } finally {
        this.questions.loading = false
      }
    },
    questionsWithDecrementedOrder(questions) {
      return questions.map((question) => ({ ...question, order: question.order - 1 }))
    },
  },
}
</script>

<style lang="scss" scoped>
.questions-view {
  &__filters-bar {
    margin-bottom: $size-s;
  }
}
</style>
