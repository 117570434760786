<template>
  <div class="question-drawer-hit-rate">
    <div class="question-drawer-hit-rate__donut">
      <RadialProgressSkeleton
        :size="`${donutSize}px`"
      />
    </div>
    <div class="question-drawer-hit-rate__text">
      <div class="question-drawer-hit-rate__text__title">
        <skeleton-loader
          width="200px"
          height="15px"
        />
      </div>
      <div class="question-drawer-hit-rate__text__count">
        <skeleton-loader
          width="190px"
          height="27px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RadialProgressSkeleton from 'App/components/RadialProgressSkeleton'
import mediaQueries from 'shared/mixins/mediaQueries'

export default {
  name: 'QuestionDrawerHitRate',
  components: {
    RadialProgressSkeleton,
  },
  mixins: [ mediaQueries ],
  computed: {
    donutSize() {
      return this.mq_xs ? 88 : 110
    },
  },
}
</script>

<style lang="sass" scoped>
.question-drawer-hit-rate
  border-bottom: 1px solid $color-ink-lightest
  padding: $size-m $size-l

  +mq-s--mf
    +flex-center-start

  &__donut
    margin-bottom: $size-s

    +mq-s--mf
      margin-right: $size-s
      margin-bottom: 0

  &__text
    &__title
      margin-bottom: $size-xs
</style>
