<template>
  <div>
    <div class="logos-mission-item">
      <template>
        <div class="logos-mission-item__column-group">
          <img
            class="logos-mission-item__icon"
            :src="percentage"
            :style="percentageStyle"
            alt=""
          >
          <div class="logos-mission-item__description">
            <h1
              v-tooltip="logosMission.title"
              class="logos-mission-item__title"
            >
              {{ logosMission.title }}
            </h1>
            <span class="logos-mission-item__chapter">
              Capítulo {{ chapterNumber }}
            </span>
          </div>
        </div>

        <div class="logos-mission-item__group">
          <p class="logos-mission-item__group-title">
            Finalizadas
          </p>
          <p class="logos-mission-item__group-text">
            {{ logosMission.students.finished }} /
            {{ logosMission.students.total }}
          </p>
        </div>

        <slot />
      </template>
    </div>
  </div>
</template>

<script>
import percentageIcon from 'MFE/assets/icons/percentage.svg'

export default {
  name: 'LogosMissionItem',
  props: {
    logosMission: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    chapterNumber: {
      type: Number,
      required: true,
    },
  },
  computed: {
    percentage() {
      return percentageIcon
    },
    percentageStyle() {
      const { finished, total } = this.logosMission?.students

      return {
        backgroundColor: finished === total
          ? '#D8FDE8'
          : '#DDEBFD',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.logos-mission-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  border: 1px solid #E6E9ED;
  padding: $size-s !important;
  margin-bottom: 12px;
  border-radius: 8px;

  @include mq_s {
    flex-direction: column;
  }

  &__chapter {
    display: block;
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: $size-s;
    max-width: 450px;

    @include mq-m--mf {
      max-width: 300px;
    }

    @include mq_m {
      max-width: 205px;
    }
  }

  &__icon {
    border-radius: 8px;
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 44px;
    min-width: 44px;
  }

  @include mq-m--mf {
    flex-wrap: nowrap;
    padding: $size-s $size-m;
  }

  &__group {
    display: flex;
    flex-direction: column;

    @include mq-m--mf {
      margin-bottom: 0;
    }

    @include mq_s {
      flex-direction: row;
      width: 100%;
      margin: $size-xs $size-s 0 0;
      align-items: center;
    }
  }

  &__group-title, &__chapter {
    font-size: 14px;
    color: #707780;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__group-title {
    @include mq_s {
      margin: $size-xs;
    }
  }

  &__column-group {
    display: flex;
    width: 100%;

    @include mq-m--mf {
      width: 55%;
    }
  }

  &__title {
    font-size: $font-size-heading-6;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  ::v-deep &__icon {
    min-width: 24px;
    color: $color-ink-light;
    margin-right: $size-xs;
  }

  &__text {
    white-space: nowrap;
    display: inline-block;
    color: $color-ink-light;
    font-size: $font-size-s;
    font-weight: $font-weight-semi-bold;
  }

  .sas-button.--secondary, .sas-button.--primary {
    font-family: "Inter", sans-serif;
    box-shadow: none;
    border-radius: 8px;
  }
}
</style>
