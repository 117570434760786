export default function translateShift(shift) {
  const shifts = {
    morning: 'Manhã',
    fulltime: 'Integral',
    afternoon: 'Tarde',
    evening: 'Noite',
  }

  return shifts[shift]
}
