<template>
  <div class="adaptive-selector-list">
    <div class="adaptive-selector-list__wrapper">
      <div
        v-for="(option, index) in options"
        :key="index"
        tabindex="0"
        :class="[
          'adaptive-selector-list__item',
          {
            '--active': selectedOption && isEqual(option, selectedOption),
            '--disabled': option && option.disabled,
          }
        ]"
        @click="selectOption(option)"
        @keyup.enter="selectOption(classroom)"
      >
        <slot
          name="option"
          :option="option"
        >
          {{ option }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'

export default {
  name: 'AdaptiveSelectorList',
  model: {
    prop: 'selectedOption',
    event: 'select',
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: [ Object, String ],
      default: null,
    },
  },
  methods: {
    isEqual(value, other) {
      return isEqual(value, other)
    },
    selectOption(option) {
      this.$emit('select', option)
    },
  },
}
</script>

<style lang="scss" scoped>
.adaptive-selector-list {
  &__wrapper {
    overflow: auto;
    @include flex-center-start;
  }

  &__item {
    border-radius: 2rem;
    cursor: pointer;
    color: #707780;
    background: $color-white;
    border: 1px solid #C9CED4;
    font-weight: $font-weight-semi-bold;
    flex-shrink: 0;
    font-size: $font-size-s;
    padding: 6px 16px;
    @include flex-center;
    @include space-inline($size-xs);
    @include transition($speed-x-fast);

    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 3px $color-primary-light;
    }

    &.--active {
      background: #C7D8FF;
      color: #0D4AD6;
      border: 1px solid #8CAFFF;
    }

    &.--disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:focus {
        box-shadow: none;
      }
    }
  }
}
</style>
