<template>
  <div class="questions-view-table">
    <s-table
      ref="sTable"
      :fields="fields"
      :content="questions"
      :selected-row="activeQuestion"
      :default-sort="defaultSort"
      :loading="loading"
      :skeleton-lines="5"
      @click-row="clickRow"
    >
      <template #number="{ row }">
        <skeleton-loader
          v-if="loading"
          width="24px"
          height="24px"
        />
        <div
          v-else
          class="questions-view-table__number"
        >
          <span>
            {{ row.order }}
          </span>
        </div>
      </template>
      <template #statement="{ row }">
        <div
          v-if="!loading"
          class="questions-view-table__question"
        >
          <span
            v-if="mustShowSubject"
            class="questions-view-table__question__content"
          >
            {{ row.content.name }}
          </span>
          <h6>
            {{ truncateText(row.statement, statementMaxLength) }}
          </h6>
        </div>
        <template v-else>
          <skeleton-loader
            class="questions-view-table__question__content"
            width="20%"
            height="19px"
          />
          <skeleton-loader
            width="270px"
            height="25px"
          />
        </template>
      </template>
      <template #level="{ row }">
        <div
          v-if="!loading"
          class="questions-view-table__level"
        >
          {{ $t(`levels.${row.level}`) }}
        </div>
        <skeleton-loader
          v-else
          width="60px"
          height="19px"
        />
      </template>
      <template #hitRate="{ row }">
        <template v-if="!loading">
          <p class="questions-view-table__hit-rate">
            <b>
              <b>{{ row.hitRate | handleHitRate }}</b>
            </b>
          </p>
          <span
            v-if="row.hitRate !== null"
            class="questions-view-table__hit-count"
          >
            {{ hitCountLabel(row.hitCount, row.count) }}
          </span>
        </template>
        <template v-else>
          <div class="questions-view-table__hit-rate">
            <skeleton-loader
              width="34px"
              height="22px"
            />
          </div>
          <div class="questions-view-table__hit-count">
            <skeleton-loader
              width="100%"
              height="19px"
            />
          </div>
        </template>
      </template>
      <template #details>
        <template v-if="!loading">
          <s-button variation="secondary">
            {{ $t('commons.details') }}
          </s-button>
        </template>
        <template v-else>
          <skeleton-loader
            width="90px"
            height="36px"
          />
        </template>
      </template>
    </s-table>
  </div>
</template>

<script>
import truncateText from 'shared/utils/truncateText'
import STable from 'App/components/STable'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'
import { isNaN, isNil } from 'lodash'

export default {
  name: 'QuestionsTable',
  components: { STable },
  filters: {
    handleHitRate: (hitRate) => {
      if (isNaN(hitRate) || isNil(hitRate)) return '-'

      return `${hitRate}%`
    },
  },
  mixins: [ subjectVisibilityToggle ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    activeQuestion: {
      type: Object,
      default: null,
      required: false,
    },
    isClassroomActivity: Boolean,
  },
  computed: {
    statementMaxLength() {
      switch (this.$mq) {
        case 'large':
          return 80
        case 'x_large':
          return 100
        default:
          return Number.MAX_SAFE_INTEGER
      }
    },
    defaultSort() {
      const defaultSort = {}

      if (!this.isClassroomActivity) {
        defaultSort.field = 'number'
        defaultSort.direction = 'asc'
      }

      return defaultSort
    },
    fields() {
      const fields = [
        {
          value: 'statement',
          text: this.$t(
            'report.questionsView.question'
          ),
          sortable: true,
          sortFunction: (a, b, modifier = 1) => a.localeCompare(b) * modifier,
        },
        {
          value: 'level',
          text: this.$t('report.questionsView.level'),
          sortable: true,
          sortFunction: (a, b, modifier = 1) => {
            if (a === b) return 0
            if (a === 'easy') return -1 * modifier
            if (a === 'hard') return modifier
            if (a === 'medium' && b === 'easy') return modifier

            return -1 * modifier
          },
        },
        {
          value: 'hitRate',
          text: this.$t('report.questionsView.hitRate'),
          sortable: true,
          width: '144px',
        },
        {
          value: 'details',
          text: '',
          width: '1px',
        },
      ]

      if (!this.isClassroomActivity) {
        fields.unshift({ value: 'number', text: 'Nº', sortable: true })
      }

      return fields
    },
  },
  methods: {
    truncateText,
    hitCountLabel(count, total) {
      if (this.$mq === 'large') {
        return this.$tc(
          'report.difficulties.hitsShort',
          count,
          {
            count,
            total,
          }
        )
      }

      return this.$tc('report.difficulties.hits',
        count,
        {
          count,
          total,
        })
    },
    clickRow({ data, dataIndex }) {
      this.$emit('selectQuestion', {
        question: data,
        index: dataIndex,
      })
    },
    previousQuestion() {
      this.$refs.sTable.previous()
    },
    nextQuestion() {
      this.$refs.sTable.next()
    },
  },
}
</script>

<style lang="scss" scoped>
.questions-view-table {
  ::v-deep .sas-table__body tr {
    cursor: pointer;
  }

  &__number {
    width: $size-m;
    height: $size-m;
    color: $color-ink-light;
    background: rgba(201, 204, 207, 0.3);
    border-radius: $size-xs;
    @include flex-center;
  }

  &__question {
    &__content {
      color: $color-ink-light;
      margin-bottom: $size-xxs;
    }
  }

  &__level {
    color: $color-ink-light;
    font-size: $font-size-s;
  }

  &__hit-rate {
    margin-bottom: $size-xxs;
  }

  &__hit-count {
    color: $color-ink-light;
  }
}
</style>
