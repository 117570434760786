<template>
  <div
    ref="tabsParent"
    :class="['tabs', `--${theme}`]"
  >
    <SButton
      v-for="(tab, index) in tabs"
      :key="index"
      class="tabs__item"
      variation="tertiary"
      :to="tab.route"
      @click.native="scrollToActive($event.target)"
    >
      {{ tab.name }}
    </SButton>
  </div>
</template>

<script>
import { SButton } from '@sas-te/alfabeto-vue'

export default {
  name: 'Tabs',
  components: {
    SButton,
  },
  props: {
    theme: {
      type: String,
      default: 'light',
      validator: (value) => value.match(/(light|dark)/),
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    const activeTab = document.querySelector('.tabs__item.--active')

    if (activeTab) {
      this.scrollToActive(activeTab)
    }
  },
  methods: {
    scrollToActive(element) {
      const tab = element.classList.contains('tabs__item')
        ? element
        : element.parentNode
      const { tabsParent } = this.$refs
      tabsParent.scrollBy({
        left: tab.clientWidth + tab.offsetLeft - tabsParent.clientWidth,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs {
  overflow: auto;
  @include flex-center-start;

  &__item {
    @include space-inline($size-s);
    border-radius: 0;
    flex-shrink: 0;

    &::after {
      position: absolute;
      bottom: 0;
      content: '';
      display: block;
      height: 5px;
      width: 0%;
      border-radius: 10px 10px 0 0;
      background: $color-primary-dark;
      @include transition(0.3s);
    }

    &.--active,
    &.router-link-active {
      &::after {
        width: 100%;
      }

      ::v-deep .sas-button__text {
        color: $color-ink;
      }
    }

    ::v-deep .sas-button__text {
      color: $color-ink-light;
    }
  }

  &.--dark {
    .tabs__item {
      &::after {
        background-color: #0D4AD6;
      }

      &.--active,
      &.router-link-active {
        ::v-deep .sas-button__text {
          opacity: 1;
          font-size: $font-size-s;
          line-height: 20px;
        }
      }

      ::v-deep .sas-button__text {
        color: #191C1F;
        opacity: 0.7;
      }
    }
  }
}
</style>
