<template>
  <wrapper>
    <div v-if="currentMatchHistory.loading">
      <AssignmentSectionLayout
        title="Arena Ancestral"
        subtitle="Confira todas as partidas das missões magnas jogadas com sua turma"
        class="ancestral-arena-report__title"
      >
        <template #header>
          <skeleton-loader
            class="assignment-section-skeleton__subtitle"
            width="150px"
            height="50px"
          />
        </template>
        <TabSelector
          class="ancestral-arena-report__tabs"
          :tabs="tabs"
          @change-tab="changeTab"
        />
        <AssignmentsListSkeleton
          title
          buttons="2"
        />
      </AssignmentSectionLayout>
    </div>
    <div v-else-if="!currentMatchHistory.loading">
      <AssignmentSectionLayout
        title="Arena Ancestral"
        subtitle="Confira todas as partidas das missões magnas jogadas com sua turma"
        class="ancestral-arena-report__header"
      >
        <template #header>
          <OrderSelector
            class="ancestral-arena-report__selector"
            @change-option="setSort"
          />
        </template>
        <TabSelector
          class="ancestral-arena-report__tabs"
          :tabs="tabs"
          @change-tab="changeTab"
        />
        <div v-if="hasMatchHistory">
          <AncestralArenaList
            v-if="hasOngoingMatchHistory"
            class="ancestral-arena-report__list"
            title="Partidas ativas"
            :list="mappedOngoingMatchHistory"
          />
          <AncestralArenaList
            v-if="hasFinishedMatchHistory"
            title="Partidas finalizadas"
            :list="sortedFinishedMatchHistory"
          />
        </div>
        <EmptyState
          v-else
          class="ancestral-arena-report__empty-state"
          title="Nenhuma missão iniciada"
          description="Você ainda não iniciou nenhuma missão no Arena Ancestral para esta turma."
          image="teacher-dash"
        />
      </AssignmentSectionLayout>
    </div>
  </wrapper>
</template>

<script>
import { isEmpty, isNil } from 'lodash'
import { compareAsc, compareDesc } from 'date-fns'
import { mapState, mapActions } from 'vuex'

import arenaApi from '@/service/arena'
import OrderSelector from 'MFE/components/OrderSelector'
import AssignmentSectionLayout from 'App/components/Assignments/AssignmentSectionLayout'
import EmptyState from 'App/components/EmptyState'
import TabSelector from 'App/components/TabSelector'
import updateRoute from 'shared/mixins/updateRoute'
import AssignmentsListSkeleton from 'App/components/Assignments/AssignmentsListSkeleton'
import AncestralArenaList from './components/AncestralArenaList'
import applicationTypeEnum from './enums/applicationTypeEnum'
import missionStatusEnum from './enums/missionStatusEnum'

export default {
  name: 'AncestralArenaReport',
  components: {
    AssignmentSectionLayout,
    AncestralArenaList,
    EmptyState,
    OrderSelector,
    TabSelector,
    AssignmentsListSkeleton,
  },
  mixins: [ updateRoute ],
  data() {
    return {
      matchHistory: {
        [applicationTypeEnum.LIVE]: {
          data: [],
          loading: false,
          error: null,
        },
        [applicationTypeEnum.HOME]: {
          data: [],
          loading: false,
          error: null,
        },
      },
      sort: null,
      applicationType: this.$route.query?.tab ?? applicationTypeEnum.LIVE,
    }
  },
  computed: {
    ...mapState({
      eurekaHomeMissionsEnabled: ({ featureToggles }) => featureToggles
        .toggles.eurekaHomeMissions,
    }),
    tabs() {
      return [
        {
          name: applicationTypeEnum.LIVE,
          text: 'Partidas ao vivo',
          isShow: true,
        },
        {
          name: applicationTypeEnum.HOME,
          text: 'Partidas para casa',
          isShow: this.eurekaHomeMissionsEnabled,
        },
      ].filter(({ isShow }) => isShow)
    },
    selectedClassroom() {
      return this.$route.query?.classroomId ?? null
    },
    hasMatchHistory() {
      return !isEmpty(this.currentMatchHistory.data)
    },
    currentMatchHistory() {
      return this.matchHistory[this.applicationType]
    },
    mappedOngoingMatchHistory() {
      if (!this.hasMatchHistory) return []

      return this.currentMatchHistory.data
        .filter(({ finishedStatus }) => finishedStatus === missionStatusEnum.ONGOING)
    },
    mappedFinishedMatchHistory() {
      if (!this.hasMatchHistory) return []

      return this.currentMatchHistory.data
        .filter(({ finishedStatus }) => finishedStatus !== missionStatusEnum.ONGOING)
    },
    hasOngoingMatchHistory() {
      return !isEmpty(this.mappedOngoingMatchHistory)
    },
    hasFinishedMatchHistory() {
      return !isEmpty(this.mappedFinishedMatchHistory)
    },
    sortedFinishedMatchHistory() {
      const compareFunction = this.sort === 'asc' ? compareAsc : compareDesc
      const copyMatchHistory = [ ...this.mappedFinishedMatchHistory ]

      const newList = copyMatchHistory
        .sort((matchHistoryLeft, matchHistoryRight) => compareFunction(
          new Date(matchHistoryLeft.finishedAt),
          new Date(matchHistoryRight.finishedAt)
        ))

      return newList
    },
  },
  watch: {
    selectedClassroom: {
      immediate: true,
      handler() {
        this.getAllMatchHistories()
      },
    },
    applicationType: {
      handler(applicationType) {
        if (Object.values(applicationTypeEnum).includes(applicationType)) {
          this.fetchMatchHistory(applicationType)
        }
      },
    },
  },
  destroyed() {
    this.updateRouteQueryWithReplace('tab', null)
  },
  methods: {
    ...mapActions([
      'getClassrooms',
    ]),
    async getAllMatchHistories() {
      const promises = Object.values(applicationTypeEnum)
        .map((applicationType) => this.fetchMatchHistory(applicationType))

      await Promise.allSettled(promises)
    },
    setSort({ order }) {
      this.sort = order
    },
    changeTab(tab) {
      if (isNil(tab)) return
      this.applicationType = tab?.name
    },
    async fetchMatchHistory(applicationType) {
      const classroomId = this.selectedClassroom

      if (isNil(applicationType)) {
        console.error('applicationType is null')

        return
      }

      if (isNil(classroomId)) {
        console.error('classroomId is null')

        await this.getClassrooms(this.yearSelectedContent)
      }

      const currentMatchHistory = this.matchHistory?.[applicationType]
      if (isNil(currentMatchHistory)) {
        console.error('Invalid applicationType')

        return
      }

      currentMatchHistory.loading = true
      try {
        const { data } = await arenaApi
          .getMatchHistoryByClassroom({ classroomId, applicationType })

        currentMatchHistory.data = data
      } catch (error) {
        currentMatchHistory.error = error
      } finally {
        currentMatchHistory.loading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ancestral-arena-report {
  &__header {
    ::v-deep .assignment-section-layout__container {
      min-height: 110px;
    }
  }

  &__list {
    margin-bottom: 40px;
  }

  &__selector {
    margin-bottom: $size-l;
  }

  &__empty-state {
    padding: 10% 0;
  }

  &__tabs {
    margin-bottom: $size-m;
  }
}

::v-deep .assignment-section-layout {
  header {
    @include flex-space-between;
  }
}
</style>
