<template>
  <div>
    <wrapper class="recomposition-knowledge-test-class-detail-table-container">
      <h3 class="recomposition-knowledge-test-class-detail-table-container__title">
        Detalhamentos da turma
      </h3>
      <span class="recomposition-knowledge-test-class-detail-table-container__subtitle">
        Dados de aprofundamento de relatório considerando apenas alunos que responderam
        cada uma das questões.
      </span>
      <TabSelector
        v-if="showTable"
        class="recomposition-knowledge-test-class-detail-table-container__tabs"
        :tabs="tabs"
        :is-active-param="false"
        @change-tab="changeTab"
      />

      <div v-if="loading">
        <skeleton-loader
          width="100%"
          height="500px"
        />
      </div>

      <RecompositionKnowledgeTestClassDetailsTable
        v-show="isRecompositionDiagnosticTab && showTable"
        :questions="questionsDiagnostic"
        @select-question="selectQuestion"
      />

      <RecompositionKnowledgeTestClassDetailsTable
        v-show="isRecompositionRecoveryTab && showTable"
        :questions="questionsRecovery"
        @select-question="selectQuestion"
      >
        <template #selector>
          <s-select
            v-model="recompositionOption"
            class="recomposition-knowledge-test-class-detail-table-container__selector"
            size="large"
            variation="secondary"
            track-by="label"
            :options="filteredOptions"
          />

          <h5
            class="recomposition-knowledge-test-class-detail-table-container__learning-title"
          >
            Aprendizagem essencial avaliada: {{ essentialLearningTitle }}
          </h5>
        </template>
      </RecompositionKnowledgeTestClassDetailsTable>
    </wrapper>
    <QuestionDrawer
      v-if="activeQuestion"
      hide-navigation
      :question="activeQuestion"
      :has-previous="hasPreviousQuestion"
      :has-next="hasNextQuestion"
      @on-close="clearSelectedQuestion"
      @on-previous="previousQuestion"
      @on-next="nextQuestion"
    />
    <EmptyState
      v-if="!showTable"
      :title="emptyState.title"
      :description="emptyState.description"
      :image="emptyState.image"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import updateRoute from 'shared/mixins/updateRoute'
import { isNil } from 'lodash'
import TabSelector from 'App/components/TabSelector'
import logosApi from '@/service/logos'
import QuestionDrawer from 'App/components/QuestionDrawer/QuestionDrawer'
import EmptyState from 'App/components/EmptyState'

import mediaQueries from 'shared/mixins/mediaQueries'
import RecompositionKnowledgeTestClassDetailsTable from '../RecompositionKnowledgeTestClassDetailsTable/RecompositionKnowledgeTestClassDetailsTable'

const questionsTypeEnum = {
  RECOMPOSITION_DIAGNOSTIC: 'RECOMPOSITION_DIAGNOSTIC',
  RECOMPOSITION_RECOVERY: 'RECOMPOSITION_RECOVERY',
}

export default {
  name: 'RecompositionKnowledgeTestClassDetailsTableContainer',
  components: {
    EmptyState,
    TabSelector,
    QuestionDrawer,
    RecompositionKnowledgeTestClassDetailsTable,
  },
  mixins: [ mediaQueries, updateRoute ],
  props: {
    showTable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      progressionPathInfo: {
        loading: false,
        error: null,
        data: [],
      },
      emptyState: {
        image: 'empty-student-report',
        title: 'Os alunos ainda não finalizaram a atividade',
        description: 'Incentive seus alunos a finalizarem a atividade para ver as questões respondidas.',
      },
      questions: {
        [questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC]: {
          loading: false,
          error: null,
          data: [],
        },
        [questionsTypeEnum.RECOMPOSITION_RECOVERY]: {
          loading: false,
          error: null,
          data: [],
        },
      },
      recoveryOptions: {
        [questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC]: {
          loading: false,
          error: null,
          data: [],
        },
        [questionsTypeEnum.RECOMPOSITION_RECOVERY]: {
          loading: false,
          error: null,
          data: [],
        },
      },
      questionsType: questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC,
      selectedClassroom: null,
      loadingClassPicker: true,
      recompositionOption: null,
      activeQuestionInnerIndex: -1,
    }
  },
  computed: {
    ...mapGetters([
      'classrooms',
      'yearSelectedContent',
    ]),
    activeQuestion() {
      const { activeQuestion } = this.$route.query
      if (!activeQuestion) return null

      const [ question ] = this.activeQuestionsByType.data.filter((item) => (
        item.id === activeQuestion
      ))

      return question
    },
    activeQuestionIndex() {
      return this.activeQuestionsByType.data.findIndex((question) => (
        question.id === this.activeQuestion.id
      ))
    },
    hasPreviousQuestion() {
      return this.activeQuestionInnerIndex > 0
    },
    hasNextQuestion() {
      return this.activeQuestionInnerIndex < this.activeQuestionsByType.data.length - 1
    },
    isRecompositionDiagnosticTab() {
      return this.questionsType === questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC
    },
    isRecompositionRecoveryTab() {
      return this.questionsType === questionsTypeEnum.RECOMPOSITION_RECOVERY
    },
    loading() {
      return !this.classrooms.ready
        || this.activeQuestionsByType.loading || this.progressionPathInfo.loading
    },
    loadingTable() {
      return this.questions.RECOMPOSITION_DIAGNOSTIC.loading
       || this.recoveryOptions.RECOMPOSITION_DIAGNOSTIC.loading
    },
    tabs() {
      return [
        {
          name: questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC,
          text: 'Questões da Missão Lógos',
          isShow: true,
        },
        {
          name: questionsTypeEnum.RECOMPOSITION_RECOVERY,
          text: 'Questões do Resgate de conhecimento',
          isShow: true,
        },
      ].filter(({ isShow }) => isShow)
    },
    recompositionChapterId() {
      return Number(this.$route.params.recompositionChapterId) ?? null
    },
    error() {
      return this.classrooms.error
        || this.activeQuestionsByType.error || this.progressionPathInfo.error
    },
    filteredOptions() {
      return this.recoveryOptions[questionsTypeEnum.RECOMPOSITION_RECOVERY]
        .data.map(({ code, title, essentialLearningTitle }) => ({
          value: code,
          label: title,
          essentialLearningTitle,
        }))
    },
    classroomPathParam() {
      return parseInt(this.$route.params.classroomId, 10)
    },
    questionnaireCode() {
      return this.recompositionOption?.value ?? null
    },
    essentialLearningTitle() {
      return this.recompositionOption?.essentialLearningTitle ?? null
    },
    activeQuestionsByType() {
      return this.questions?.[this.questionsType]
      ?? this.questions[questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC]
    },
    questionsDiagnostic() {
      return this.questions?.[questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC].data ?? []
    },
    questionsRecovery() {
      return this.questions?.[questionsTypeEnum.RECOMPOSITION_RECOVERY].data ?? []
    },
    recoveryOptionsDiagnostic() {
      return this.recoveryOptions?.[questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC].data ?? []
    },
    recoveryOptionsRecovery() {
      return this.recoveryOptions?.[questionsTypeEnum.RECOMPOSITION_RECOVERY].data ?? []
    },
  },
  watch: {
    selectedClassroom(newSelectedClassroom, oldSelectedClassroom) {
      this.updateRouteParam('classroomId', this.selectedClassroom?.id)
      const isNotInitialState = oldSelectedClassroom !== null

      if (newSelectedClassroom !== oldSelectedClassroom && isNotInitialState) {
        this.refresh()
      }
    },
    filteredOptions(newFilteredOptions) {
      this.recompositionOption = newFilteredOptions?.[0] ?? null
    },
    recompositionOption(newRecompositionOption, oldRecompositionOption) {
      if (!isNil(oldRecompositionOption) && newRecompositionOption !== oldRecompositionOption) {
        this.fetchRefreshRecompositionRecovery()
      }
    },
  },
  async mounted() {
    this.startFetches()
  },
  methods: {
    ...mapActions([ 'getClassrooms' ]),
    refresh() {
      this.startFetches()
    },
    async fetchClassrooms() {
      try {
        if (!this.classrooms.ready) {
          await this.getClassrooms(this.yearSelectedContent)
        }

        if (this.classroomPathParam) {
          this.selectedClassroom = this.classrooms.data.find(
            ({ id }) => id === this.classroomPathParam
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingClassPicker = false
      }
    },
    async fetchRecoveryOptions(type) {
      if (!type) {
        console.error('Missing params fetchRecoveryOptions:', {
          type,
        })

        return
      }

      const recoveryOptions = this.recoveryOptions[type]
      recoveryOptions.loading = true
      const classroomId = this.selectedClassroom?.id
      try {
        const { data } = await logosApi.getRecoveryOptions({
          types: type,
          recompositionChapterId: this.recompositionChapterId,
          classroomId,
        })
        recoveryOptions.data = data
        recoveryOptions.error = false
      } catch (error) {
        recoveryOptions.error = true
      } finally {
        recoveryOptions.loading = false
      }
    },
    async fetchKnowledgeTestQuestions({ code, type }) {
      const questions = this.questions[type]

      try {
        const classId = this.selectedClassroom?.id
        const contentYear = this.yearSelectedContent

        if (!classId || !contentYear || !code || !type) {
          console.error('Missing params fetchKnowledgeTestQuestions: ', {
            classId, contentYear, code, type,
          })

          return []
        }

        const { data } = await logosApi.getKnowledgeTestQuestions({
          classroomId: classId,
          contentYear,
          questionnaireCode: code,
        })
        questions.data = data
        questions.error = false

        return data
      } catch (error) {
        questions.error = true

        return []
      } finally {
        questions.loading = false
      }
    },
    async fetchRecompositionDiagnostic() {
      const type = questionsTypeEnum.RECOMPOSITION_DIAGNOSTIC
      await this.fetchRecoveryOptions(type)

      const code = this.recoveryOptionsDiagnostic?.[0]?.code ?? null

      if (!code) {
        console.error('Missing params fetchRecompositionDiagnostic: ', {
          code,
        })

        return
      }

      await this.fetchKnowledgeTestQuestions({
        code,
        type,
      })
      this.setQuestionnaireCode(code)
    },
    setQuestionnaireCode(code) {
      this.$emit('set-questionnaire-code', code)
    },
    trackEvent(question, type) {
      const info = {
        id: question.id,
        hitRate: question.hitRate,
        type,
        essentialLearningTitle: this.essentialLearningTitle,
      }
      this.$emit('track-event', info)
    },
    async fetchRecompositionRecovery() {
      const type = questionsTypeEnum.RECOMPOSITION_RECOVERY
      await this.fetchRecoveryOptions(type)

      const code = this.recompositionOption?.value ?? this.recoveryOptionsRecovery?.[0]?.code
      await this.fetchKnowledgeTestQuestions({
        code,
        type,
      })
    },
    async fetchRefreshRecompositionRecovery() {
      const type = questionsTypeEnum.RECOMPOSITION_RECOVERY

      const code = this.recompositionOption?.value ?? this.recoveryOptionsRecovery?.[0]?.code
      await this.fetchKnowledgeTestQuestions({
        code,
        type,
      })
    },
    async startFetches() {
      await this.fetchClassrooms()
      this.fetchRecompositionDiagnostic()
      this.fetchRecompositionRecovery()
    },
    changeTab(tab) {
      if (isNil(tab)) return
      this.questionsType = tab?.name
    },
    selectQuestion(event) {
      const { question } = event
      const hasActiveQuestion = question && question.id !== this.$route.query.activeQuestion
      this.trackEvent(question, this.questionsType)
      if (hasActiveQuestion) {
        this.activeQuestionInnerIndex = event.index
        delete this.$route.query.activeStudent
        this.$router.push({
          name: this.$route.name,
          params: { ...this.$route.params },
          query: {
            ...this.$route.query,
            activeQuestion: question.id,
          },
        })
      }
    },
    clearSelectedQuestion() {
      const query = { ...this.$route.query }
      delete query.activeQuestion
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query,
      })
    },
    previousQuestion() {
      if (this.mq_m) {
        if (this.activeQuestionIndex > 0) {
          this.selectQuestion({
            data: this.activeQuestionsByType.data[this.activeQuestionIndex - 1],
            dataIndex: this.activeQuestionIndex - 1,
          })
        }
      } else {
        this.$refs.questionsTable.previous()
      }
    },
    nextQuestion() {
      if (this.mq_m) {
        if (this.activeQuestionIndex < this.activeQuestionsByType.data.length - 1) {
          this.selectQuestion({
            data: this.activeQuestionsByType.data[this.activeQuestionIndex + 1],
            dataIndex: this.activeQuestionIndex + 1,
          })
        }
      } else {
        this.$refs.questionsTable.next()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.recomposition-knowledge-test-class-detail-table-container {
  padding: $size-l 0;

  &__title {
    color: $color-ink;
    margin-bottom: $size-xs;
  }

  &__subtitle {
    display: block;
    color: $color-ink-light;
  }

  ::v-deep .tabs__item::after {
    width: 0;
  }

  &__selector {
    min-width: 300px;
    max-width: 500px;
  }

  ::v-deep .--active {
    border-radius: 10px;
    background: rgba($color-primary-lightest, 0.5);
  }

  ::v-deep .tabs .--active .sas-button__text {
    color: $color-primary-dark;
  }

  &__tabs {
    margin-top: $size-m;
  }

  &__learning-title {
    font-family: 'Metropolis';
    font-size: $font-size-m;
    font-weight: $font-weight-regular;
    line-height: $line-height-input;
    color: #707780;
    margin: $size-s 0;
  }
}
</style>
