<template>
  <i>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        stroke="#707780"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M5.834 3.333v13.334m0-13.334c.659 0 2.5 2.5 2.5 2.5m-2.5-2.5c-.659 0-2.5
      2.5-2.5 2.5m10.833 10v-12.5m2.5 10.834s-1.84 2.5-2.5 2.5c-.659 0-2.5-2.5-2.5-2.5"
      />
    </svg>
  </i>
</template>

<script>

export default {
  name: 'SortIcon',
  props: {
    size: {
      type: [ String ],
      default: '24',
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
