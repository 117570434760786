<template>
  <div class="student-drawer-hit-rate">
    <box
      padding="0"
      :border-radius="mq_s ? '0' : $tokens.border_radius_m"
    >
      <div class="student-drawer-hit-rate__card">
        <div
          :class="['student-drawer-hit-rate__card__donut', {'--no-margin': !loading && !hitRate}]"
        >
          <RadialProgressSkeleton
            v-if="loading"
            :size="`${donutSize}px`"
            border-radius="50%"
            class="radial-progress-loader"
          />
          <RadialProgressBar
            v-else-if="hitRate"
            inner-stroke-color="#EFF0F0"
            start-color="#438DE4"
            stop-color="#438DE4"
            :stroke-width="10"
            :diameter="donutSize"
            :total-steps="100"
            :completed-steps="hitRate"
          >
            <h4><b>{{ hitRate }}%</b></h4>
          </RadialProgressBar>
        </div>
        <div class="student-drawer-hit-rate__card__text">
          <div class="student-drawer-hit-rate__card__title">
            <skeleton-loader
              v-if="loading"
              width="160px"
              height="26px"
            />
            <h4 v-else>
              {{ $t(
                'report.studentDrawer.assignmentHits'
              ) }}
            </h4>
          </div>
          <div class="student-drawer-hit-rate__card__count">
            <skeleton-loader
              v-if="loading"
              width="120px"
              height="18px"
            />
            <p v-else>
              {{ $tc(
                'report.studentDrawer.hitsCount',
                total,
                {
                  count: correct,
                  total
                }
              ) }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="redoCount"
        class="student-drawer-hit-rate__redo-count"
      >
        <icon type="repeat" />
        <p>
          {{ $t('report.studentDrawer.redoCount.start') }}
          <b>{{ redoCount }}</b>
          {{ $tc(
            'report.studentDrawer.redoCount.end',
            redoCount,
          ) }}
        </p>
      </div>
    </box>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
import mediaQueries from 'shared/mixins/mediaQueries'
import RadialProgressSkeleton from 'App/components/RadialProgressSkeleton'

export default {
  name: 'StudentDrawerHitRate',
  components: {
    RadialProgressBar,
    RadialProgressSkeleton,
  },
  mixins: [ mediaQueries ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hitRate: {
      type: Number,
      default: 0,
    },
    correct: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    redoCount: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    donutSize() {
      return this.mq_xs ? 80 : 104
    },
  },
}
</script>

<style lang="scss" scoped>
.student-drawer-hit-rate {
  margin-bottom: $size-m;

  @include mq-s--mf {
    margin-bottom: $size-l;
    padding: 0 $size-l;
  }

  .sas-box {
    @include mq-m--mf {
      display: flex;
    }
  }

  &__card {
    padding: $size-s;
    @include flex-center-start;

    @include mq-s--mf {
      padding: $size-m $size-l;
      flex-grow: 1;
    }

    &__donut {
      margin-right: $size-s;
    }

    &__title {
      margin-bottom: $size-xxs;

      @include mq-s--mf {
        margin-bottom: $size-xs;
      }
    }

    &__count {
      color: $color-ink-light
    }
  }

  &__redo-count {
    border-top: 1px solid #E5E5E5;
    color: $color-ink-light;
    padding: $size-s $size-m;
    @include flex-center-start;

    @include mq-m--mf {
      flex-shrink: 1;
      border-top: none;
      border-left: 1px solid #E5E5E5;
      @include flex-column-center;
    }

    .feather {
      margin-right: $size-s;

      @include mq-m--mf {
        margin-right: 0;
        margin-bottom: $size-s;
      }
    }
  }
}

.--no-margin {
  margin: 0;
}
</style>
