<template>
  <section class="assignment-section-layout">
    <header class="assignment-section-layout__container">
      <slot name="header" />
    </header>
    <slot />
  </section>
</template>

<script>
export default {
  name: 'AssignmentSectionLayout',
  props: {
    title: {
      type: String,
      default: null,
      required: false,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.assignment-section-layout {
  margin-bottom: $size-xl;

  &__container {
    display: flex;
    justify-content: space-between;

    @include mq_s {
      flex-direction: column;
    }
  }

  &__title {
    color: $color-ink;
    margin-bottom: $size-xs;
  }

  &__subtitle {
    display: block;
    color: $color-ink-light;
    margin-bottom: $size-m;
  }
}
</style>
