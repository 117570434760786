<template>
  <div class="hit-rate-counter">
    <box
      :padding="padding"
      :border-radius="currentBorderRadius"
    >
      <div class="hit-rate-counter__card">
        <div
          :class="['hit-rate-counter__card__donut', {'--no-margin': !loading && !hitRate}]"
        >
          <RadialProgressSkeleton
            v-if="loading"
            :size="`${donutSize}px`"
            border-radius="50%"
            class="radial-progress-loader"
          />
          <RadialProgressBar
            v-else-if="hitRate"
            inner-stroke-color="#EFF0F0"
            start-color="#438DE4"
            stop-color="#438DE4"
            :stroke-width="10"
            :diameter="donutSize"
            :total-steps="100"
            :completed-steps="hitRate"
          >
            <h4><b>{{ hitRate }}%</b></h4>
          </RadialProgressBar>
        </div>
        <div class="hit-rate-counter__card__text">
          <div class="hit-rate-counter__card__title">
            <skeleton-loader
              v-if="loading"
              width="160px"
              height="26px"
            />
            <h4 v-else>
              {{ title }}
            </h4>
          </div>
          <div class="hit-rate-counter__card__count">
            <skeleton-loader
              v-if="loading"
              width="120px"
              height="18px"
            />
            <p v-else>
              {{ pluralizedCountLabel }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="redoCount"
        class="hit-rate-counter__redo-count"
      >
        <icon type="repeat" />
        <p v-html="pluralizedRedoLabel" />
      </div>
    </box>
  </div>
</template>

<script>
import simplur from 'simplur'

import RadialProgressBar from 'vue-radial-progress'
import mediaQueries from 'shared/mixins/mediaQueries'
import RadialProgressSkeleton from 'App/components/RadialProgressSkeleton'

export default {
  name: 'HitRateCounter',
  components: {
    RadialProgressBar,
    RadialProgressSkeleton,
  },
  mixins: [ mediaQueries ],
  props: {
    loading: Boolean,
    hitRate: {
      type: Number,
      default: 0,
    },
    correct: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    redoCount: {
      type: Number,
      default: 0,
    },
    borderRadius: {
      type: String,
      default: '',
    },
    padding: {
      type: String,
      default: '0',
    },
    cardTitle: {
      type: String,
      default: '',
    },
    counterLabel: {
      type: String,
      default: '',
    },
  },
  computed: {
    donutSize() {
      return this.mq_xs ? 80 : 104
    },
    currentBorderRadius() {
      const responsiveBorderRadius = this.mq_s ? '0' : this.$tokens.border_radius_m

      return this.borderRadius || responsiveBorderRadius
    },
    title() {
      return this.cardTitle || 'Acertos na tarefa'
    },
    pluralizedCountLabel() {
      const { correct, total } = this

      return simplur`${correct} de ${total} [questão|questões]`
    },
    pluralizedRedoLabel() {
      const { redoCount } = this

      return simplur`Refez a tarefa <b>${redoCount}</b> [vez|vezes]`
    },
  },
}
</script>

<style lang="scss" scoped>
.hit-rate-counter {
  margin-bottom: $size-m;

  @include mq-s--mf {
    margin-bottom: $size-l;
    padding: 0 $size-l;
  }

  .sas-box {
    @include mq-m--mf {
      display: flex;
    }
  }

  &__card {
    padding: $size-s;
    @include flex-center-start;

    @include mq-s--mf {
      padding: $size-m $size-l;
      flex-grow: 1;
    }

    &__donut {
      margin-right: $size-s;
    }

    &__title {
      margin-bottom: $size-xxs;

      @include mq-s--mf {
        margin-bottom: $size-xs;
      }
    }

    &__count {
      color: $color-ink-light
    }
  }

  &__redo-count {
    border-top: 1px solid #E5E5E5;
    color: $color-ink-light;
    padding: $size-s $size-m;
    @include flex-center-start;

    @include mq-m--mf {
      flex-shrink: 1;
      border-top: none;
      border-left: 1px solid #E5E5E5;
      @include flex-column-center;
    }

    .feather {
      margin-right: $size-s;

      @include mq-m--mf {
        margin-right: 0;
        margin-bottom: $size-s;
      }
    }
  }
}

.--no-margin {
  margin: 0;
}
</style>
