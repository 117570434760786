class MicrofrontendService {
  constructor() {
    this.isMicrofrontend = false
    this.listeners = []

    this.initializeState()
  }

  initializeState() {
    this.set(false)
  }

  set(value) {
    const newValue = value === 'true' || value === true

    if (this.isMicrofrontend !== newValue) {
      this.isMicrofrontend = newValue
      sessionStorage.setItem('SAS_IS_MICROFRONTEND', newValue)
      window.eureka_report_init = newValue

      this.notifyListeners()
    }
  }

  get() {
    return this.isMicrofrontend
  }

  addListener(callback) {
    this.listeners.push(callback)
  }

  removeListener(callback) {
    this.listeners = this.listeners.filter((listener) => listener !== callback)
  }

  notifyListeners() {
    this.listeners.forEach((callback) => callback(this.isMicrofrontend))
  }

  clear() {
    this.listeners = []

    window.isMicrofrontend = undefined
    sessionStorage.removeItem('SAS_IS_MICROFRONTEND')

    this.isMicrofrontend = null
  }
}

const microfrontendService = new MicrofrontendService()
export default microfrontendService
