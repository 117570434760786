import AppRouter from 'App/router'
import AppRoutes from 'App/router/routes'
import MFERouter from '@/MFE/router'
import MFERoutes from '@/MFE/router/routes'

import microfrontendService from '@/shared/utils/MicrofrontendService'

class RouterService {
  static instance;

  constructor() {
    if (RouterService.instance) {
      return RouterService.instance
    }

    this.routes = []
    this.router = null

    RouterService.instance = this
    this.initialize()
  }

  initialize() {
    this.updateRouterAndRoutes()

    microfrontendService.addListener(() => {
      this.updateRouterAndRoutes()
    })
  }

  updateRouterAndRoutes() {
    this.setRouterByEnvironment(microfrontendService.get())
    this.setRoutesByEnvironment(microfrontendService.get())
  }

  setRouterByEnvironment(isMicrofrontend) {
    const newRouter = isMicrofrontend ? MFERouter : AppRouter

    this.router = newRouter
  }

  setRoutesByEnvironment(isMicrofrontend) {
    const newRoutes = isMicrofrontend ? MFERoutes : AppRoutes

    this.routes = newRoutes
  }

  getRouter() {
    this.updateRouterAndRoutes()

    return this.router
  }

  getRoutes() {
    this.updateRouterAndRoutes()

    return this.routes
  }

  clear() {
    this.router = null
    this.routes = []
  }

  destroy() {
    this.clear()
    RouterService.instance = null
  }
}

const routerService = new RouterService()

export const getRouter = () => routerService.getRouter()
export const getRoutes = () => routerService.getRoutes()

microfrontendService.addListener(() => {
  routerService.updateRouterAndRoutes()
})

export default routerService
