<template>
  <box
    v-if="!hasError"
    class="engagement-report"
    :border-radius="mq_m ? '0' : $tokens.border_radius_m"
  >
    <div class="engagement-report__title">
      <skeleton-loader
        v-if="loading"
        width="150px"
        height="33px"
      />
      <h4 v-else>
        Engajamento
      </h4>
    </div>
    <div class="engagement-report__students-count">
      <skeleton-loader
        v-if="loading"
        width="120px"
        height="22px"
      />
      <p v-else-if="studentsTotalAmount">
        {{ studentsTotalAmount }}
      </p>
    </div>
    <div class="engagement-report__chart">
      <skeleton-loader
        v-if="loading"
        width="100%"
        height="100%"
      />
      <template v-else>
        <div
          v-for="(item, index) in barChartList"
          :key="index"
          class="engagement-report__chart__bar"
          :style="barStyle(item)"
        />
      </template>
    </div>
    <div class="engagement-report__legend">
      <template v-if="loading">
        <skeleton-loader
          width="120px"
          height="22px"
          class="engagement-report__legend__item"
        />
        <skeleton-loader
          width="120px"
          height="22px"
          class="engagement-report__legend__item"
        />
        <skeleton-loader
          width="120px"
          height="22px"
          class="engagement-report__legend__item"
        />
      </template>
      <template v-else>
        <div
          v-for="(item, index) in engagementList"
          :key="index"
          class="engagement-report__legend__item"
        >
          <div
            class="engagement-report__legend__item__bullet"
            :style="`background: ${item.color}`"
          />
          <div class="engagement-report__legend__item__label">
            <span>{{ item.name }}</span>
          </div>
          <div class="engagement-report__legend__item__value">
            <span><b>{{ item.rate }}%</b></span>
          </div>
        </div>
      </template>
    </div>
  </box>
</template>

<script>
import simplur from 'simplur'
import { mapGetters, mapActions } from 'vuex'
import { isNil } from 'lodash'

import { colorsByStatus, engagementClassroomStatus } from 'App/utils/status'
import mediaQueries from 'shared/mixins/mediaQueries'
import statisticsApi from '@/service/statistics'
import accentFold from 'App/mixins/accentFold'
import applicationTypeEnum from 'App/views/AncestralArena/enums/applicationTypeEnum'
import missionStatusEnum from 'App/views/AncestralArena/enums/missionStatusEnum'

export default {
  name: 'EngagementReport',
  mixins: [ mediaQueries, accentFold ],
  data() {
    return {
      engagement: {},
      hasError: false,
      loading: false,
      questionnaire: {},
    }
  },
  computed: {
    ...mapGetters([
      'yearSelectedContent',
    ]),
    studentsTotalAmount() {
      const total = this.engagement?.studentsTotalAmount

      if (!total) {
        return ''
      }

      return simplur`Total de ${total} [aluno|alunos]`
    },
    engagementList() {
      const isHomeApplication = this.questionnaire?.applicationType === applicationTypeEnum.HOME
      const isLiveApplication = this.questionnaire?.applicationType === applicationTypeEnum.LIVE
      const isMissionOngoing = this.questionnaire?.finishedStatus === missionStatusEnum.ONGOING
      const isMissionFinished = this.questionnaire?.finishedStatus === missionStatusEnum.FINISHED

      if (isHomeApplication) {
        if (isMissionOngoing) {
          return this.filterEngagementByStatus(engagementClassroomStatus.INCOMPLETE)
        }
        if (isMissionFinished) {
          return this.filterEngagementByStatus(engagementClassroomStatus.IN_PROGRESS)
        }
      }

      if (isLiveApplication && isMissionFinished) {
        return this.filterEngagementByStatus(engagementClassroomStatus.IN_PROGRESS)
      }

      return this.engagement?.engagementRange ?? []
    },
    barChartList() {
      const isHomeApplication = this.questionnaire?.applicationType === applicationTypeEnum.HOME
      const isLiveApplication = this.questionnaire?.applicationType === applicationTypeEnum.LIVE
      const isMissionOngoing = this.questionnaire?.finishedStatus === missionStatusEnum.ONGOING
      const isMissionFinished = this.questionnaire?.finishedStatus === missionStatusEnum.FINISHED

      if (isHomeApplication) {
        if (isMissionOngoing) {
          return this.filterEngagementByStatus(engagementClassroomStatus.INCOMPLETE)
            .filter(({ rate }) => rate !== 0)
        }
        if (isMissionFinished) {
          return this.filterEngagementByStatus(engagementClassroomStatus.IN_PROGRESS)
            .filter(({ rate }) => rate !== 0)
        }
      }

      if (isLiveApplication && isMissionFinished) {
        return this.filterEngagementByStatus(engagementClassroomStatus.IN_PROGRESS)
          .filter(({ rate }) => rate !== 0)
      }

      return this.engagement?.engagementRange ?? []
    },

    classroomId() {
      return this.$route.params.classroomId
    },
  },
  watch: {
    classroomId: {
      immediate: true,
      handler() {
        this.getEngagement()
      },
    },
  },
  created() {
    this.getQuestionnaire()
  },
  methods: {
    ...mapActions([
      'getClassrooms',
    ]),
    barStyle(item) {
      return {
        width: `${item.rate}%`,
        background: item.color,
      }
    },
    filterEngagementByStatus(status) {
      return this.engagement?.engagementRange?.filter(({ name }) => name !== status) ?? []
    },
    getLegendColor(label) {
      const normalizeLabel = this.accentFold(label.split(' ').join('_')).toLowerCase()

      return colorsByStatus({ tokens: this.$tokens, status: normalizeLabel })
    },
    async getQuestionnaire() {
      const { questionnaireCode } = this.$route.params

      try {
        const response = await statisticsApi.getQuestionnaire(questionnaireCode)
        this.questionnaire = response
      } catch (error) {
        this.questionnaire.error = true
      }
    },
    checkStudentsEngagement() {
      const { engagementRange } = this.engagement
      const { rate: notStartedRate } = engagementRange
        .find(({ name }) => name === engagementClassroomStatus.NOT_STARTED)
      const finishedStudents = engagementRange
        .find(({ name }) => name === engagementClassroomStatus.FINISHED)

      if (finishedStudents?.rate === 0) {
        this.$emit('no-students-finished')
      }

      if (notStartedRate === 100) {
        this.$emit('no-students-started')
      }
    },
    mapEngagementRange(data) {
      return data.engagementRange.map((item) => ({
        ...item,
        color: this.getLegendColor(item.name),
      }))
    },
    async getEngagement() {
      const { questionnaireCode, classroomId } = this.$route.params

      if (!questionnaireCode) {
        console.error('Questionnaire code or classroom id not found', questionnaireCode, classroomId)

        return
      }

      if (!classroomId) {
        console.error('classroomId is null')

        await this.getClassrooms(this.yearSelectedContent)
      }

      this.loading = true
      try {
        const isRouteTypeBookActivities = this.$route.query.type === 'book-activities'
        const isBookActivities = (!isNil(this.$route.query.type) && isRouteTypeBookActivities)
        const getEngagementStatistics = isBookActivities
          ? statisticsApi.getEngagementStatistics
          : statisticsApi.getEngagementStatisticsBff
        const currentYear = new Date().getFullYear()

        const { data } = await getEngagementStatistics({
          questionnaireCode,
          classroomId,
          contentYear: this.yearSelectedContent ?? currentYear,
        })

        this.engagement = {
          ...data,
          engagementRange: this.mapEngagementRange(data),
        }

        this.checkStudentsEngagement()
      } catch (error) {
        this.hasError = true
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.engagement-report {
  align-content: space-between;

  &__title {
    flex: 1 0 auto;
  }

  &__students-count {
    color: $color-ink-light;
  }

  &__chart {
    flex: 1 0 100%;
    height: $size-m;
    margin: $size-s 0;
    border-radius: $size-xxs;
    @include flex-center;

    &__bar {
      height: 100%;
      border-right: 3px solid $color-white;

      &:first-child {
        border-top-left-radius: $size-xxs;
        border-bottom-left-radius: $size-xxs;
      }

      &:last-child {
        border: none;
        border-top-right-radius: $size-xxs;
        border-bottom-right-radius: $size-xxs;
      }
    }
  }
  &__legend {
    @include mq-media--mf(1024px) {
      @include flex-center;
    }

    &__item {
      margin-top: $size-xs;
      @include flex-center-start;

      @include mq-media--mf(1024px) {
        margin-top: 0;
        @include space-inline($size-s);
      }

      @include mq-media--mf(1080px) {
        @include space-inline($size-l);
      }

      &__bullet {
        width: $size-s;
        height: $size-s;
        display: inline-block;
        border-radius: 50%;
        margin-right: $size-xs;
      }

      &__label {
        margin-right: $size-xxs;
      }
    }
  }
}
</style>
