const production = {
  PORTAL_URL: 'https://portalsaseducacao.com.br',
  BASE_URL: 'https://assignment-report.portalsaseducacao.com.br',
  CUSTOM_ASSIGNMENTS_REPORT_URL: 'https://custom-assignments-report.portalsaseducacao.com.br/exercicios-personalizados',
  CQ_URI: 'https://centraldequestoes.portalsaseducacao.com.br',
  EUREKA_URI: 'https://eureka.portalsaseducacao.com.br',
  // sasdigital.com.br
  ASSIGNMENTS_API: 'https://assignment-backend.sasdigital.com.br',
  ASSIGNMENT_REPORT_BFF: 'https://api.sasdigital.com.br/assignment-report-frontend-bff',
  AUTH_API: 'https://auth-server.portalsaseducacao.com.br/v1',
  AUTH_API_V2: 'https://auth-server.portalsaseducacao.com.br/v2',
  CROSS_PORTAL_URL: 'https://app.portalsaseducacao.com.br',
  // TODO: trocar pelo bff da aplicação
  EUREKA_BFF_API: 'https://api.sasdigital.com.br/eureka-frontend-bff',
}

const staging = {
  PORTAL_URL: 'https://portal.staging.portalsaseducacao.com.br',
  BASE_URL: 'https://assignment-report.staging.portalsaseducacao.com.br',
  CUSTOM_ASSIGNMENTS_REPORT_URL: 'https://custom-assignments-report.staging.portalsaseducacao.com.br/exercicios-personalizados',
  CQ_URI: 'https://centraldequestoes.staging.portalsaseducacao.com.br',
  EUREKA_URI: 'https://eureka.staging.portalsaseducacao.com.br',
  // sasdigital.com.br
  ASSIGNMENTS_API: 'https://assignment-backend.staging.sasdigital.com.br',
  ASSIGNMENT_REPORT_BFF: 'https://assignment-report-frontend-bff.staging.sasdigital.com.br/',
  ASSIGNMENT_REPORT_BFF_MOCK: 'https://api.staging.sasdigital.com.br/sas-mock/assignment-report-frontend-bff',
  AUTH_API: 'https://auth-server.staging.portalsaseducacao.com.br/v1',
  AUTH_API_V2: 'https://auth-server.staging.portalsaseducacao.com.br/v2',
  CROSS_PORTAL_URL: 'https://app-stg.portalsaseducacao.com.br',
  // TODO: trocar pelo bff da aplicação
  EUREKA_BFF_API: 'https://api.staging.sasdigital.com.br/eureka-frontend-bff',
}

const development = {
  ...staging,
  PORTAL_URL: 'http://localhost:8080',
  CROSS_PORTAL_URL: 'http://localhost:3000',
}

const urlsByMode = {
  production,
  staging,
  development,
}

export const urls = urlsByMode[process.env.VUE_APP_MODE]
