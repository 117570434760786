<template>
  <div :class="[ 'assignments-list-item', { '--unreleased': unreleased }]">
    <div class="assignments-list-item__lecture">
      <div class="assignments-list-item__icon">
        <img :src="imgSrc">
      </div>
      <div class="assignments-list-item__details">
        <h6>
          {{ assignment.title | truncate(statementMaxLength) }}
        </h6>
        <span>{{ assignment.subtitle }}</span>
      </div>
    </div>
    <div
      v-if="unreleased"
      class="assignments-list-item__release-date"
    >
      <icon
        class="assignments-list-item__release-date__icon"
        type="calendar"
      />
      <p class="assignments-list-item__release-date__text">
        {{ formattedReleaseDate }}
      </p>
    </div>
    <slot v-else />
  </div>
</template>

<script>
import formatDate from 'App/mixins/formatDate'
import truncate from 'shared/utils/truncateText'
import mediaQueries from 'shared/mixins/mediaQueries'

export default {
  name: 'AssignmentsListItem',
  mixins: [ formatDate, mediaQueries ],
  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imgSrc() {
      const isUnreleased = this.assignment.status === 'unreleased'

      // eslint-disable-next-line global-require
      return isUnreleased ? require('MFE/assets/icons/lock.svg') : require('MFE/assets/icons/tata.svg')
    },
    unreleased() {
      return this.assignment.status === 'unreleased'
    },
    formattedReleaseDate() {
      return this.localizedDayAndMonth(this.assignment.releaseDate)
    },
    statementMaxLength() {
      switch (this.$mq) {
        case 'x_small':
          return 25
        case 'small':
          return 32
        case 'medium':
          return 58
        case 'large':
          return 58
        case 'x_large':
          return 82
        default:
          return Number.MAX_SAFE_INTEGER
      }
    },
  },
  methods: { truncate },
}
</script>

<style lang="scss" scoped>
.assignments-list-item {
  font-family: "Inter", sans-serif;
  border: 1px solid #E6E9ED;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include space-stack($size-s);
  padding: $size-s !important;
  border-radius: 8px;

  @include mq-m--mf {
    flex-wrap: nowrap;
    padding: $size-s $size-m !important;
  }

  @include mq_m {
    flex-direction: column;
  }

  &__lecture {
    display: flex;
    max-width: 50%;
    align-items: center;

    @include mq_m {
      margin-bottom: $size-s;
      width: 100%;
    }
  }

  &__icon {
    border-radius: 8px;
    background-color: #c9d8ff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 44px;
    max-height: 44px;
    min-width: 44px;
  }

  &__details {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: $size-m;

    @include mq-m--mf {
      flex-grow: 1;
      flex-basis: auto;
    }

    @include mq_m {
      max-width: 85%;
    }

    span {
      display: block;
      color: $color-ink-lighter;

      @include mq-m--mf {
        margin-bottom: 0
      }
    }
  }

  &__release-date {
    display: flex;
    align-items: flex-end;

    ::v-deep &__icon {
      min-width: 24px;
      color: $color-ink-lighter;
      margin-right: $size-xxs;
    }

    &__text {
      white-space: nowrap;
      color: $color-ink-light;
    }

    @include mq_m {
      width: 100%;
    }
  }
}

.--unreleased .assignments-list-item__icon {
  background-color: #E6E9ED;
}
</style>
