<template>
  <div
    role="tablist"
    class="tabs"
  >
    <s-button
      v-for="tab in tabs"
      :key="tab.name"
      role="tab"
      :class="['tabs__item', {
        '--active': isTabActive(tab),
        '--disabled': isTabDisabled(tab)
      }]"
      @click="openTab(tab)"
    >
      {{ text(tab) }}
    </s-button>
  </div>
</template>

<script>
import updateRoute from 'shared/mixins/updateRoute'
import { isNil, has } from 'lodash'

export default {
  name: 'TabSelector',
  mixins: [ updateRoute ],
  props: {
    tabs: {
      type: Array,
      default: () => [],
      validator: (value) => value.every((tab) => has(tab, 'name') && has(tab, 'text')),
    },
    variation: {
      type: String,
      default: 'primary',
      validator: (value) => value.match(/(primary|secondary)/),
    },
    isActiveParam: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentTab: null,
    }
  },
  computed: {
    tabQueryParam() {
      return this.$route.query.tab
    },
  },
  watch: {
    tabQueryParam: {
      immediate: true,
      handler() {
        const { tabs, tabQueryParam, openTab } = this
        const [ firstTab ] = tabs
        const tabFound = tabs.find(({ name }) => name === tabQueryParam)

        if (tabFound) {
          openTab(tabFound)
        } else {
          openTab(firstTab)
        }
      },
    },
  },
  methods: {
    updateTabQueryParam(value) {
      if (!this.isActiveParam) return

      this.$router.replace({
        query: {
          ...this.$route.query,
          tab: value,
        },
      })
    },
    openTab(tab) {
      if ((!isNil(tab) && this.isTabActive(tab)) || tab?.isDisabled) {
        return
      }

      this.currentTab = tab

      this.$emit('change-tab', tab)

      if (!this.isActiveParam) return
      this.updateRouteQueryWithReplace('tab', this.currentTab?.name)
    },
    isTabActive(tab) {
      if (isNil(tab)) return false

      return this.currentTab?.name === tab.name
    },
    isTabDisabled(tab) {
      if (isNil(tab)) return false

      return tab?.isDisabled ?? false
    },
    text(tab) {
      return tab?.text ?? tab?.name
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs {
  overflow: auto;
  @include flex-center-start;
  margin-bottom: $size-m;

  ::v-deep .sas-button {
    border-radius: 2rem;
    color: #707780;
    background: $color-white;
    box-shadow: none;

    &.--active {
      background: #C7D8FF;
      color: #0D4AD6;
      border: 1px solid #8CAFFF;
    }
  }

  &__item {
    cursor: pointer;
    border: 1px solid #C9CED4;
    font-weight: $font-weight-semi-bold;
    flex-shrink: 0;
    font-size: $font-size-s;
    padding: 12px;
    @include flex-center;
    @include space-inline($size-xs);
    @include transition($speed-x-fast);

    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 3px $color-primary-light;
    }

    &.--disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:focus {
        box-shadow: none;
      }
    }
  }
}
</style>
