import Vue from 'vue'
import VueGtm from 'vue-gtm'
import { getRouter } from 'shared/router/RouterService'
import microfrontendService from 'shared/utils/MicrofrontendService'

const initializeGtm = () => {
  const vueRouter = getRouter()

  Vue.use(VueGtm, {
    id: process.env.VUE_APP_TRACKING_GTM_ID,
    enabled: JSON.parse(process.env.VUE_APP_TRACKING_ENABLED),
    debug: process.env.VUE_APP_MODE !== 'production',
    loadScript: true,
    vueRouter,
  })

  Vue.prototype.$trackEvent = ({
    ...rest
  }) => {
    Vue.gtm.trackEvent({
      event: 'custom_event_analytics',
      ...rest,
    })
  }
  Vue.prototype.$track = {
    category: {
      feedback: 'Feedback',
      proposedQuestions: 'QuestoesSugeridas',
      syllabus: '[Gestão][TOL]ExerciciosPersonalizados',
      syllabusHome: 'HomeExerciciosPersonalizados',
      sasActivities: 'AtividadesdoLivro',
      logosReport: 'RelatorioLogos',
    },
    action: {
      rateExperience: 'AvaliarExperiencia',
      seeReport: 'VerRelatorio',
      syllabus: {
        selectClassroom: 'SeleçãoDaTurma',
        selectSubject: 'SeleçãoDaMatéria',
        searchStudent: 'PesquisarAluno',
        accessStudentHistory: 'AcessarHistóricoDoAluno',
        rateExperience: 'AvaliarExperiencia',
        bannerClick: 'ClickNoBanner',
      },
      teacherGenerateReinforcement: 'ProfessorGerouMissãoDeReforço',
      selectLogosReport: 'SelecionarRelatorioLogos',
      seeLogosStudentDetails: 'VisualizarDetalheAlunoLogos',
      seeLogosQuestionDetails: 'VisualizarDetalheQuestaoLogos',
    },
    label: {
      generateMission: 'gerou uma missao de reforço',
      goToAncestralArena: 'foi direto para a arena',
    },
  }
}

initializeGtm()

microfrontendService.addListener(() => {
  Vue.gtm.vueRouter = getRouter()
})
