// TODO: testar se o export de rotas com componente não causa problemas
// Se for o caso, separar as rotas em `routes` e `configRoutes`
// https://github.com/arcotech-services/sas-childhood-followup-frontend/pull/74
import PageError from 'shared/views/PageError'
import BaseLayout from 'shared/views/BaseLayout'
import Home from 'MFE/views/Home/Home'
import ClassReport from 'App/views/ClassReport/ClassReport'
import GeneralView from 'App/components/GeneralView/GeneralView'
import QuestionsView from 'App/components/QuestionsView/QuestionsView'
import StudentsView from 'App/components/StudentsView/StudentsView'
import AncestralArenaReport from 'MFE/views/AncestralArena/AncestralArenaReport'
import SASActivities from 'MFE/views/SasActivities/SasActivities'
import LogosReport from 'MFE/views/LogosReport/LogosReport'
import LogosMissionReport from 'MFE/views/LogosMissionReport/LogosMissionReport'

const routes = [
  {
    path: '/error/:status',
    name: 'mfe-error',
    component: PageError,
  },
  {
    path: '/',
    component: BaseLayout,
    children: [{
      path: '',
      component: Home,
      children: [
        {
          path: 'sas-activities',
          name: 'mfe-sas-activities',
          component: SASActivities,
        },
        {
          path: 'arena-ancestral',
          name: 'mfe-arena-ancestral',
          component: AncestralArenaReport,
        },
        {
          path: 'logos-report',
          name: 'mfe-logos-report',
          component: LogosReport,
        },
      ],
    },
    {
      path: 'recomposition/recomposition-chapter/:recompositionChapterId/classroom/:classroomId/progress',
      name: 'mfe-recomposition-chapter-report',
      component: LogosMissionReport,
    },
    {
      path: 'questionnaires/:questionnaireCode/classes/:classroomId',
      component: ClassReport,
      children: [
        {
          path: 'general',
          name: 'mfe-extra-activity-report-general',
          component: GeneralView,
        },
        {
          path: 'general',
          name: 'mfe-report-general',
          component: GeneralView,
        },
        {
          path: 'questions',
          name: 'mfe-extra-activity-report-questions',
          component: QuestionsView,
        },
        {
          path: 'students',
          name: 'mfe-extra-activity-report-students',
          component: StudentsView,
        },
      ],
    }],
  },
  {
    path: '*',
    redirect: {
      name: 'mfe-error',
      params: { status: 404 },
    },
  },
]

export default routes
