<template>
  <div v-if="hasAssignments">
    <AdaptiveSelector
      :options="mappedAssignments"
      :selected-option="selectedAssignment"
      :threshold="0"
      :empty-image="require('App/assets/class-picker-empty.svg')"
      class="assignment-selector"
      :initial-index-selected-option="assignmentCodeIndex"
      @select="selectAssignment"
    >
      <template #selectedOption="{ option }">
        <icon
          v-if="option.isMaxScore"
          v-tooltip.top="'Sessão com maior índice de acertos'"
          class="assignment-selector__icon"
          type="award"
        />
        <div class="assignment-selector__description-container">
          <div class="assignment-selector__description">
            {{ option.description }}
          </div>
        </div>
      </template>
      <template #option="{ option }">
        <div
          :class="['assignment-selector__option', {'--max-score': option.isMaxScore}]"
        >
          <div class="assignment-selector__option-container">
            <div class="assignment-selector__option-description">
              {{ option.description }}
            </div>
            <div
              :class="['assignment-selector__option-sub-description',
                       { '--max-score': option.isMaxScore }]"
            >
              <icon
                v-if="option.isMaxScore"
                class="assignment-selector__option-icon"
                type="award"
              />
              {{ option.subDescription }}
            </div>
          </div>
        </div>
      </template>
    </AdaptiveSelector>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import formatDate from 'App/mixins/formatDate'
import { parseISO } from 'date-fns'
import simplur from 'simplur'
import AdaptiveSelector from 'App/components/AdaptiveSelector/AdaptiveSelector'
import isEmpty from 'lodash/isEmpty'
import updateRoute from 'shared/mixins/updateRoute'
import assessmentStatus from '../StudentsView/utils/assessmentStatus'
import assessmentStatusEnum from '../StudentsView/enums/assessmentStatus'

export default {
  name: 'AssignmentSelector',
  components: {
    AdaptiveSelector,
  },
  mixins: [ formatDate, updateRoute ],
  props: {
    student: {
      type: Object,
      required: true,
    },
    assignments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedAssignment: null,
    }
  },
  computed: {
    ...mapGetters([
      'classrooms',
      'activeClassroom',
    ]),
    mappedAssignments() {
      return this.assignments.map((assignment, index) => {
        const status = assessmentStatus(assignment)
        const showStatus = status !== assessmentStatusEnum.FINISHED
        const isInProgress = status === assessmentStatusEnum.IN_PROGRESS
        const text = isInProgress ? 'Em andamento' : ''
        const date = !isEmpty(assignment.finishedAt)
          ? this.format(parseISO(assignment.finishedAt), 'dd/MM - HH\'h\'mm')
          : text

        return {
          index,
          id: assignment.code,
          assignmentCode: assignment.code,
          isMaxScore: assignment.maxScore,
          status,
          showStatus,
          description: `${index + 1}º · ${date}`,
          subDescription: this.answers({
            correctAnswers: assignment?.correctAnswers ?? 0,
            questionsCount: this.student?.questionsCount ?? 0,
          }),
        }
      })
    },
    hasAssignments() {
      return !isEmpty(this.assignments)
    },
    assignmentCode() {
      return this.$route.query?.assignmentCode ?? this.selectedAssignment?.assignmentCode
    },
    assignmentCodeIndex() {
      return this.assignments.findIndex((assignment) => assignment.code === this.assignmentCode)
    },
    activeStudentId() {
      return this.$route.query?.activeStudent
    },
  },
  watch: {
    mappedAssignments(assignments) {
      this.addSelectedAssignmentWithParam(assignments)
    },
    selectedAssignment(assignment) {
      this.upDateParamsWhenSelectAssignment(assignment)
    },
    activeStudentId() {
      this.updateSelectAssignmentWhenChangeStudent()
    },
  },
  methods: {
    answers({ correctAnswers, questionsCount }) {
      return simplur`${correctAnswers}/${questionsCount} ${[ correctAnswers ]}[acerto|acertos]`
    },
    selectAssignment(assignment) {
      this.selectedAssignment = assignment
    },
    addSelectedAssignmentWithParam(assignments) {
      if (isEmpty(this.selectedAssignment)) {
        if (this.$route.query?.assignmentCode) {
          const assignmentCodeQuery = this.$route.query.assignmentCode
          const selected = assignments.find(
            (assignment) => assignment.assignmentCode === assignmentCodeQuery
          )

          if (!isEmpty(selected)) {
            this.selectAssignment = selected

            return
          }
        }

        const [ firstValue ] = assignments
        this.selectedAssignment = firstValue
      }
    },
    upDateParamsWhenSelectAssignment(assignment) {
      if (this.selectedAssignment?.assignmentCode !== this.assignmentCodeParam) {
        this.updateRouteQueryWithReplace('assignmentCode', assignment?.assignmentCode)
      }
    },
    updateSelectAssignmentWhenChangeStudent() {
      if (this.activeStudentId !== Number(this.selectedAssignment.id)) {
        const selected = this.mappedAssignments?.find(
          (assignment) => assignment.assignmentCode === this.assignmentCode
        )

        if (!isEmpty(selected)) {
          this.selectedAssignment = selected
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.assignment-selector {
  ::v-deep &__icon {
    border-radius: 50%;
    background-color: #C0D9F6;
    padding: $size-xxs;
    color: #034DA4;
    margin-right: 12px;
  }

  &__option {
    display: inline-flex;
  }

  &__option-container {
    display: flex;
    flex-direction: column;
  }

  &__option-description {
    font-family: Metropolis;
    font-weight: $font-weight-regular;
    font-size: $font-size-m;
    line-height: 160%;
    color: #1E2124;
  }

  &__option-sub-description {
    color: #666E75;
    font-family: Metropolis;
    font-weight: $font-weight-regular;
    font-size: $font-size-s;
    line-height: 160%;
    display: inline-flex;
    align-items: center;

    &.--max-score {
      color: #034DA4;
      background: #C0D9F6;
      font-size: $font-size-xs;
      padding: $size-xxs 12px;
      border-radius: 100px;
      padding: 0 $size-xs;
      grid-gap: $size-xxs;
    }
  }

  ::v-deep &__option-icon {
    margin: 0 !important;
    object-fit: contain;
    width: 20px;
    height: 24px;
    padding: $size-xxs;

    .feather {
      margin: 0;
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
  }

  ::v-deep .feather--check {
      overflow: visible;
      display: block;
    }
}

</style>
