<template>
  <AdaptiveSelectorDropdown
    class-name="mfe-home-selector"
    :options="filteredClassrooms"
    :selected-option="selectedClassroom"
    filterable
    :loading="loading"
    filter-placeholder="Pesquisar"
    @select="$emit('select', $event)"
    @search="searchString = $event"
  >
    <template #selectedOption>
      {{ mapClassroomToOption(selectedOption).label }}
    </template>
    <template #option="{ option }">
      {{ mapClassroomToOption(option).label }}
    </template>
  </AdaptiveSelectorDropdown>
</template>

<script>
import truncateText from 'shared/utils/truncateText'
import getShiftLabel from 'MFE/utils/getShiftLabel'
import AdaptiveSelectorDropdown from 'MFE/components/AdaptiveSelector/AdaptiveSelectorDropdown'
import accentFold from 'App/mixins/accentFold'

export default {
  name: 'ClassPicker',
  components: {
    AdaptiveSelectorDropdown,
  },
  mixins: [ accentFold ],
  props: {
    classrooms: {
      type: Array,
      default: () => [],
    },
    selectedOption: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    loading: Boolean,
  },
  data() {
    return {
      searchString: '',
    }
  },
  computed: {
    selectedClassroom() {
      return this.loading ? null : this.selectedOption
    },
    options() {
      return this.classrooms?.length
        ? this.classrooms.map(this.mapClassroomToOption)
        : []
    },
    filteredClassrooms() {
      if (!this.options.length) {
        return []
      }

      return this.options.filter((classroom) => (
        this.accentFold(classroom.label.toLocaleLowerCase())
          .includes(
            this.accentFold(this.searchString.toLocaleLowerCase())
          )
      ))
    },
  },
  methods: {
    truncateText,
    mapClassroomToOption(classroom) {
      const gradeName = classroom.grade.name
      const truncatedClassroomName = truncateText(classroom.name, 50)
      const classroomShift = getShiftLabel(classroom.shift)

      return {
        ...classroom,
        label: `${gradeName} · ${truncatedClassroomName} · ${classroomShift}`,
        value: classroom.id,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
