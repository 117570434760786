<template>
  <div
    ref="adaptiveSelector"
    class="adaptive-selector-dropdown"
  >
    <button
      ref="adaptiveSelectorHandle"
      :class="[
        'adaptive-selector-dropdown__handle',
        [{ '--disabled': disabled}],
      ]"
      :disabled="disabled"
      @click="toggleOptions"
    >
      <template v-if="loading">
        <slot name="skeleton">
          <div class="adaptive-selector-dropdown__handle__skeleton">
            <skeleton-loader
              width="100%"
              height="19px"
            />
          </div>
        </slot>
      </template>
      <template v-else-if="!selectedOption">
        <slot name="placeholder">
          {{ placeholder }}
        </slot>
      </template>
      <template v-if="selectedOption">
        <div class="adaptive-selector-dropdown__handle__content">
          <slot
            name="selectedOption"
            :option="selectedOption"
          >
            {{ selectedOption }}
          </slot>
        </div>
      </template>
      <icon
        class="adaptive-selector-dropdown__handle__caret"
        type="chevron-down"
      />
    </button>
    <transition name="dropdown">
      <div
        v-if="open"
        ref="adaptiveSelectorOptions"
        :class="[
          'adaptive-selector-dropdown__options',
          `--${dropdownDirection}`,
        ]"
      >
        <div
          v-if="filterable"
          class="adaptive-selector-dropdown__options__search"
        >
          <Input
            icon-left="search"
            width="100%"
            class="adaptive-selector-dropdown__options__search__input"
            :placeholder="filterPlaceholder"
            :value="searchString"
            clearable
            icon-color="#9EA4AC"
            @input="handleInput"
            @clear="clearSearch"
          />
        </div>
        <div class="adaptive-selector-dropdown__options__body">
          <template v-if="groupBy">
            <div
              v-for="([ group, optionsInGroup ], groupIndex)
                in groupedOptions"
              :key="groupIndex"
              class="adaptive-selector-dropdown__option-group"
            >
              <div class="adaptive-selector-dropdown__option-group__title">
                {{ group }}
              </div>
              <div
                v-for="(option, optionIndex) in optionsInGroup"
                :key="optionIndex"
                tabindex="0"
                aria-role="button"
                :class="optionClass(option)"
                @click="selectOption(option)"
                @keyup.enter="selectOption(option)"
              >
                <div>
                  <slot
                    name="option"
                    :option="option"
                  >
                    {{ option }}
                  </slot>
                </div>
                <Icon
                  v-if="isEqual(option, selectedOption)"
                  type="check"
                  stroke-width="3"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(option, index) in options"
              :key="index"
              :tabindex="0"
              :class="optionClass(option)"
              @click="selectOption(option)"
              @keyup.enter="selectOption(option)"
            >
              <div class="adaptive-selector-dropdown__option__content">
                <slot
                  name="option"
                  :option="option"
                >
                  {{ option }}
                </slot>
              </div>
              <icon
                v-if="!loading && isSelectedOption(option)"
                type="check"
                stroke-width="2"
              />
            </div>
          </template>
          <div
            v-if="options.length === 0"
            class="adaptive-selector-dropdown__options__empty"
          >
            <img
              v-if="emptyImage"
              :src="emptyImage"
            >
            <p class="adaptive-selector-dropdown__options__empty__text">
              {{ emptyString || $t('adaptiveSelector.emptyString') }}
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import mediaQueries from 'shared/mixins/mediaQueries'
import { Icon } from '@sas-te/alfabeto-vue'
import Input from 'MFE/components/Input/Input'

export default {
  name: 'AdaptiveSelectorDropdown',
  components: { Icon, Input },
  mixins: [ mediaQueries ],
  model: {
    prop: 'selectedOption',
    event: 'select',
  },
  props: {
    placeholder: {
      type: String,
      default: null,
    },
    loading: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: [ Object, String ],
      default: null,
    },
    optionsTitle: {
      type: String,
      default: null,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    filterPlaceholder: {
      type: String,
      default: null,
    },
    emptyString: {
      type: String,
      default: null,
    },
    emptyImage: {
      type: String,
      default: null,
    },
    groupBy: {
      type: String,
      default: null,
    },
    dropdownDirection: {
      type: String,
      default: 'right',
      validator: (value) => value.match(/(left|right)/),
    },
  },
  data() {
    return {
      open: false,
      optionsHeight: undefined,
      searchString: '',
    }
  },
  computed: {
    groupedOptions() {
      if (this.groupBy) {
        return Object.entries(this.options.reduce(
          (previous, current) => {
            const next = { ...previous }
            const group = current[this.groupBy]
            if (!Object.prototype.hasOwnProperty.call(next, group)) {
              next[group] = []
            }
            next[group].push({ ...current })

            return next
          },
          {},
        ))
      }

      return this.options
    },
  },
  methods: {
    isSelectedOption(option) {
      return isEqual(option.id, this.selectedOption?.id)
    },
    clearSearch() {
      this.searchString = ''
      this.$emit('search', this.searchString)
    },
    isEqual(value, other) {
      return isEqual(value, other)
    },
    optionClass(option) {
      return [
        'adaptive-selector-dropdown__option',
        {
          '--active': isEqual(option?.id, this.selectedOption?.id),
          '--disabled': option && option.disabled,
        },
      ]
    },
    selectOption(option) {
      if (!option.disabled) {
        this.$emit('select', option)
        this.hideOptions()
        this.$refs.adaptiveSelectorHandle.focus()
      }
    },
    toggleOptions() {
      if (this.open) {
        this.hideOptions()
      } else {
        this.showOptions()
      }
    },
    showOptions() {
      this.open = true

      if (this.mq_l) {
        this.$nextTick(() => {
          const { height } = this.$refs.adaptiveSelectorOptions
            .getBoundingClientRect()
          this.optionsHeight = height
        })
      }
      document.body.addEventListener('click', this.clickOutside)
      document.body.addEventListener('keyup', this.keyUpHandler)
      this.$emit('open')
    },
    hideOptions() {
      document.body.removeEventListener('click', this.clickOutside)
      document.body.removeEventListener('keyup', this.keyUpHandler)
      this.open = false
      this.$emit('close')
    },
    clickOutside(event) {
      if (!this.$refs.adaptiveSelector.contains(event.target)) {
        this.hideOptions()
      }
    },
    keyUpHandler(event) {
      if (event.defaultPrevented) {
        return
      }

      if (event.key === 'Esc' || event.key === 'Escape') {
        this.hideOptions()
      }
    },
    handleInput(event) {
      this.searchString = event
      this.$emit('search', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.adaptive-selector-dropdown {
  position: relative;
  min-width: 100%;
  max-width: 500px;

  &__name {
    flex-grow: 1;
  }

  &__handle {
    background-color: $color-white;
    border: 1px solid #C9CED4;
    border-radius: 8px;
    font-size: $font-size-heading-6-small;
    font-weight: $font-weight-regular;
    padding: $size-m / 2 $size-s;
    position: relative;
    text-align: left;
    width: 100%;
    @include flex-center-start;
    @include transition($speed-fast);

    @include media-breakpoint-up(md) {
      z-index: 2;
    }

    &__skeleton{
      width: 100%;
    }

    &__content {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ::v-deep &__caret {
      flex-shrink: 1;
      margin-left: $size-s;
      height: 20px;
      width: 20px;
      overflow: initial !important;

      @include media-breakpoint-up(md) {
        margin-left: $size-l;
      }
    }

    &:hover:not([disabled]) {
      background-color: $color-ice;
      cursor: pointer;
    }

    &:focus {
      border-color: $color-primary-light;
      box-shadow: $shadow-s rgba($color-ink, 0.25), inset 0 0 0 2px $color-primary-light;
      outline: none;
    }

    &.--disabled {
      background-color: $color-ice;
      cursor: not-allowed;
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__options {
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0px 14px 20px -8px rgba(25, 28, 31, 0.15);
    max-height: 330px;
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 100;

    @include media-breakpoint-up(md) {
      border-radius: $size-s;
      bottom: auto;
      max-height: 40vh;
      top: 100%;
      z-index: 3;

      &.--left {
        left: auto;
      }

      &.--right {
        right: auto;
      }
    }

    &__body {
      box-shadow: inset 0px 5px 5px -5px rgba(0, 0, 0, 0.25);
      max-height: calc(100vh - 45px);
      padding: $size-s $size-xs $size-xs;
    }

    &__search {
      background-color: $color-white;
      border-bottom: 1px solid #E6E9ED;
      position: sticky;
      top: 0;
      z-index: 2;
      padding: $size-s;
    }

    &__empty {
      height: calc(100% - 76px);
      width: 100%;
      padding: $size-s;
      @include flex-column-center;

      &__text {
        margin-top: $size-s;
        text-align: center;
        max-width: 240px;

        @include media-breakpoint-up(md) {
          max-width: 220px;
        }
      }
    }
  }

  &__option {
    cursor: pointer;
    padding: $size-m / 2;
    position: relative;
    display: grid;
    grid-template-columns: auto min-content;
    align-items: center;
    @include transition($speed-x-fast);
    border-radius: 8px;
    margin: 0 8px;

    &__content {
      word-break: break-word;
    }

    &:hover {
      background-color: #E6E9ED;
    }

    &:focus {
      background-color: #C9CED4;
      outline: none;
    }

    &.--disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        background-color: transparent;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .feather {
      color: $color-primary;
      margin-left: $size-xs;
    }
  }

  &__option-group {
    border-bottom: 1px solid transparentize($color-ink-lightest, 0.5);
    padding-bottom: $size-s;
    margin-bottom: $size-s;

    &:last-of-type {
      border-bottom: none;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    &__title {
      font-size: $font-size-heading-3-small;
      font-weight: $font-weight-semi-bold;
      padding: $size-xs $size-s;

      @include media-breakpoint-up(md) {
        font-size: $font-size-heading-4;
      }
    }
  }
}

@include v-transition(dropdown) {
  opacity: 0;
  transform: translateY(-10%);
}
</style>
