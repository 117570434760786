<template>
  <box
    v-if="!hasError"
    :border-radius="mq_m ? '0' : $tokens.border_radius_m"
  >
    <div class="hit-rate-report__donut">
      <RadialProgressSkeleton
        v-if="loading"
        :size="`${donutSize}px`"
      />
      <radial-progress-bar
        v-else
        inner-stroke-color="#EFF0F0"
        start-color="#438DE4"
        stop-color="#438DE4"
        :stroke-width="10"
        :diameter="donutSize"
        :total-steps="100"
        :completed-steps="hitRate"
      >
        <h4 class="hit-rate-report__donut__label">
          {{ hitRate }}%
        </h4>
      </radial-progress-bar>
    </div>
    <skeleton-loader
      v-if="loading"
      width="190px"
      height="33px"
    />
    <div v-else>
      <h4>
        Média de acertos
      </h4>
      <p
        v-if="hitRate === 0"
        class="hit-rate-report__empty"
      >
        {{ emptyHitRateLabel }}
      </p>
    </div>
  </box>
</template>

<script>
import { mapGetters } from 'vuex'
import RadialProgressBar from 'vue-radial-progress'

import statisticsApi from '@/service/statistics'
import mediaQueries from 'shared/mixins/mediaQueries'
import RadialProgressSkeleton from 'App/components/RadialProgressSkeleton'

const noHitRateLabelVariations = {
  notFinished: 'no-student-finished',
  notHit: 'no-student-hit',
  empty: '',
}

const emptyHitRateLabelVariations = {
  notFinished: 'Nenhum aluno finalizou',
  notHit: 'Nenhum aluno acertou',
}

export default {
  name: 'HitRateReport',
  components: {
    RadialProgressBar,
    RadialProgressSkeleton,
  },
  mixins: [ mediaQueries ],
  props: {
    noHitRateLabel: {
      type: String,
      default: noHitRateLabelVariations.notHit,
      validator: (value) => Object.values(noHitRateLabelVariations).includes(value),
    },
  },
  data() {
    return {
      hitRate: 0,
      loading: false,
      hasError: false,
    }
  },
  computed: {
    ...mapGetters([
      'yearSelectedContent',
    ]),
    donutSize() {
      return this.mq_xs ? 88 : 110
    },
    selectedClassroom() {
      return this.$route.params.classroomId
    },
    emptyHitRateLabel() {
      switch (this.noHitRateLabel) {
        case noHitRateLabelVariations.notFinished: return emptyHitRateLabelVariations.notFinished
        case noHitRateLabelVariations.notHit: return emptyHitRateLabelVariations.notHit
        case noHitRateLabelVariations.empty: return emptyHitRateLabelVariations.notHit
        default: return emptyHitRateLabelVariations.notHit
      }
    },
  },
  watch: {
    selectedClassroom() {
      this.fetchPerformanceRate()
    },
  },
  async created() {
    this.fetchPerformanceRate()
  },
  methods: {
    async fetchPerformanceRate() {
      const { questionnaireCode, classroomId } = this.$route.params

      this.loading = true
      try {
        const currentYear = new Date().getFullYear()
        const { data } = await statisticsApi.getPerformanceRate({
          questionnaireCode,
          classroomId,
          contentYear: this.yearSelectedContent ?? currentYear,
        })

        this.hitRate = Math.round(data)
      } catch {
        this.hasError = true
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.hit-rate-report {
  &__donut {
    margin-right: $size-s;

    @include mq-m--mf {
      margin: 0 auto;
    }

    @include mq-l--mf {
      width: auto;
      margin: 0 $size-s 0 0;
    }

    &__label {
      font: {
        weight: 700;
        size: 1.25rem;
      }

    }
  }

  &__empty {
    color: $color-ink-light;
    margin-top: $size-xs;
  }
}
</style>
