<template>
  <div class="syllabus-classroom-performance">
    <SyllabusHeader
      :description="description"
      :title="title"
    >
      <ClassPicker
        v-model="selectedClassroom"
        :classrooms="classrooms.data"
        :selected-classroom="selectedClassroom"
        :loading="classrooms.loading"
        @select="trackClassroomSelect"
      />
    </SyllabusHeader>
    <Wrapper>
      <header :class="$style.header">
        <div :class="$style.headerContent">
          <h3>
            Desempenho dos alunos
          </h3>
        </div>
        <div>
          <SyllabusExperienceSurveyButton />
        </div>
      </header>
      <div :class="$style.filters">
        <div :class="$style.subjectPickerContainer">
          <SubjectPicker
            v-model="selectedSubject"
            :subjects="subjects"
            :is-loading="!selectedClassroom"
            dropdown-direction="right"
            @select="trackSubjectSelect"
          />
        </div>
        <SInput
          v-model.trim="studentSearchTerm"
          icon-left="search"
          placeholder="Pesquisar por nome do aluno"
          size="large"
          :class="$style.studentSearchInput"
          :disabled="isStudentSearchDisabled"
          @input="trackSearchStudent"
          @blur="clearStartedSearch"
        />
      </div>
      <div :class="$style.content">
        <template v-if="loading && !error">
          <SyllabusStudentsCardsSkeleton v-if="mq_m" />
          <SyllabusStudentsTableSkeleton v-else />
        </template>
        <EmptyState
          v-else-if="error"
          :description="listStates.error.description"
          :image="listStates.error.image"
          :title="listStates.error.title"
        />
        <template v-else>
          <template v-if="hasFilteredStudents">
            <SyllabusStudentsCards
              v-if="mq_m"
              :students="filteredStudents"
              @select-student="selectStudent"
            />
            <SyllabusStudentsTable
              v-else
              :active-student="activeStudent"
              :students="filteredStudents"
              @select-student="selectStudent"
            />
          </template>
          <EmptyState
            v-else
            :description="emptyStateDescription"
            :image="listStates.empty.image"
            :title="listStates.empty.title"
          />
        </template>
      </div>
    </Wrapper>

    <StudentDrawer
      v-if="activeStudent"
      :student="activeStudent"
      :has-previous="hasPreviousStudent"
      :has-next="hasNextStudent"
      :questionnaire="questionnaire"
      @close="clearSelectedStudent"
      @previous-student="previousStudent"
      @next-student="nextStudent"
    />
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import { mapGetters, mapActions } from 'vuex'

import { isNotEmpty } from 'App/utils/arrays'
import mediaQueries from 'shared/mixins/mediaQueries'

import syllabusService from '@/service/syllabus'
import statisticsService from '@/service/statistics'

import { SInput, Wrapper } from '@sas-te/alfabeto-vue'

import ClassPicker from 'App/components/ClassPicker/ClassPicker'
import EmptyState from 'App/components/EmptyState'
import SubjectPicker from 'App/components/SubjectPicker'
import StudentDrawer from 'App/components/StudentDrawer/StudentDrawer'

import SyllabusExperienceSurveyButton from 'App/components/Syllabus/SyllabusExperienceSurveyButton'
import SyllabusHeader from 'App/components/Syllabus/SyllabusHeader'
import SyllabusStudentsCards from 'App/components/Syllabus/SyllabusStudentsCards'
import SyllabusStudentsCardsSkeleton from 'App/components/Syllabus/SyllabusStudentsCardsSkeleton'
import SyllabusStudentsTable from 'App/components/Syllabus/SyllabusStudentsTable'
import SyllabusStudentsTableSkeleton from 'App/components/Syllabus/SyllabusStudentsTableSkeleton'

export default {
  name: 'ClassroomPerformance',
  components: {
    ClassPicker,
    EmptyState,
    SInput,
    SubjectPicker,
    SyllabusExperienceSurveyButton,
    SyllabusHeader,
    SyllabusStudentsCards,
    SyllabusStudentsCardsSkeleton,
    SyllabusStudentsTable,
    SyllabusStudentsTableSkeleton,
    StudentDrawer,
    Wrapper,
  },
  mixins: [
    mediaQueries,
  ],
  data() {
    return {
      title: 'Exercícios personalizados',
      description: 'Confira o desempenho dos seus alunos em trilhas de questões personalizadas.',
      selectedClassroom: null,
      selectedSubject: null,
      isFetchingClassroom: true,
      hasFetchingClassroomError: false,
      studentSearchTerm: '',
      students: [],
      activeStudent: null,
      activeStudentIndex: null,
      listStates: {
        empty: {
          description: 'Nenhum aluno disponível nessa lista',
          image: 'empty-list',
          title: 'Nenhum aluno',
        },
        error: {
          description: 'Ocorreu um erro ao tentar obter a lista de alunos',
          image: 'error',
          title: 'Erro',
        },
      },
      questionnaire: {
        data: null,
        loading: true,
        error: false,
      },
      hasLoadedDefaultClassroom: false,
      hasLoadedDefaultSubject: false,
      hasStartedSearch: false,
    }
  },
  computed: {
    ...mapGetters([
      'classrooms',
    ]),
    loading() {
      return !this.classrooms.ready || this.isFetchingClassroom
    },
    error() {
      return this.classrooms.error || this.hasFetchingClassroomError
    },
    subjects() {
      let subjects = []

      if (!this.selectedClassroom) {
        return subjects
      }

      this.selectedClassroom.lectures.forEach((lecture) => {
        subjects.push(lecture.subject)
      })

      subjects = uniqBy(subjects, 'id')

      return orderBy(subjects, [ 'name' ])
    },
    hasStudents() {
      return isNotEmpty(this.students)
    },
    filteredStudents() {
      const term = this.studentSearchTerm.trim().toLowerCase()

      return term
        ? this.students.filter((student) => student.student.name.toLowerCase().includes(term))
        : this.students
    },
    hasFilteredStudents() {
      return isNotEmpty(this.filteredStudents)
    },
    isStudentSearchDisabled() {
      return this.loading || this.error || !this.hasStudents
    },
    hasPreviousStudent() {
      return this.activeStudentIndex > 0
    },
    hasNextStudent() {
      return this.activeStudentIndex < this.filteredStudents.length - 1
    },
    emptyStateDescription() {
      if (this.studentSearchTerm) {
        return `Nenhum aluno encontrado com o nome "${this.studentSearchTerm}"`
      }

      return this.listStates.empty.description
    },
  },
  watch: {
    selectedClassroom() {
      this.selectedSubject = null

      const { classroomId } = this.$route.query

      if (Number(classroomId) !== this.selectedClassroom.id) {
        this.$router.push({
          name: this.$route.name,
          params: { ...this.$route.params },
          query: {
            ...this.$route.query,
            classroomId: this.selectedClassroom.id,
          },
        })
      }
    },
    selectedSubject() {
      if (this.selectedSubject) {
        this.fetchClassroom()
      } else if (isNotEmpty(this.subjects)) {
        const [ firstSubject ] = this.subjects

        this.selectedSubject = firstSubject
      }
    },
  },
  async created() {
    this.$setTitle(`${this.title} | ${this.$t('app.title')}`)

    if (!this.classrooms.data) {
      await this.getClassrooms()
    }
  },
  methods: {
    ...mapActions([
      'getClassrooms',
    ]),
    async fetchClassroom() {
      const activeStudent = Number(this.$route.query.activeStudent)

      this.isFetchingClassroom = true
      this.hasFetchingClassroomError = false
      this.studentSearchTerm = ''
      this.students = []

      try {
        const { data } = await syllabusService.getClassroomStudentPerformance({
          classroomId: this.selectedClassroom.id,
          subjectId: this.selectedSubject.id,
        })

        this.students = data.map((student) => ({
          ...student,
          id: student.student.id,
          name: student.student.name,
          startedAt: student.startedAt
            ? new Date(student.startedAt)
            : null,
          finishedAt: student.finishedAt
            ? new Date(student.finishedAt)
            : null,
          loading: false,
        }))

        if (activeStudent) {
          let selectedStudentIndex = null
          const selectedStudent = this.students
            .find((student, studentIndex) => {
              if (student.student.id === activeStudent) {
                selectedStudentIndex = studentIndex

                return true
              }

              return false
            })

          if (selectedStudent) {
            this.selectStudent({
              student: selectedStudent,
              studentIndex: selectedStudentIndex,
            })
          }
        }
      } catch {
        this.hasFetchingClassroomError = true
      } finally {
        this.isFetchingClassroom = false
      }
    },
    async fetchQuestionnaire(questionnaireCode) {
      this.questionnaire.loading = true
      this.questionnaire.data = null
      this.questionnaire.error = false

      if (!questionnaireCode) {
        this.questionnaire.loading = false

        return
      }

      try {
        const questionnaire = await statisticsService.getQuestionnaire(questionnaireCode)

        this.questionnaire.data = questionnaire
      } catch (error) {
        this.questionnaire.error = true
      } finally {
        this.questionnaire.loading = false
      }
    },
    async selectStudent({ student, studentIndex }) {
      this.students[studentIndex].loading = true

      this.activeStudent = student
      this.activeStudentIndex = studentIndex

      try {
        await this.fetchQuestionnaire(student.questionnaireCode)

        if (student.student.id !== Number(this.$route.query.activeStudent)) {
          this.$router.push({
            name: this.$route.name,
            params: { ...this.$route.params },
            query: {
              ...this.$route.query,
              activeStudent: student.student.id,
            },
          })
        }
      } catch {
        this.activeStudent = null
        this.activeStudentIndex = null

        this.$toasted.global.error({
          title: 'Ocorreu um problema ao obter os dados do aluno',
        })
      } finally {
        this.students[studentIndex].loading = false
      }
    },
    clearSelectedStudent() {
      const query = { ...this.$route.query }

      delete query.activeStudent

      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query,
      })

      this.activeStudent = null
      this.activeStudentIndex = null
    },
    previousStudent() {
      if (this.hasPreviousStudent) {
        this.selectStudent({
          student: this.filteredStudents[this.activeStudentIndex - 1],
          studentIndex: this.activeStudentIndex - 1,
        })
      }
    },
    nextStudent() {
      if (this.hasNextStudent) {
        this.selectStudent({
          student: this.filteredStudents[this.activeStudentIndex + 1],
          studentIndex: this.activeStudentIndex + 1,
        })
      }
    },
    trackClassroomSelect() {
      if (!this.hasLoadedDefaultClassroom) {
        this.hasLoadedDefaultClassroom = true

        return
      }

      this.$trackEvent({
        category: this.$track.category.syllabusHome,
        action: this.$track.action.syllabus.selectClassroom,
      })
    },
    trackSubjectSelect(subject) {
      if (!this.hasLoadedDefaultSubject) {
        this.hasLoadedDefaultSubject = true

        return
      }

      this.$trackEvent({
        category: this.$track.category.syllabusHome,
        action: this.$track.action.syllabus.selectSubject,
        label: subject.name,
      })
    },
    trackSearchStudent() {
      if (this.hasStartedSearch) {
        return
      }

      this.hasStartedSearch = true

      this.$trackEvent({
        category: this.$track.category.syllabusHome,
        action: this.$track.action.syllabus.searchStudent,
      })
    },
    clearStartedSearch() {
      this.hasStartedSearch = false
    },
  },
}
</script>

<style lang="scss" module>
.header {
  margin-bottom: $size-l;

  @include mq-s--mf() {
    display: flex;
  }
}

.headerContent {
  flex-grow: 1;
  margin-bottom: $size-s;

  @include mq-s--mf() {
    margin-bottom: 0;
  }
}

.filters {
  margin-bottom: $size-s;

  @include mq-m--mf {
    display: flex;
    align-items: center;
  }
}

.subjectPickerContainer {
  flex: none;

  :global(.adaptive-selector-list__item) {
    width: 100%;

    @include mq-m--mf {
      width: auto;
    }
  }
}

.studentSearchInput {
  flex-grow: 1;
  width: 100%;
  margin-top: $size-s;

  :global(.sas-input__field) {
    height: 54px;
  }

  @include mq-m--mf {
    padding-left: $size-m;
    margin-top: 0;
  }
}

.content {
  padding-bottom: 100px;
}
</style>
