<template>
  <div
    ref="classPicker"
    class="class-picker"
  >
    <AdaptiveSelector
      :disabled="disabled"
      filterable
      group-by="gradeName"
      :options-title="$t('classPicker.selectClassroom')"
      :options="mappedClassrooms"
      :selected-option="mappedSelectedClassroom"
      :threshold="0"
      :filter-placeholder="$t('classPicker.searchClassroom')"
      :empty-string="$t('classPicker.emptySearch', { searchString })"
      :empty-image="require('App/assets/class-picker-empty.svg')"
      @search="searchString = $event"
      @select="select"
      @close="searchString = ''"
    >
      <template #selectedOption="{ option }">
        <Icon
          class="class-picker__handle__icon"
          type="users"
        />
        <div class="class-picker__handle__text">
          <div class="class-picker__class-name">
            {{ option.gradeName }}
            ·
            {{ option.name }}
          </div>
          <div class="class-picker__details">
            {{ shiftText(option.shift) }}
            ·
            {{ $tc(
              'report.studentsView.studentsCount',
              option.studentsAmount,
              { count: option.studentsAmount },
            ) }}
          </div>
        </div>
      </template>
      <template #skeleton>
        <SkeletonLoader
          class="class-picker__handle__icon"
          width="24px"
          height="24px"
        />
        <div class="class-picker__handle__text">
          <SkeletonLoader
            class="class-picker__class-name"
            width="50%"
            height="22px"
          />
          <SkeletonLoader
            width="140px"
            height="19px"
          />
        </div>
      </template>
      <template #optionsTitle>
        <Icon
          class="class-picker__options__header__icon"
          type="users"
        />
        <h4 class="class-picker__options__header__title">
          {{ $t('classPicker.selectClassroom') }}
        </h4>
      </template>
      <template #option="{ option }">
        <div class="class-picker__class-name">
          {{ option.name }}
        </div>
        <div class="class-picker__details">
          {{ shiftText(option.shift) }}
          ·
          {{ $tc(
            'report.studentsView.studentsCount',
            option.studentsAmount,
            { count: option.studentsAmount },
          ) }}
        </div>
      </template>
    </AdaptiveSelector>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Icon, SkeletonLoader } from '@sas-te/alfabeto-vue'
import microfrontendService from '@/shared/utils/MicrofrontendService'
import accentFold from 'App/mixins/accentFold'
import AdaptiveSelector from 'App/components/AdaptiveSelector/AdaptiveSelector'
import translateShift from 'MFE/utils/shiftsTranslation'

export default {
  name: 'ClassPicker',
  components: {
    Icon,
    SkeletonLoader,
    AdaptiveSelector,
  },
  mixins: [ accentFold ],
  model: {
    prop: 'selectedClassroom',
    event: 'select',
  },
  props: {
    classrooms: {
      type: Array,
      default: null,
    },
    selectedClassroom: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMicrofrontend: false,
      searchString: '',
    }
  },
  computed: {
    mappedSelectedClassroom() {
      if (this.selectedClassroom === null) {
        return null
      }

      const {
        id,
        name,
        shift,
        studentsAmount,
        grade,
        lectures,
      } = this.selectedClassroom

      return {
        id,
        name,
        shift,
        studentsAmount,
        gradeName: grade.name,
        gradeId: grade.id,
        lectures,
        disabled: false,
      }
    },
    mappedClassrooms() {
      if (!this.filteredClassrooms) {
        return []
      }

      return this.filteredClassrooms.map(({
        id,
        name,
        shift,
        studentsAmount,
        grade,
        lectures,
      }) => ({
        id,
        name,
        shift,
        studentsAmount,
        gradeName: grade.name,
        gradeId: grade.id,
        lectures,
        disabled: studentsAmount === 0,
      }))
    },
    filteredClassrooms() {
      if (!this.classrooms) {
        return []
      }

      return this.classrooms.filter((classroom) => (
        this.accentFold(classroom.name.toLocaleLowerCase())
          .includes(
            this.accentFold(this.searchString.toLocaleLowerCase())
          )
      ))
    },
  },
  watch: {
    selectedClassroom() {
      this.setActiveClassroom(this.selectedClassroom)
    },
  },
  mounted() {
    this.isMicrofrontend = microfrontendService.get()
  },
  methods: {
    ...mapActions([
      'setActiveClassroom',
    ]),
    shiftText(shift) {
      const isProductionMode = process.env.VUE_APP_MODE === 'production'
      const translateShiftText = translateShift(shift)

      const mfeShiftText = !isProductionMode
        ? this.$t(shift.charAt(0).toUpperCase() + shift.slice(1))
        : translateShiftText

      const sasShiftText = this.$t(`shifts.${shift}`)

      return this.isMicrofrontend ? mfeShiftText : sasShiftText
    },
    select(classroom) {
      if (classroom && !this.disabled) {
        this.$emit('select', this.classrooms.find(
          ({ id }) => id === classroom.id
        ))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$class-picker-shadow: $shadow-s rgba($color-ink, .25);

.class-picker {
  color: $color-ink;

  &__handle {
    &__text {
      flex-grow: 1;
    }

    ::v-deep &__icon {
      color: $color-ink-lighter;
      flex-shrink: 1;
      margin-right: $size-s;
      height: $size-m;
      width: $size-m;
    }
  }

  &__class-name {
    font-size: $font-size-heading-6-small;
    font-weight: $font_weight_medium;
    margin-bottom: $size-xxs;

    @include mq-m--mf() {
      font-size: $font-size-heading-6;
    }
  }

  &__details {
    font-size: $font-size-s;
    color: $color-ink-light;
  }

  &__options {
    &__header {
      &__title {
        flex-grow: 1;
      }

      &__icon {
        color: $color-ink-lighter;
        flex-shrink: 1;
        margin-right: $size-s;
        height: $size-m;
        width: $size-m;

        ::v-deep .feather__content {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
</style>
