<template>
  <div class="home-header">
    <div class="home-header__content">
      <div class="home-header__content__left">
        <div class="home-header__content__breadcrumbs">
          <Breadcrumbs />
        </div>
        <h1 class="home-header__content__left__title">
          {{ title }}
        </h1>
        <p class="home-header__content__left__subtitle">
          {{ subtitle }}
        </p>
        <div class="home-header__content__select-classroom">
          <slot name="left" />
        </div>
      </div>
      <div class="home-header__content__right">
        <slot name="right" />
      </div>
    </div>
    <div class="home-header__activities">
      <Tabs :tabs="tabRoutes" />
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import Tabs from '../Tabs/Tabs'

export default {
  name: 'Header',
  components: {
    Breadcrumbs, Tabs,
  },
  props: {
    subjectIds: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    tabRoutes: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
.home-header, h1 {
  font-family: "Inter", sans-serif;
}

.home-header {
  max-width: 77%;
  margin: 0 auto;
  padding-top: $size-xl;
  margin-bottom: $size-l;
  border-bottom: 1px solid #E6E9ED;

  @include mq_s {
    max-width: 90%;
  }

  &__content {
    padding-bottom: $size-l;

    @include mq-l__mf {
      @include flex-space-between;
    }

    &__breadcrumbs {
      margin-bottom: $size-s;
    }

    &__select-classroom {
      margin-top: $size-m;
    }

    &__right {
      display: flex;
      align-self: flex-start;
    }

    &__left {
      margin-bottom: $size-m;

      @include mq-l__mf {
        padding: 0;
      }

      &__back-button {
        svg {
          color: #707780;
        }
      }

      &__title {
        font-weight: $font-weight-semi-bold;
        font-size: 2rem;
      }

      &__subtitle {
        color: #707780;
      }
    }
  }
}
</style>
