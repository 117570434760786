<template>
  <s-table
    ref="questionsTable"
    class="class-difficulties-table"
    :fields="fields"
    :content="questions"
    :loading="loading"
    :skeleton-lines="5"
    :selected-row="activeQuestion"
    @click-row="$emit('select-question', $event)"
  >
    <template #number="{ row }">
      <skeleton-loader
        v-if="loading"
        width="24px"
        height="24px"
      />
      <div
        v-else
        class="class-difficulties-table__number"
      >
        <span>
          {{ row.order }}
        </span>
      </div>
    </template>
    <template #question="{ row }">
      <template v-if="!loading">
        <span
          v-if="mustShowSubject"
          class="class-difficulties-table__content"
        >
          {{ row.content.name }}
        </span>
        <h6 class="class-difficulties-table__statement">
          {{ truncateText( row.statement, statementMaxLength) }}
        </h6>
      </template>
      <template v-else>
        <div class="class-difficulties-table__content">
          <skeleton-loader
            width="20%"
            height="19px"
          />
        </div>
        <div class="class-difficulties-table__statement">
          <skeleton-loader
            width="100%"
            height="25px"
          />
        </div>
      </template>
    </template>
    <template #hitRate="{ row }">
      <template v-if="!loading">
        <p class="class-difficulties-table__hit-rate">
          <b>{{ Math.round(row.hitRate) }}%</b>
        </p>
        <span class="class-difficulties-table__hit-count">
          {{ hitCountLabel(row.hitCount, row.count) }}
        </span>
      </template>
      <template v-else>
        <div class="class-difficulties-table__hit-rate">
          <skeleton-loader
            width="34px"
            height="22px"
          />
        </div>
        <div class="class-difficulties-table__hit-count">
          <skeleton-loader
            width="100%"
            height="19px"
          />
        </div>
      </template>
    </template>
    <template #details>
      <template v-if="!loading">
        <s-button variation="secondary">
          {{ $t('commons.details') }}
        </s-button>
      </template>
      <template v-else>
        <skeleton-loader
          width="90px"
          height="36px"
        />
      </template>
    </template>
  </s-table>
</template>

<script>
import truncateText from 'shared/utils/truncateText'
import STable from 'App/components/STable'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'

export default {
  name: 'ClassDifficultiesTable',
  components: {
    STable,
  },
  mixins: [ subjectVisibilityToggle ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    activeQuestion: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          value: 'number',
          text: this.$t(
            'report.questionsView.number'
          ),
        },
        {
          value: 'question',
          text: this.$t(
            'report.difficulties.question'
          ),
        },
        {
          value: 'hitRate',
          text: this.$t('report.difficulties.hitRate'),
          width: '180px',
        },
        {
          value: 'details',
          text: '',
          width: '1px',
        },
      ],
    }
  },
  computed: {
    statementMaxLength() {
      switch (this.$mq) {
        case 'large':
          return 96
        default:
          return 150
      }
    },
  },
  methods: {
    truncateText,
    hitCountLabel(hitCount, count) {
      if (count === 0) {
        return '-'
      }

      switch (this.$mq) {
        case 'large':
          return this.$tc('report.difficulties.hitsShort', count, {
            count: hitCount,
            total: count,
          })
        default:
          return this.$tc('report.difficulties.hits', count, {
            count: hitCount,
            total: count,
          })
      }
    },
    previous() {
      this.$refs.questionsTable.previous()
    },
    next() {
      this.$refs.questionsTable.next()
    },
  },
}
</script>

<style lang="scss" scoped>
.class-difficulties-table {
  &__number {
    width: $size-m;
    height: $size-m;
    color: $color-ink-light;
    background: rgba(201, 204, 207, 0.3);
    border-radius: $size-xs;
    @include flex-center;
  }

  &__content{
    margin-bottom: $size-xs;
  }

  &__hit-rate {
    margin-bottom: $size-xxs;
  }

  &__content,
  &__hit-count{
    color: $color-ink-light;
  }

  ::v-deep .sas-table__body tr {
    cursor: pointer;
  }
}
</style>
