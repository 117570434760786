<template>
  <div class="order-selector__wrapper">
    <AdaptiveSelectorDropdown
      :options="options"
      :selected-option="selectedSort"
      @select="setSelectOption"
    >
      <template #placeholder>
        <div class="order-selector__placeholder-container">
          <SortIcon
            size="20"
            stroke-width="2"
          />
          Ordenar
        </div>
      </template>
      <template #selectedOption>
        <div class="order-selector__selected-option-container">
          <SortIcon
            size="20"
            stroke-width="2"
          />
          {{ selectedSort.name }}
        </div>
      </template>
      <template #option="{ option }">
        <div class="order-selector__option-container">
          {{ option.name }}
          <Icon
            v-if="mustShowCheckIcon(option)"
            type="check"
          />
        </div>
      </template>
    </AdaptiveSelectorDropdown>
  </div>
</template>

<script>

import SortIcon from 'MFE/components/_icons/Sort'
import AdaptiveSelectorDropdown from './AdaptiveSelector/AdaptiveSelectorDropdown'

export default {
  name: 'OrderSelector',
  components: {
    AdaptiveSelectorDropdown,
    SortIcon,
  },
  data() {
    return {
      selectedSort: null,
    }
  },
  computed: {
    options() {
      return [
        {
          id: 1,
          name: 'Mais recentes',
          defaultSelected: true,
          order: 'desc',
        },
        {
          id: 2,
          name: 'Mais antigas',
          order: 'asc',
        },
      ]
    },
  },
  methods: {
    mustShowCheckIcon(option) {
      const isDefaultSelected = !this.selectedSort && option.defaultSelected

      return isDefaultSelected
    },
    setSelectOption(option) {
      this.selectedSort = option
      this.$emit('change-option', option)
    },
  },
}
</script>

<style lang="scss" scoped>
.order-selector {
  &__wrapper {
    width: 211px;
  }

  &__selected-option-container, &__placeholder-container {
    display: flex;
    width: 100%;
    align-items: center;
    gap: $size-xxs;
    font-weight: 600;
    font-size: $font-size-m;
    line-height: $line-height-text;
    color:#707780;
  }

  &__option-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: $size-xxs;
  }
}
</style>
