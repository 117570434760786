<template>
  <div class="logos-subject-selector">
    <LecturePicker
      v-model="selectedSubject"
      :lectures="mappedSubjects"
      :loading="subjectsData.loading"
    />
    <OrderSelector
      @change-option="setSort"
    />
  </div>
</template>

<script>
import LecturePicker from 'MFE/components/LecturePicker'
import OrderSelector from 'MFE/components/OrderSelector'
import logosApi from '@/service/logos'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'LogosSubjectSelector',
  components: {
    LecturePicker,
    OrderSelector,
  },
  props: {
    gradeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedSubject: {},
      subjectsData: {
        data: [],
        success: false,
        loading: false,
        error: null,
      },
    }
  },
  computed: {
    mappedSubjects() {
      return this.subjectsData.data?.map((subject) => ({
        id: subject.id,
        name: subject.name,
        subject: {
          id: subject.id,
          name: subject.name,
          color: subject.color,
          icon: subject.icon,
        },
      })) ?? []
    },
  },
  watch: {
    selectedSubject: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit('selectedSubject', this.selectedSubject)
      },
    },
    mappedSubjects: {
      immediate: true,
      deep: true,
      handler() {
        if (isEmpty(this.selectedSubject)) {
          this.setInitialSelectedSubject()
        }
      },
    },
    gradeId: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.fetchSubjects(newValue)
        }
      },
    },
  },
  methods: {
    setSort({ order }) {
      this.sort = order
    },
    async fetchSubjects(gradeId) {
      this.subjectsData.loading = true
      try {
        const { data } = await logosApi.getRecompositionSubjects(gradeId)
        this.subjectsData.data = data
        this.subjectsData.success = true
        this.subjectsData.loading = false
        this.subjectsData.error = false
      } catch (error) {
        this.subjectsData.error = error
        this.subjectsData.success = false
        this.subjectsData.loading = false
        this.subjectsData.data = []
      }
    },
    setInitialSelectedSubject() {
      this.selectedSubject = this.mappedSubjects?.[0] ?? {}
    },
  },
}
</script>

<style scoped lang="scss">
.logos-subject-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 54px;
  margin-bottom: 54px;

  @include mq_s {
    flex-direction: column;
    align-items: flex-start;
    gap: $size-s;
  }
}
</style>
