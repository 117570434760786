<template>
  <div class="student-drawer-questions-cards">
    <box
      v-for="(question, index) in questions"
      :key="index"
      class="student-drawer-questions-cards__item"
      elevation="2"
      elevation-hover="3"
      :padding="$tokens.size_s"
      @click.native="selectQuestion(question, index)"
    >
      <div class="student-drawer-questions-cards__item__detail">
        <div class="student-drawer-questions-cards__item__detail__number">
          {{ $t(
            'report.questionsDrawer.questionNumber',
            { number: question.number }
          ) }}
        </div>
        <span
          v-if="mustShowSubject"
          class="student-drawer-questions-cards__item__detail__content"
        >
          {{ question.content.name }}
        </span>
      </div>
      <span class="student-drawer-questions-cards__item__level">
        {{ $t(
          `levels.${question.level}`
        ) }}
      </span>
      <div class="student-drawer-questions-cards__item__hit">
        <s-badge
          :icon="badgeIcon(question.correct)"
          :variation="badgeVariation(question.correct)"
        />
      </div>
    </box>
  </div>
</template>
<script>
import mediaQueries from 'shared/mixins/mediaQueries'
import badgeStyle from 'App/mixins/badgeStyle'
import subjectVisibilityToggle from 'App/mixins/subjectVisibilityToggle'

export default {
  name: 'StudentDrawerQuestionsCards',
  mixins: [ mediaQueries, badgeStyle, subjectVisibilityToggle ],
  props: {
    questions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    selectQuestion(data, index) {
      this.$emit('selectQuestion', {
        data,
        index,
      })
    },
  },
}
</script>
<style lang="sass" scoped>
.student-drawer-questions-cards
  padding: 0 $size-s $size-s

  +mq-s--mf
    padding: 0 $size-l $size-m

  &__item
    +space-stack($size-s)
    +flex-center-start

    &__detail
      flex-grow: 1

      &__content
        color: $color-ink-light

    &__level
      margin-left: $size-xs

      +mq-s--mf
        margin-left: $size-xl

    &__hit
      margin-left: $size-m

      +mq-s--mf
        margin-left: $size-xxl
</style>
