<template>
  <div class="breadcrumbs">
    <SButton
      v-if="['x_small', 'small'].includes($mq)"
      variation="secondary"
      icon-left="arrow-left"
      @click="goBack()"
    >
      <Icon
        size="16"
        type="arrow-left"
      />
    </SButton>
    <template v-else>
      <SButton
        variation="secondary"
        @click="goBack()"
      >
        <Icon
          size="16"
          type="arrow-left"
        />
      </SButton>
    </template>
  </div>
</template>

<script>
import { SButton, Icon } from '@sas-te/alfabeto-vue'

export default {
  name: 'Breadcrumbs',
  components: {
    SButton,
    Icon,
  },
  props: {
    data: {
      type: Array,
      default: () => ([
        {
          name: '',
          route: null,
        },
      ]),
      required: false,
    },
  },
  computed: {
    latestRoute() {
      if (this.data && this.data.length > 0) {
        return this.data[this.data.length - 1].route
      }

      return null
    },
  },
  methods: {
    goBack(route) {
      if (route) {
        this.$router.push(route)
      } else {
        this.$router.go(-1)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.breadcrumbs {
  @include flex-center-start;
  cursor: pointer;

  .sas-button {
    position: relative;
    margin-right: 8px;

    @include mq-xs {
      max-width: 100%;
    }

    ::v-deep .sas-button__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:after {
      content: '/';
      color: $color-white;
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
      display: none;

      @include mq-m--mf {
        display: flex;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &.--tertiary {
      color: $color-white;
    }
  }

  ::v-deep .sas-button.--secondary {
    border: none;
    background: none;
    box-shadow: none;
  }
}
</style>
